import createDataContext from './createDataContext';

const UPDATE_ANSWERS = 'updateAnswers';
const UPDATE_ID = 'updateId';
const CLEAR_PROPOSAL = 'clearAnswers';
const UPDATE_UTILITY_RATE_OPTIONS = 'updateUtilityRateOptions';
const UPDATE_UTILITY = 'updateUtility';
const UPDATE_ERRORS = 'updateErrors';
const UPDATE_UNITS = 'updateUnits';
const UPDATE_BILLS = 'updateBills';

const updateProposalAnswers = dispatch => (data) => { 
    dispatch({ type: UPDATE_ANSWERS, payload: data }) 
};

const updateUtilityRateOptions = dispatch => (data) => { 
    dispatch({ type: UPDATE_UTILITY_RATE_OPTIONS, payload: data }) 
};

const updateUtility = dispatch => (data) => { 
    dispatch({ type: UPDATE_UTILITY, payload: data }) 
};

const updateErrors = dispatch => (data) => { 
    dispatch({ type: UPDATE_ERRORS, payload: data }) 
};

const clearProposal = dispatch => () => { 
    dispatch({ type: CLEAR_PROPOSAL }) 
};

const updateProposalId = dispatch => (data) => { 
    dispatch({ type: UPDATE_ID, payload: data }) 
};

const updateUnits = dispatch => (data) => { 
    dispatch({ type: UPDATE_UNITS, payload: data }) 
};

const updateBills = dispatch => (data) => { 
    dispatch({ type: UPDATE_BILLS, payload: data }) 
};

const proposalReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_ANSWERS:
            return {
                ...state,
                proposalAnswers: action.payload,
            };
        case UPDATE_ID:
            return {
                ...state,
                proposalId: action.payload,
            };
        case CLEAR_PROPOSAL:
            return {
                ...state,
                proposalAnswers: {},
                proposalId: null
            };
        case UPDATE_UTILITY_RATE_OPTIONS:
            return {
                ...state,
                utilityRateOptions: action.payload
            };
        case UPDATE_UTILITY:
            return {
                ...state,
                utility: action.payload
            };
        case UPDATE_ERRORS:
            return {
                ...state,
                formErrors: action.payload
            };
        case UPDATE_UNITS:
            return {
                ...state,
                billingUnits: action.payload
            };
        case UPDATE_BILLS:
            return {
                ...state,
                bills: action.payload
            };
        default:
            return state;
    }
};

export const { Provider, Context } = createDataContext(
    proposalReducer,
    { updateProposalAnswers, updateProposalId, clearProposal, 
        updateUtilityRateOptions, updateUtility, updateErrors, updateUnits, updateBills },
    { proposalAnswers: {} },
    { proposalId: null },
    { utilityRateOptions: [] },
    { utility: 0 },
    { formErrors: [] },
    { billingUnits: 0 },
    { bills: {}}
);
