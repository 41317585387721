import './IndoorSavingsDrilldownWidget.css';
import '../assetDashboardHomeIcons/WaterSavingsRollup.css'
import '../Common.css';
import GallonsSaved from '../GallonsSaved';
import NetSavings from '../NetSavings';
import GrossSavings from '../GrossSavings';
import LoadingSpinner from '../LoadingSpinner';

export default function IndoorSavingsDrilldownWidget({ data, isLoading }) {
  const waterdrop = require('../../../src/assets/images/water-drop-with-white-fill.png');

  if (isLoading) return <LoadingSpinner />;
  return (
    <div className="widget-drilldown-indoor">
      <div className="water-droplet-container">
        <img src={waterdrop} alt="water-drop"/>
        <div className="overlay-text">
          <GrossSavings waterSavingsResponseData={data.TotalSavingsInDollars}></GrossSavings>
        </div>
      </div>
      <div className="water-droplet-container">
        <img src={waterdrop} alt="water-drop"/>
        <div className="overlay-text">
          <NetSavings waterSavingsResponseData={data.NetSavingsInDollars}></NetSavings>
        </div>
      </div>
      <div className="water-droplet-container">
        <img src={waterdrop} alt="water-drop"/>
        <div className="overlay-text">
          <GallonsSaved waterSavingsResponseData={data.TotalWaterSavingsInGallons}></GallonsSaved>
        </div>
      </div>
    </div>
  );
}
