import { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

export default function SubmitButton({ handleClick }) {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async (e) => {
        setIsLoading(true);
        await handleClick(e);
        setIsLoading(false);
    };

    if (isLoading) return <LoadingSpinner />;

    return (
        <div
            className='proposal-form-btn proposal-form-submit-btn'
            onClick={(e) => onClick(e)}
        >Submit</div>
    );
}