
import './Landing';
import '../../components/Common.css'
import './WaterSavingsDrilldown.css';
import Dropdown from "../../components/Dropdown";
import React from 'react';
import LeakInsightsTable from '../../components/leaks/LeakInsightsTable';
import LeakInsightsRollup from '../../components/leaks/LeakInsightsRollup';
import ComponentWithData from '../../components/ComponentWithData';

const LeakInsights = () => {
    return (
          <div>
            <div className='landing-screen-container-ws'>
                <br></br>
                <ComponentWithData 
                  url="LeakDetectionDetails"  
                  child={LeakInsightsRollup} />

                <Dropdown />
                <LeakInsightsTable url="LeakDetectionTableDetails" />
             </div>
        </div>
    )
}


export default LeakInsights;