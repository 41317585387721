import React, { useContext, useEffect, useState } from 'react';
import Navbar from "../../components/Navbar";
import './inventory.css'
import api from '../../api/api';
import { Modal } from 'antd';
import { Context } from '../../context/AuthContext';
import LoadingSpinner from '../../components/LoadingSpinner';
import OrderForm from '../../components/inventory/OrderForm';
import OrderImport from '../../components/inventory/OrderImport';

const Orders = () => {
    const [isNewOrderModalOpen, setNewOrderModalOpen] = useState(false);
    const [isImportModalOpen, setImportModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [components, setComponents] = useState([]);
    const [properties, setProperties] = useState([]);
    const { state } = useContext(Context);
    const token = state.token;

      useEffect(() => {
        (async () => {
            const jsonResComponents = await fetchComponents();
            await setComponents(jsonResComponents.data);

            const jsonResProperties = await fetchProperties();
            await setProperties(jsonResProperties.data);

            setIsLoading(false);
        })()
      }, []);

      const fetchComponents = async () => {
        try {
            const res = await api.getInventoryComponents(token);
            return res;
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
      };

      const fetchProperties = async () => {
        try {
            const res = await api.getProperties(token);
            return res;
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
      };

      const handleNewOrderSubmit = async (dataForPost) => {
        try {
            const response = await api.updateOrders(state.token, dataForPost);
      
          } catch (error) {
            console.log(error);
          };
      };

      const handleCloseNewOrderModal = () => {
        setNewOrderModalOpen(false);
      }

      const handleImportOrderModal = () => {
        setImportModalOpen(false);
      }

        return (
          <div className='container'>
              <Navbar category='inventory'/>
              <h1 className="sunray">Orders</h1>
              <div className="content">
                <div className='order-actions'>
                  <button onClick={() => setNewOrderModalOpen(true)}>
                    New Order
                  </button>
                  <button onClick={() => setImportModalOpen(true)}>
                    Import Orders
                  </button>
                </div>
                {isLoading === true &&
                  <LoadingSpinner/>
                }
              </div>
              <Modal 
                title="New Order"
                open={isNewOrderModalOpen}
                onCancel={() => setNewOrderModalOpen(false)}
                destroyOnClose={true}
                footer={() => (<></>)}
              >
                <OrderForm
                  handleNewOrderSubmit={handleNewOrderSubmit}
                  onCancel={handleCloseNewOrderModal}
                  components={components}
                  properties={properties}
                />
              </Modal>
              <Modal 
                title="Import Orders"
                open={isImportModalOpen}
                onCancel={() => setImportModalOpen(false)}
                destroyOnClose={true}
                footer={() => (<></>)}
              >
                <OrderImport
                  onCancel={() => handleImportOrderModal(false)}
                />
              </Modal>
          </div>
        );
};
  
export default Orders;