import React from 'react';
import { Table, Button } from 'antd';
import NumberFormat from '../NumberFormat';
import CurrencyFormat from '../CurrencyFormat';
import NumberFormatTwoDecimalPlaces from '../NumberFormatTwoDecimalPlaces';
import PercentFormat from '../PercentFormat';
import { CSVLink } from 'react-csv';

const tableColumns = [
    {
      title: 'Property',
      dataIndex: 'Name',
      key: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (text) => <div>{text}</div>
    },
    {
      title: 'Address',
      dataIndex: 'Address',
      key: 'Address',
      sorter: (a, b) => a.Address.localeCompare(b.Address)
    },
    {
      title: 'Meter Count',
      dataIndex: 'MeterCount',
      key: 'MeterCount',
      sorter: (a, b) => a.MeterCount - b.MeterCount
    },
    {
      title: 'Baseline Usage (kgal)',
      dataIndex: 'BaselineUsageKiloGallons',
      key: 'BaselineUsageKiloGallons',
      sorter: (a, b) => a.BaselineUsageKiloGallons - b.BaselineUsageKiloGallons,
      render: value => <NumberFormat data={value} />
    },
    {
      title: 'Expected Usage (kgal)',
      dataIndex: 'ExpectedUsageKiloGallons',
      key: 'ExpectedUsageKiloGallons',
      sorter: (a, b) => a.ExpectedUsageKiloGallons - b.ExpectedUsageKiloGallons,
      render: value => <NumberFormat data={value} />
    },
    {
      title: 'Baseline Cost',
      dataIndex: 'BaselineCost',
      key: 'BaselineCost',
      sorter: (a, b) => a.BaselineCost - b.BaselineCost,
      render: value => <CurrencyFormat data={value} />
    },
    {
      title: 'Expected Cost',
      dataIndex: 'ExpectedCost',
      key: 'ExpectedCost',
      sorter: (a, b) => a.ExpectedCost - b.ExpectedCost,
      render: value => <CurrencyFormat data={value} />
    },
    {
      title: 'Gross Savings',
      dataIndex: 'GrossSavings',
      key: 'GrossSavings',
      sorter: (a, b) => a.GrossSavings - b.GrossSavings,
      render: value => <CurrencyFormat data={value} />
    },
    {
      title: 'Estimated Site Setup Fee',
      dataIndex: 'SiteSetupFee',
      key: 'SiteSetupFee',
      sorter: (a, b) => a.SiteSetupFee - b.SiteSetupFee,
      render: value => <CurrencyFormat data={value} />
    },
    {
      title: 'Estimated Annual Fees',
      dataIndex: 'AnnualFees',
      key: 'AnnualFees',
      sorter: (a, b) => a.AnnualFees - b.AnnualFees,
      render: value => <CurrencyFormat data={value} />
    },
    {
      title: 'Estimated Leak Cost Avoidance',
      dataIndex: 'LeakCostAvoidance',
      key: 'LeakCostAvoidance',
      sorter: (a, b) => a.LeakCostAvoidance - b.LeakCostAvoidance,
      render: value => <CurrencyFormat data={value} />
    },
    {
      title: 'Estimated Net Savings',
      dataIndex: 'NetSavings',
      key: 'NetSavings',
      sorter: (a, b) => a.NetSavings - b.NetSavings,
      render: value => <CurrencyFormat data={value} />
    },
    {
      title: 'Estimated Payback Years',
      dataIndex: 'PaybackYears',
      key: 'PaybackYears',
      sorter: (a, b) => a.PaybackYears - b.PaybackYears,
      render: value => <NumberFormatTwoDecimalPlaces data={value} />
    },
    {
      title: 'Estimated Carbon Offset',
      dataIndex: 'CarbonOffset',
      key: 'CarbonOffset',
      sorter: (a, b) => a.CarbonOffset - b.CarbonOffset,
      render: value => <NumberFormat data={value} />
    },
    {
      title: 'Estimated Trees Saved',
      dataIndex: 'TreesSaved',
      key: 'TreesSaved',
      sorter: (a, b) => a.TreesSaved - b.TreesSaved,
      render: value => <NumberFormat data={value} />
    },
    {
      title: 'Estimated Controller Count',
      dataIndex: 'ControllerEstimate',
      key: 'ControllerEstimate',
      sorter: (a, b) => a.ControllerEstimate - b.ControllerEstimate,
      render: value => <NumberFormat data={value} />
    },
    {
      title: 'Estimated HydroZone Count',
      dataIndex: 'ZoneEstimate',
      key: 'ZoneEstimate',
      sorter: (a, b) => a.ZoneEstimate - b.ZoneEstimate,
      render: value => <NumberFormat data={value} />
    },
    {
      title: 'Markup Percentage',
      dataIndex: 'MarkupPercentage',
      key: 'MarkupPercentage',
      sorter: (a, b) => a.MarkupPercentage - b.MarkupPercentage,
      render: value => <PercentFormat data={value} />
    },
    {
      title: 'Seasonality',
      dataIndex: 'Seasonality',
      key: 'Seasonality',
      sorter: (a, b) => a.Seasonality - b.Seasonality,
      render: value => <PercentFormat data={value} />
    }
];

const BulkProposalTable = ({ data, type }) => {
    return (
        <div className='table-container'>
        <h3 className="sales-title">{type}</h3>
        <Button type="primary" style={{ marginBottom: '10px' }}>
                <CSVLink 
                    data={data} 
                    headers={tableColumns.map(col => col.dataIndex)} 
                    filename={"bulk_proposal_details.csv"} 
                    className="csv-link-style">
                    Export to CSV
                </CSVLink>
        </Button>
        <Table
            columns={tableColumns}
            dataSource={data}
            pagination={false}
            rowKey={(record, index) => `${record.Id}-${index}`
            }
        />
        </div>
    );
};

export default BulkProposalTable;
