
import '../Common.css'
import React from 'react';
import AlertCenterTable from './AlertCenterTable';
import moment from 'moment';

const PayingHighestWaterRatesTable = () => {
  const urlEndingPHWRTable = '/PayingHighestWaterRate';

  const tableSortees = [{
    id: "PropertyName",
    desc: false
  }];

  const tableColumns = [
      {
        Header: 'Property Name',
        accessor: 'PropertyName',
        Cell: ({ row: { index }, value }) => <div>{value}</div>  
      },
      {
        Header: 'Location',
        accessor: 'Location', // accessor is the "key" in the data
        Cell: ({ row: { index }, value }) => <div>{value}</div> 
      },
      {
        Header: 'Identified On',
        accessor: 'AlertTimestamp',
        Cell: ({ cell: { value } }) => {
            return (
              moment(value).format("h:mm a MM-DD-YYYY")
            );
          }
      },
    ];

    return (
          <AlertCenterTable title="Properties Paying Highest Water Rates" 
            columns={tableColumns} 
            url={urlEndingPHWRTable} 
            sortees={tableSortees} 
            getRowProps={row => ({
            style: {
              background: row.values.OverHighestRateThreshold = true ? "red" : "white"
            }})}/>
    );
}


export default PayingHighestWaterRatesTable;