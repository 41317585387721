

const DeviceInfo = ({serial, isNew, checkIn}) => {
    const bgColor = isNew ? 'lime' : '#C4DAE0';

    return <div style={{backgroundColor: bgColor, color: 'black',
        boxShadow: '0px 0px 2px white', height: '90px', 
        width: '300px', margin: '25px', padding: '10px'}}>
        <h2 style={{marginTop:'10px'}}>{serial}</h2>
        <div>{checkIn}</div>
    </div>
};

export default DeviceInfo;