
import './Common.css'
import React from 'react';
import Table from './Table';
import NumberFormat from './NumberFormat';
import CurrencyFormat from './CurrencyFormat';
import NumberFormatTwoDecimalPlaces from './NumberFormatTwoDecimalPlaces';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/AuthContext';
import { Context as userContext } from '../context/ProposalContext';
import { useContext } from 'react';

const BulkIrrigationLightDetailTable = ({ data }) => {

  const { state, setPropertyName } = useContext(Context);
  const { updateProposalId } = useContext(userContext); 
  const to = 'sales/bulkpropertydetail';
  const navigate = useNavigate();

  const tableData = data.filter(detail => detail.LightProposalDetail).map((d) => ({
    'Name': d.Name,
    'Address': d.Address,
    'MeterCount': d.MeterCount,
    'ControllerCount': d.LightProposalDetail.ControllerCount,
    'BaselineUsage': d.BaselineUsageKiloGallons,
    'BaselineCost': d.BaselineCost,
    'SiteSetupFee': d.LightProposalDetail.SiteSetupFee,
    'TotalPossibleLeakWasteKG': d.LightProposalDetail.TotalPossibleLeakWasteKG,
    'LeakCost': d.LightProposalDetail.LeakCost,
    'AnnualFee': d.LightProposalDetail.AnnualFee,
    'PaybackYears': d.LightProposalDetail.PaybackYears
  }));

  const tabularData = [...tableData].reverse();

  const callback = (value, index, data) => {
    const property = value.original;
    updateProposalId(property.Id);
    setPropertyName(property.Name);
    navigate(`/${to}`);
  };

  const tableSortees = [{
    id: "Name",
    desc: false
  }];

  const tableColumns = [
    {
      Header: 'Property',
      accessor: 'Name',
      Cell: ({ row: { index }, value }) => 
      <div>{value}</div>// accessor is the "key" in the data
    },
    {
      Header: 'Address',
      accessor: 'Address',
    },
    {
      Header: 'Meter Count',
      accessor: 'MeterCount',
    },
    {
      Header: 'Controller Count',
      accessor: 'ControllerCount',
    },
    {
      Header: 'Baseline Usage (kgal)',
      accessor: 'BaselineUsage',
      Cell: ({ cell: { value } }) => <NumberFormat data={value} />
    },
    {
      Header: 'Baseline Cost',
      accessor: 'BaselineCost',
      Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
    },
    {
      Header: 'Estimated Site Setup Fee',
      accessor: 'SiteSetupFee',
      Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
    },
    {
      Header: 'Total Possible Leak Waste (Kgal)',
      accessor: 'TotalPossibleLeakWasteKG',
      Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
    },
    {
      Header: 'Possible Leak Cost',
      accessor: 'LeakCost',
      Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
    },
    {
      Header: 'Estimated Annual Fees',
      accessor: 'AnnualFee',
      Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
    },
    {
      Header: 'Estimated Payback Years',
      accessor: 'PaybackYears',
      Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
    }
  ];

  return (
    <div className='table-container'>
      <h3 className="sales-title">Irrigation Light Insight</h3>
      {tabularData.length > 0 ?
        <Table columns={tableColumns} defaultData={tabularData} sortees={tableSortees} callback={callback} />
        :
        <h3>No results</h3>
      }
    </div>
  );
}


export default BulkIrrigationLightDetailTable;