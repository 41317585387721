import './AlertCenter.css';
import '../Common.css';
import './CarbonOffset.css';
import { useNavigate } from 'react-router-dom';
import CarbonOffsetValue from '../carbonOffset/CarbonOffsetValue';

export default function CarbonOffset({ data }) {
  const navigate = useNavigate();
  const to = 'carbonoffset';

  const carbonOffset = data.CarbonOffset;

  return (
    <span>
      <span className="carbon-offset common-box title" onClick={() => 
        { navigate(`/${to}`) }}>
            <CarbonOffsetValue carbonOffset={carbonOffset}/>
            <span className='co-data-trees-planted'>

            {new Intl.NumberFormat("us-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
              }).format(data.TreesPlanted)} 
            
            </span>
            <span className='big-co-text'> Trees Planted</span>
          </span>
      </span>
  );
}
