import './AlertCenter.css'
import '../Common.css'
import { useNavigate } from 'react-router-dom';

export default function AlertCenter({ data }) {
  const navigate = useNavigate();
  const caution = require('../../../src/assets/images/warning-sign.png');
  const to = 'alertcenter';

  return (
    <span>
      <span className="alert-center common-box" onClick={() => 
        { navigate(`/${to}`) }}>
          <span className="header font-family">
            <span className='image-container'>
              <img
                src={caution}
                alt="Alert"
                className="alert-image"
                ></img>
            </span>
                <div className="alert-text alert-warning-title font-family">Alert Center</div></span>
          <div className='alert-panel'>
            <span className='alert-data font-family'>
                {data.OfflineDevices} 
            </span>
            <span className='alert-text font-family'> Offline Devices</span>
          </div>
          <div className='alert-panel'>
            <span className='alert-data font-family'>
                {data.NotFullyOperational}
            </span>
            <span className='alert-text font-family'> Sites are not fully operational </span>
          </div>
          <div className='alert-panel'>
              <span className='alert-data font-family'>
                {data.PropertiesPayingHighestTier} 
              </span>
            <span className='alert-text font-family'> Properties paying the highest water rate tier </span>
            </div>
        </span>
      </span>
  );
}
