import '../assetDashboardHomeIcons/AlertCenter.css';
import '../Common.css';
import '../assetDashboardHomeIcons/CarbonOffset.css';

export default function CarbonOffset({ data }) {
  return (
    <span>
      <div className="widget-drilldown-li common-box">
            <div className='common-panel'>
        <div className="title">
        {new Intl.NumberFormat("us-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
              }).format(data.CarbonOffset)} 
            </div>
        <span className='common-panel-text-white'> Pounds of Carbon Offset</span>
    </div>
            <div className='vl'></div>
            <div className='common-panel'>
        <div className='title'>
        {new Intl.NumberFormat("us-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
              }).format(data.TreesPlanted)}
            </div>
        <span className='common-panel-text-white'> Trees Planted</span>
    </div>
          </div>
      </span>
  );
}
