import './Common.css';
import CurrencyHeader from './CurrencyHeader';

export default function AssetValuationIncrease({waterSavingsResponseData}){

 return(   
    <div className='common-panel'>
        <span className='water-drop-title'>
            <CurrencyHeader data={waterSavingsResponseData}></CurrencyHeader>
            </span>
            <span className='common-panel-text'> Asset Valuation Increase* </span>
            <span className='astrick'> *5% cap rate </span>
        </div>
 )
}