import { useState, useContext } from 'react';
import TextFormField from '../../components/salesAnalysis/proposalForm/TextFormField';
import api from '../../api/api';
import './IndoorProposal.css';
import { Context } from '../../context/AuthContext';
import LoadingSpinner from '../../components/LoadingSpinner';

const resultRow = (title, val, isCurrency) => {
    return <div style={{display:'flex', flexDirection:'row', 
    justifyContent:'space-between', padding: 5}}>
            <div>{title}</div>
            <div style={{fontWeight: 'bold'}}>{isCurrency ? '$' : null}{val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        </div>
};

const renderResult = (result) => {
    return <div className='white-box' style={{width: 250}}>
        <h2>Results</h2> 
        {resultRow('Monthly', result.Fees, true)}
        {resultRow('Setup', result.InstallationCost, true)}
        {resultRow('Total Meters', result.TotalMeters, false)}
        {resultRow('Gross Savings', result.Savings, true)}
        {resultRow('Payback', result.Payback, false)}
    </div>;
};

const IndoorProposal = () => {
    const { state } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [largeMeters, setLargeMeters] = useState(0);
    const [smallMeters, setSmallMeters] = useState(0);
    const [existingMeters, setExistingMeters] = useState(0);
    const [moistureSensors, setMoistureSensors] = useState(0);
    const [buildings, setBuildings] = useState(0);

    const onChange = (event) => {
        const id = event.target.id;
        const type = id.replace('q-', '');
        const value = event.target.value;

        switch(type){
            case 'name':
                setName(value);
                break;
            case 'large-meters':
                const largeMeterCount = parseInt(value);
                setLargeMeters(largeMeterCount);
                break;
            case 'small-meters':
                const smallMeterCount = parseInt(value);
                setSmallMeters(smallMeterCount);
                break;
            case 'existing-meters':
                const existingMeterCount = parseInt(value);
                setExistingMeters(existingMeterCount);
                break;
            case 'location-input':
                setLocation(value);
                break;
            case 'moisture-sensors':
                const moistureSensorsCount = parseInt(value);
                setMoistureSensors(moistureSensorsCount);
                break;
            case 'buildings-input':
                const buildingsCount = parseInt(value);
                setBuildings(buildingsCount);
                break;
        }
    };

    const submit = async () => {
        setLoading(true);
        let flightRisk = false;
        let problem = '';
        if (name === '') {
            flightRisk = true;
            problem += 'Needs Site Name. ';
        }

        if (isNaN(largeMeters)) {
            flightRisk = true;
            problem += 'Large meter input must be number. ';
        }

        if (isNaN(smallMeters)) {
            flightRisk = true;
            problem += 'Small meter input must be number. ';
        }

        if (isNaN(existingMeters)) {
            flightRisk = true;
            problem += 'Existing meter input must be number. ';
        }

        if (isNaN(buildings)) {
            flightRisk = true;
            problem += 'Building input must be number. ';
        }

        if (isNaN(moistureSensors)) {
            flightRisk = true;
            problem += 'Moisture Sensor input must be number. ';
        }
 
        if (!flightRisk) {
            const payload = { name, largeMeters, smallMeters, existingMeters, location, moistureSensors, buildings };
            const resp = await api.indoorProposal(state.token, payload);
            let data = resp.data;
            data.TotalMeters = largeMeters + smallMeters + existingMeters;
            setResult(data);
        } else {
            alert(problem);
        }

        setLoading(false);
    };

    const results = loading ? <LoadingSpinner /> 
        : result != '' ? renderResult(result) : null;

    return (
        <div className='indoor-proposal-container'>
            <div className='indoor-text-fields'>
                <TextFormField question="Site Name" changeValue={onChange} id="name" />
                <TextFormField question="Location" changeValue={onChange} id="location-input" />
                <TextFormField question="Large Meters" changeValue={onChange} id="large-meters" />
                <TextFormField question="Small Meters" changeValue={onChange} id="small-meters" />
                <TextFormField question="Existing Digital Meters" changeValue={onChange} id="existing-meters" />
                <TextFormField question="Buildings" changeValue={onChange} id="buildings-input" />
                <TextFormField question="Moisture Sensors" changeValue={onChange} id="moisture-sensors" />
            </div>
            <button className='blue-btn indoor-submission' onClick={submit}>Submit</button>
            {results}
        </div>
    );
};

export default IndoorProposal;