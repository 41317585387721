import "./BulkProposalDetail.css"
import React from "react"
import LoadingSpinner from "../../components/LoadingSpinner";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import BulkProposalDetailTable from "../../components/BulkProposalDetailTable";
import BulkIrrigationLightDetailTable from "../../components/BulkIrrigationLightDetailTable";
import { useEffect } from "react";

export default function BulkProposalDetail() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(location.state.data);

    useEffect(() => {
        if (data.length > 1 && data != undefined){
            setIsLoading(false);
        }
    }, []);
    
    const proposalDetail = isLoading ? <h3>No results</h3> : <BulkProposalDetailTable data={data} />;
    const proposalLightDetail = isLoading ? <h3>No results</h3> : <BulkIrrigationLightDetailTable data={data} />;

    return (
        <div>
            <div className="sales-home-container">
                <h2 className="sales-title">Bulk Proposal Detail</h2>
                <div className="proposal-form-container"></div>
            </div>
            <div className="detail-table-container">
            {proposalDetail}  
            </div>
            <div className="detail-table-container">
            {proposalLightDetail}   
            </div>
        </div>
    )
}
