import axios from "axios";

export default async function login(username, password) {
    var body = 'grant_type=password&username=' 
        + username + '&password=' + password;
    
    var uri = process.env.REACT_APP_ASSET_BASE_URL + '/oauth/token';
    var headers = {"Content-Type": "application/x-www-form-urlencoded"};

    var response = await axios.post(uri, body, { headers });

    return response;
}