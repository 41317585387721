import './App.css';
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as ProposalProvider } from "./context/ProposalContext"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { useRef, useEffect } from 'react';

import PrivateRoute from './routes/PrivateRoute';

import Login from './screens/users/Login';
import ResetPassword from './screens/users/ResetPassword';
import Home from './screens/Home'

import SalesHome from './screens/salesAnalysis/SalesHome';
import ProposalForm from './screens/salesAnalysis/ProposalForm';
import BulkCreation from './screens/salesAnalysis/BulkCreation';
import BulkProposalDetail from './screens/salesAnalysis/BulkProposalDetail';
import BulkPropertyDetail from './screens/salesAnalysis/BulkPropertyDetail';
import BillDataInput from './screens/salesAnalysis/BillDataInput';
import BillVerification from './screens/salesAnalysis/BillVerification';
import BillProposal from './screens/salesAnalysis/BillProposal';
import IndoorProposal from './screens/salesAnalysis/IndoorProposal';
import Archive from './screens/salesAnalysis/Archive';
import IntakeForm from './screens/salesAnalysis/IntakeForm';
import Bebbia from './screens/bebbia/Bebbia';

import Landing from './screens/assetDashboard/Landing';
import CarbonOffset from './screens/assetDashboard/CarbonOffset';
import ResponseRateDrilldown from './screens/assetDashboard/ResponseRatesDrilldown';
import LeakInsights from './screens/assetDashboard/LeakInsights';
import AlertCenter from './screens/assetDashboard/AlertCenter';
import WaterSavingsDrilldown from './screens/assetDashboard/WaterSavingsDrilldown';
import InstallationsDrilldown from './screens/assetDashboard/InstallationsDrilldown';
import RepairsPage from './screens/assetDashboard/Repairs';
import RepairPhotos from './screens/assetDashboard/RepairPhotos';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import LayoutWithNav from './components/layouts/LayoutWithNav';
import PropertyWaterSavings from './screens/assetDashboard/PropertyWaterSavings';
import AdminView from './screens/assetDashboard/AdminView';

import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { Helmet } from 'react-helmet';

import BebbiaPurification from './screens/bebbia/BebbiaPurification';
import BebbiaOperations from './screens/bebbia/BebbiaOperations';
import BebbiaMarketing from './screens/bebbia/BebbiaMarketing';
import Controllers from './screens/inventory/Controllers';

import MobileTrainingHome from './screens/mobileTrainingHub/MobileTrainingHome';

import NewArrivals from './screens/inventory/newArrivals';
import InventoryCount from './screens/inventory/inventoryCount';
import AssemblyTracker from './screens/inventory/assemblyTracker';
import Orders from './screens/inventory/orders';
import ReturnForm from './screens/inventory/returnForm';
import Testing from './screens/inventory/testing';

import PaybackCalcs from './screens/assetDashboard/PaybackCalcs';

import Moisture from './screens/evapCooling/moisture';

import StatusReport from './screens/installReport/statusReport';

import CommsDown from './screens/commsDown/CommsDown';
import Portfolio from './screens/assetDashboard/Portfolio';
import PortfolioBreakdown from './screens/assetDashboard/PortfolioBreakdown';
import PortfolioLeaks from './screens/assetDashboard/PortfolioLeaks';
import AuditReportDetail from './screens/audits/AuditReportDetail';

hotjar.initialize('3345951', '6');

 // Check if Hotjar has been initialized before calling its methods
 if (hotjar.initialized()) {
  hotjar.identify('USER_ID', { userProperty: 'value' });
  hotjar.event('button-click');
}   

function App() {
  const componentRef = useRef();

  ReactGA.initialize({ gaTrackingId: process.env.REACT_APP_GA_ID });

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  hotjar.initialize(process.env.REACT_APP_HJID, process.env.REACT_APP_HJSV);

  // Check if Hotjar has been initialized before calling its methods
  if (hotjar.initialized()) {
    hotjar.identify('USER_ID', { userProperty: 'value' });
    hotjar.event('button-click');
  }

  var id = useParams();
  if (window.location.pathname.includes('conservationreport')){
      id = window.location.pathname.split('/').slice(-1).toString();
  };
  
  return (
    <Router>
      <AuthProvider>
        <ProposalProvider>
          <div>
            <Helmet>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-DGE5QS8E68"></script>
              <script>
                {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'G-DGE5QS8E68');
              `}
              </script>
              <script type="text/plain" data-consent="analytics">
                {`
              (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3345951,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
              </script>
            </Helmet>
            <div className="app-wrapper" ref={componentRef}>
              <Routes>
                <Route
                  path="/watersavings"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <WaterSavingsDrilldown />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/payback"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <PaybackCalcs />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/landing"
                  element={
                    <PrivateRoute>
                      <Landing />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/responserates"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <ResponseRateDrilldown />
                    </PrivateRoute>
                  } />
                <Route
                  path="/leakinsights"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <LeakInsights />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/alertcenter"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <AlertCenter />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/propertywatersavings"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <PropertyWaterSavings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/installationsmetric"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <InstallationsDrilldown />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/repairs"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <RepairsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/repairphotos"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <RepairPhotos />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/assetadminview"
                  element={
                    <PrivateRoute>
                      <AdminView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolio"
                  element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolioBreakdown"
                  element={
                    <PrivateRoute>
                      <PortfolioBreakdown />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/portfolioLeaks"
                  element={
                    <PrivateRoute>
                      <PortfolioLeaks />
                    </PrivateRoute>
                  }
                />
                <Route element={<PrivateRoute
                  requiresAdmin={true}
                  redirectPath="/landing" />}>
                  <Route path="/home" element={
                    <LayoutWithNav children={<Home />} category="mode" />
                  } >
                  </Route>
                  <Route path="/sales" element={
                    <LayoutWithNav children={<SalesHome />} category="sales" />
                  } >
                  </Route>
                  <Route path="/sales/proposal" element={
                    <LayoutWithNav children={<ProposalForm />} category="sales" />
                  } />
                  <Route path="/sales/bulkcreation" element={
                    <LayoutWithNav children={<BulkCreation />} category="sales" />
                  } />
                </Route>
                <Route path="/sales/bulkproposaldetail" element={
                  <LayoutWithNav children={<BulkProposalDetail />} category="sales" />
                } />
                <Route path="/sales/bulkpropertydetail" element={
                  <LayoutWithNav children={<BulkPropertyDetail />} category="sales" />
                } />
                <Route path="/sales/archive" element={
                  <LayoutWithNav children={<Archive />} category="sales" />
                } />
                <Route path="/sales/billinput" element={
                  <LayoutWithNav children={<BillDataInput />} category="sales" />
                } />
                <Route path="/sales/billverification" element={
                  <LayoutWithNav children={<BillVerification />} category="sales" />
                } />
                <Route path="/sales/billproposal" element={
                  <LayoutWithNav children={<BillProposal />} category="sales" />
                } />
                <Route path="/sales/indoor-proposal" element={
                  <LayoutWithNav children={<IndoorProposal />} category="sales" />
                } />
                <Route
                  path="/carbonoffset"
                  element={
                    <PrivateRoute>
                      <Navbar />
                      <CarbonOffset />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/resetpassword"
                  element={
                    <ResetPassword />
                  }
                />
                <Route path="/savingsCalculator" element={<IntakeForm />} />
                <Route path="/controllers" element={<Controllers />}/>
                <Route
                  path="/newarrivals"
                  element={
                   <PrivateRoute>
                      <NewArrivals />
                   </PrivateRoute>
                  } 
                />
                <Route
                  path="/inventorycount"
                  element={
                    <PrivateRoute>
                      <InventoryCount/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/assemblytracker"
                  element={
                    <PrivateRoute>
                      <AssemblyTracker/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/installtracking"
                  element={
                    <PrivateRoute>
                      <StatusReport/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/orders"
                  element={
                    <PrivateRoute>
                      <Orders/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/moisture"
                  element={
                    <PrivateRoute>
                      <Moisture />
                    </PrivateRoute>
                  }
                />    
                <Route
                  path="/returns"
                  element={
                    <PrivateRoute>
                      <ReturnForm/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/testing"
                  element={
                    <PrivateRoute>
                      <Testing/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bebbia"
                  element={
                    <PrivateRoute>
                      <Bebbia />
                    </PrivateRoute>
                  }
                />                
                <Route
                  path="/bebbia/purification"
                  element={
                    <PrivateRoute>
                      <BebbiaPurification />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bebbia/operations"
                  element={
                    <PrivateRoute>
                      <BebbiaOperations />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bebbia/marketing"
                  element={
                    <PrivateRoute>
                      <BebbiaMarketing />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/mobiletraininghub"
                  element={
                    <PrivateRoute>
                      <MobileTrainingHome />
                    </PrivateRoute>
                  }
                />  
                  <Route
                  path="/commsdownreport"
                  element={
                    <PrivateRoute>
                      <CommsDown />
                    </PrivateRoute>
                  }
                />
                <Route path="/audit/:auditId" element={<AuditReportDetail />} />      
                <Route path="/" element={<Login />} />
                <Route key={id} path={`/conservationreport/${id}`} element={<Login id={id}/>} />
              </Routes>
            </div>
            <Footer componentRef={componentRef}></Footer>
          </div>
        </ProposalProvider>
      </AuthProvider>
    </Router >
  );
}



export default App;
