import axios from "axios";

// params should look like:
// [{key: name, value: 'mike'}, {key: home, value: 'texas'}]
export default async function get(url, params=[]) {
    var uri = process.env.REACT_APP_ASSET_BASE_URL + '/' + url;
    if (params.length > 0) {
        uri += "?";
        params.forEach(p => uri += (p.key +"=" + p.value));
    }
    return await axios.get(uri);
}