import React from 'react'
import Select from 'react-select'
import { ReactSelectCustomTheme, ReactSelectCustomStyles } from "../../constants/selectFomFieldStyle"

export default function BebbiaDeviceDropdown({ options = [], handleSelect }) {
    const dropdownOptions = options.map(o => ({ label: o, value: o }));
    return (<div className="bebbia-device-dropdown" >
        <Select
            defaultValue={null}
            onChange={(e) => handleSelect(e)}
            placeholder='Seleccionar dispositivo...'
            theme={(theme) => ReactSelectCustomTheme(theme)}
            styles={ReactSelectCustomStyles}
            options={dropdownOptions}
        />
    </div>)
}