import './ScheduleACallWidget.css';
import './Common.css';
import { useState, useContext, useEffect } from 'react'
import api from '../api/api';
import { Context } from "../context/AuthContext";
import DateTimePicker from 'react-datetime-picker';

export default function ScheduleACallWidget(props) {

    const presentDateTime = new Date();
    const [firstValue, firstOnChange] = useState(presentDateTime);
    const [secondValue, secondOnChange] = useState(presentDateTime);
    const [thirdValue, thirdOnChange] = useState(presentDateTime);
    const [thankYouMessage, setThankYouMessage] = useState();

    const { state } = useContext(Context);
    const token = state.token;
    const email = state.email;

    const urlEndingScheduleACall = '/ScheduleACall'

    
    const sendDates = (e) => {
        api.postData(firstValue, secondValue, thirdValue, email, urlEndingScheduleACall, token)
              .then((response)=>{
                setThankYouMessage(response.data);
              })
              .catch((error) => {
                console.log(error)
              })
            }


  return (
    <div>
      <div className="widget-drilldown-sac common-box">
            <div className="got-questions-title title">Got Questions?</div>
            <div className="contact-support">Contact our support team at support@banyanwater.com or request a call below</div>
            <div className='button-container'>
            <button className='submit-sac' onClick={sendDates}>Request A Call</button>
            </div>
            <div>
              {thankYouMessage}
            </div>
        </div>
      </div>
  );
}
