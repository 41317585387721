import React from 'react';
import "./ProposalForm.css";
import "./BillProposal.css";
import '../../components/Common.css';
import BackButton from '../../components/salesAnalysis/BackButton';
import { useNavigate } from 'react-router-dom';
import BillProposalContainer from '../../components/salesAnalysis/billProposal/BillProposalContainer';

export default function BillProposal() {  
    let navigate = useNavigate();
    return (
        <div>
            <BackButton handleBack={() => navigate('/sales/billinput')} />
            <div className="proposal-form-container"></div>
            <BillProposalContainer />
        </div>
    );
}