import '../leaks/LeakInsightsRollup.css';
import '../Common.css';
import VolumeActionImpact from './VolumeActionImpact';
import CostActionImpact from './CostActionImpact';
import LeakAverageResponseTime from './LeakAverageResponseTime';

export default function LeakInsightsRollup({ data }) {
  const activeLeaks = data.DetectedCount - data.ResolvedCount;
  const secondsInADay = 86400;
  const averageDays = data.AverageResponseTimeSeconds / secondsInADay;

  return (
    <div className="widget-drilldown-li common-box">
      <div className='common-panel'>
            <span className='title'>
                {data.DetectedCount}
            </span>
            <span className='common-panel-text-white'> Leaks Detected</span>
          </div>
          <div className='leak-panel-li active-leak-text-li'>
            <ul>
                    <li>
                        {activeLeaks} Active
                    </li>
                    <li className='resolved'>
                        {data.ResolvedCount} Resolved
                    </li>
                </ul>
          </div>
      <div className='vl'></div>
      <VolumeActionImpact leakDetectionResponseData={data.VolumeActionImpact}></VolumeActionImpact>
      <div className='vl'></div>
      <CostActionImpact leakDetectionResponseData={data.CostActionImpact}></CostActionImpact>
        <div className='vl'></div>
      <LeakAverageResponseTime leakDetectionResponseData={averageDays}></LeakAverageResponseTime>
    </div>
    );
}
