import { Line } from 'react-chartjs-2';
import { Chart, registerables } from "chart.js"
import './BillDataGraph.css';

Chart.register(...registerables);

const getOptions = (max) => ({
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: max
      }
    },
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Bill Usage Data (kGals)',
      },
    },
});

const BillDataGraph = ({ data }) => {
  const usages = data.map(d => d.UsageGallons);
  const mostUsage = usages.sort((a, b) => b - a)[0];
  const max = Math.round((mostUsage + (mostUsage * 0.1)) / 1000);

  const meters = [... new Set(data.map(s => s.MeterNumber))];
  
  const graphData = meters.map(meter => {
      const meterBills = data.filter(x => x.MeterNumber == meter);
      const meterUsages = meterBills.map(x => x.UsageGallons / 1000);
  
      return {
          label: meter,
          data: meterUsages,
          backgroundColor: '#fff',
          borderColor: meterBills[0]?.Color ?? '#000'
      };
  });

  const graphLabels = [... new Set(
    data.map(s => new Date(s.Start)).map(s => s.toLocaleDateString()))];

  const gd = {
      labels: graphLabels,
      datasets: graphData
  };


    return <div className='bill-data-graph-container'>
        <Line data={gd} options={getOptions(max)} />
    </div>
};

export default BillDataGraph;