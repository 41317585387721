import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import { Context as userContext } from '../../context/AuthContext';
import "./BulkCreation.css"


export default function BulkCreation() {
    const [files, setFiles] = useState();
    const { state: userState } = useContext(userContext);
    const navigate = useNavigate();

    const handleFileEvent = (e) => {
        setFiles(e.target.files);
    };

    const genericUploadFiles  = async (e) => {
        e.preventDefault();
        const formData = new FormData();
		for (let i = 0 ; i < files.length ; i++) {
            formData.append('files', files[i]);
        }
        var res = api.genericUpload(userState.token, formData).then((response) => { 
            navigate('/sales/bulkproposaldetail', {state:{data:response.data}})
        });
    };

    const energyCapUploadFiles  = async (e) => {
        e.preventDefault();
        const formData = new FormData();
		for (let i = 0 ; i < files.length ; i++) {
            formData.append('files', files[i]);
        }
        var res = api.energyCapUpload(userState.token, formData).then((response) => { 
            navigate('/sales/bulkproposaldetail', {state:{data:response.data}})
        });
    };

    const conserviceUploadFiles = async (e) => {
        e.preventDefault();
        const formData = new FormData();
		for (let i = 0 ; i < files.length ; i++) {
            formData.append('files', files[i]);
        }
        var res = await api.conserviceUpload(userState.token, formData);
        if (res.data.length > 0 ){
            navigate('/sales/bulkproposaldetail', {state:{data:res.data}});
        }
        
    };

    const simpleBillsUploadFiles = async (e) => {
        e.preventDefault();
        const formData = new FormData();
		for (let i = 0 ; i < files.length ; i++) {
            formData.append('files', files[i]);
        }
        var res = await api.simpleBillsUpload(userState.token, formData).then((response) => 
        { 
            navigate('/sales/bulkproposaldetail', {state:{data:response.data}})
        });
    };

    const waterCompassUploadFiles = async (e) => {
        e.preventDefault();
        const formData = new FormData();
		for (let i = 0 ; i < files.length ; i++) {
            formData.append('files', files[i]);
        }
        var res = await api.waterCompassUpload(userState.token, formData).then((response) => 
        { 
            navigate('/sales/bulkproposaldetail', {state:{data:response.data}})
        });
    };

    const schneiderUploadFiles = async (e) => {
        e.preventDefault();
        const formData = new FormData();
		for (let i = 0 ; i < files.length ; i++) {
            formData.append('files', files[i]);
        }
        var res = await api.schneiderUpload(userState.token, formData).then((response) => 
        { 
            navigate('/sales/bulkproposaldetail', {state:{data:response.data}})
        });
    };

    return (
        <div className="sales-home-container">
            <h2 className="sales-title">Bulk Creation</h2>
            <div style={{ textAlign: "center" }}>
                <form>
                <label className='upload-file'>
                    <input
                    type={"file"}
                    id={"fileUpload"}
                    multiple
                    accept={".xlsx, .xls, .csv"}
                    onChange={handleFileEvent}
                    />
                </label>
                <button
                    className='proposal-form-btn upload-btn'
                    onClick={(e) => genericUploadFiles(e)}>
                    Generic Upload
                </button>
                </form>
            </div>
            <div style={{ textAlign: "center" }}>
                <form>
                <label className='upload-file'>
                    <input
                    type={"file"}
                    id={"fileUpload"}
                    multiple
                    accept={".csv"}
                    onChange={handleFileEvent}
                    />
                </label>
                <button
                    className='proposal-form-btn upload-btn'
                    onClick={(e) => energyCapUploadFiles(e)}>
                    EnergyCap Upload
                </button>
                </form>
            </div>
            <div style={{ textAlign: "center" }}>
                <form>
                <label className='upload-file'>
                    <input
                    type={"file"}
                    id={"fileUpload"}
                    multiple
                    accept={".xlsx, .xls, .csv"}
                    onChange={handleFileEvent}
                    />
                </label>
                <button
                    className='proposal-form-btn upload-btn'
                    onClick={(e) => conserviceUploadFiles(e)}>
                    Conservice Upload
                </button>
                </form>
            </div>
            <div style={{ textAlign: "center" }}>
                <form>
                <label className='upload-file'>
                    <input
                    type={"file"}
                    id={"fileUpload"}
                    multiple
                    accept={".xlsx, .xls, .csv"}
                    onChange={handleFileEvent}
                    />
                </label>
                <button
                    className='proposal-form-btn upload-btn'
                    onClick={(e) => simpleBillsUploadFiles(e)}>
                    Simple Bills Upload
                </button>
                </form>
            </div>
            <div style={{ textAlign: "center" }}>
                <form>
                <label className='upload-file'>
                    <input
                    type={"file"}
                    id={"fileUpload"}
                    multiple
                    accept={".xlsx, .xls, .csv"}
                    onChange={handleFileEvent}
                    />
                </label>
                <button
                    className='proposal-form-btn upload-btn'
                    onClick={(e) => waterCompassUploadFiles(e)}>
                    Water Compass Upload
                </button>
                </form>
            </div>
            <div style={{ textAlign: "center" }}>
                <form>
                <label className='upload-file'>
                    <input
                    type={"file"}
                    id={"fileUpload"}
                    multiple
                    accept={".xlsx, .xls, .csv"}
                    onChange={handleFileEvent}
                    />
                </label>
                <button
                    className='proposal-form-btn upload-btn'
                    onClick={(e) => schneiderUploadFiles(e)}>
                    Schneider Upload
                </button>
                </form>
            </div>
        </div>
    )
}
