import { useState , useContext, useEffect } from 'react';
import { Context } from '../../context/AuthContext';
import Dropdown from "../../components/Dropdown";
import PropertyWaterSavingsDrilldownWidget from "../../components/waterSavings/PropertyWaterSavingsDrilldownWidget";
import { PropertyWaterUsageGraph } from '../../components/waterSavings/PropertyWaterUsageGraph.js';
import PropertyWaterSavingsTable from '../../components/waterSavings/PropertyWaterSavingsTable';
import ComponentWithDataForProperty from '../../components/ComponentWithDataForProperty';
import '../../components/Common.css'
import './PropertyWaterSavings.css';

const PropertyWaterSavings = () => {
  const { state } = useContext(Context);
  const propertyName = state.propertyName;
  const [rollupData, setRollupData] = useState('');
  const [hiddenCauseTypes, setHiddenCauseTypes] = useState([]);

  useEffect(() => {
    setRollupData('');
    setHiddenCauseTypes([]);
  }, [state.startDate])

  const callback = (text, gallonsSaved, netSavings) => {   
    setRollupData({
      TotalWaterSavingsInGallons: gallonsSaved,
      NetSavingsInDollars: netSavings,
      AssetValuationIncreasePercentage: netSavings / 0.05
    });

    let hiddenCauseTypesCopy = hiddenCauseTypes;
    if (hiddenCauseTypesCopy.includes(text)) {
      const idx = hiddenCauseTypesCopy.indexOf(text);
      hiddenCauseTypesCopy.splice(idx, 1);
    } else {
      hiddenCauseTypesCopy.push(text);
    }
    setHiddenCauseTypes(hiddenCauseTypesCopy)
  };

  const drilldownWidget = rollupData == '' 
  ? <ComponentWithDataForProperty child={PropertyWaterSavingsDrilldownWidget} 
      url="/PropertyWaterSavingsRollupNumbers" />
  : <PropertyWaterSavingsDrilldownWidget data={rollupData} hiddenCauseTypes={hiddenCauseTypes} />;

  return (
    <div>
      <div className='landing-screen-container-ws'>
        <div className='property-name'>
          {propertyName}
        </div>
        {drilldownWidget}
        <div className='graph-container'>
          <PropertyWaterUsageGraph callback={callback} />
        </div>
        <Dropdown />
        <ComponentWithDataForProperty child={PropertyWaterSavingsTable}  url="/PropertyWaterSavingsData" />
      </div>
    </div>
  );
};

export default PropertyWaterSavings;