import React from 'react';
import LoadingSpinner from '../LoadingSpinner';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeScale,
  LineController,
  BarController
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Chart } from 'react-chartjs-2';
import { useState, useContext, useEffect } from 'react';
import { Context } from '../../context/AuthContext';
import api from '../../api/api';
import './WaterUsageGraph.css';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale,
  LineController,
  BarController,
  Legend,
  Tooltip, 
);

const causeTypeLabels = {
  scheduled: 'Scheduled',
  unscheduled: 'Unscheduled',
  manual: 'Manual',
  nonBanyan: 'Non-Banyan Battery Operated',
  waterFeatures: 'Water Features',
  plantEstablishment: 'Plant Establishment',
  leakWasteUsage: 'Leak Waste'
};

export function PropertyWaterUsageGraph({ callback }) {
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState(['']);
  const [baselineData, setBaselineData] = useState(['']);
  const [gallonsSaved, setGallonsSaved] = useState(0);
  const [netSavings, setNetSavings] = useState(0);
  const [increase, setIncrease] = useState(0);
  const [scheduled, setScheduled] = useState(['']);
  const [unscheduled, setUnscheduled] = useState(['']);
  const [manual, setManual] = useState(['']);
  const [nonBanyan, setNonBanyan] = useState(['']);
  const [waterFeatures, setWaterFeatures] = useState(['']);
  const [plantEstablishment, setPlantEstablishment] = useState(['']);
  const [leakWasteUsage, setLeakWasteUsage] = useState(['']);
  const [rainfall, setRainfall] = useState(['']);

  const urlEndingWSGraph = '/AssetDashboard/PropertyWaterSavingsData';
  const urlRainfall = '/AssetDashboard/RainfallData';

  const { state } = useContext(Context);
  const token = state.token;
  const startDate = state.startDate;
  const endDate = state.endDate;
  const propertyId = state.propertyId;
  const accountId = state.accountId;

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [startDate, endDate]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    await fetchGraphData();
    await fetchRainfallData();
  };

  const getDataset = (legendText) => {
    switch (legendText) {
      case causeTypeLabels.scheduled:
        return scheduled;
      case causeTypeLabels.unscheduled:
        return unscheduled;
      case causeTypeLabels.manual:
        return manual;
      case causeTypeLabels.nonBanyan:
        return nonBanyan;
      case causeTypeLabels.waterFeatures:
        return waterFeatures;
      case causeTypeLabels.plantEstablishment:
        return plantEstablishment;
      case causeTypeLabels.leakWasteUsage:
        return leakWasteUsage;
    }
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Water Usage',
      },
      legend: {
        onClick: (e, legendItem, legend) => {
          const text = legendItem.text;
          const dataset = getDataset(text);

          const gallons = dataset.map(x => x.Usage).reduce((a, b) => a + b, 0);
          const cost = dataset.map(x => x.Cost).reduce((a, b) => a + b, 0);
          
          let adjustedGallonsSaved = gallonsSaved;
          let adjustedNetSavings = netSavings;

          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
              adjustedGallonsSaved += gallons;
              adjustedNetSavings += cost;
          } else {
              ci.show(index);
              legendItem.hidden = false;
              adjustedGallonsSaved -= gallons;
              adjustedNetSavings -= cost;
          }

          callback(text, adjustedGallonsSaved, adjustedNetSavings);
          setNetSavings(adjustedNetSavings);
          setGallonsSaved(adjustedGallonsSaved);
        }
      }
    },
    responsive: true,
    scales: {
      x: {
        display: true,
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        type: 'linear',
        display: true,
        position: 'left',
      },/*
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
    },*/
  },
};

  const fetchGraphData = () => {
    api.getDataPerProperty(propertyId, startDate, endDate, accountId, urlEndingWSGraph, token)
        .then((response)=>{
          const responseData = response.data;
          const data = responseData.PropertyWaterSavingsAssetDashboard;
          const fees = responseData.MonthlyFees;
          const leakAvoidance = responseData.LeakCostAvoidance;
          var labelsReadings = [];
          var baselineDataReadings = [];
          var scheduledReadings = [];
          var unscheduledReadings = [];
          var manualReadings = [];
          var nonBanyanReadings = [];
          var waterFeaturesReadings = [];
          var plantEstablishmentReadings = [];
          var leakWasteUsageReadings = [];
          let waterSavings = 0;
          let netDollarSavings = -fees;
          data.forEach(element => {
            const baseline = element.Baseline;
            const scheduled = element.Scheduled;
            const unscheduled = element.Unscheduled;
            const manual = element.Manual;
            const nonBanyan = element.NonBanyanBatteryOperated;
            const waterFeatures = element.WaterFeatures;
            const plantEstablishment = element.PlantEstablishment;
            const leakWasteUsage = element.LeakWasteUsage;
            labelsReadings.push(element.UsePeriod);
            baselineDataReadings.push(baseline)
            scheduledReadings.push(scheduled);
            unscheduledReadings.push(unscheduled);
            manualReadings.push(manual);
            nonBanyanReadings.push(nonBanyan);
            waterFeaturesReadings.push(waterFeatures);
            plantEstablishmentReadings.push(plantEstablishment);
            leakWasteUsageReadings.push(leakWasteUsage);

            const periodSavings = baseline - scheduled.Usage
              - unscheduled.Usage - manual.Usage - nonBanyan.Usage
              - waterFeatures.Usage - plantEstablishment.Usage;

            waterSavings += periodSavings;
            netDollarSavings += (element.BaselineCost - element.Cost);
          });

          setNetSavings(netDollarSavings + leakAvoidance);
          setGallonsSaved(waterSavings);
          setLabels(labelsReadings);
          setBaselineData(baselineDataReadings);
          setScheduled(scheduledReadings);
          setUnscheduled(unscheduledReadings);
          setManual(manualReadings);
          setNonBanyan(nonBanyanReadings);
          setWaterFeatures(waterFeaturesReadings);
          setPlantEstablishment(plantEstablishmentReadings);
          setLeakWasteUsage(leakWasteUsageReadings);
      })
      .catch((error) => {
        console.log('failed to get data for property water usage graph');
        console.log(error);
      });
    return {isLoading};
  }

  const fetchRainfallData = () => {
    /*api.getDataPerProperty(propertyId, startDate, endDate, accountId, urlRainfall, token)
      .then((response)=>{
        const rainReadings = response.data[0].RainfallData;
        var rainfallReadings = [];
        rainReadings.forEach(element => {
          rainfallReadings.push(element.Rainfall);
        });

        setRainfall(rainfallReadings);
        */setIsLoading(false);/*
    })
    .catch((error) => {
        console.log(error)
    });*/
    return {isLoading};
  }

  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Baseline',
        borderColor: '#000000',
        borderWidth: 2,
        fill: false,
        data: baselineData,
        yAxisID: 'y',
      },
      /*{
        type: 'line',
        label: 'Rainfall (inches)',
        borderColor: '#ffa8a8',
        borderWidth: 2,
        fill: true,
        data: rainfall,
        yAxisID: 'y1',
      },*/
      {
        type: 'bar',
        label: causeTypeLabels.scheduled,
        backgroundColor: '#7B9DC3',
        data: scheduled.map(x => x.Usage),
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: causeTypeLabels.unscheduled,
        backgroundColor: '#F8E472',
        data: unscheduled.map(x => x.Usage),
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: causeTypeLabels.manual,
        backgroundColor: '#E4B363',
        data: manual.map(x => x.Usage),
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: causeTypeLabels.plantEstablishment,
        backgroundColor: '#96A480',
        data: plantEstablishment.map(x => x.Usage),
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: causeTypeLabels.nonBanyan,
        backgroundColor: '#5E655E',
        data: nonBanyan.map(x => x.Usage),
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: causeTypeLabels.waterFeatures,
        backgroundColor: '#C4DAE0',
        data: waterFeatures.map(x => x.Usage),
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: causeTypeLabels.leakWasteUsage,
        backgroundColor: '#FFA8A8',
        data: leakWasteUsage.map(x => x.Usage),
        stack: 'Stack 0',
      },
    ].filter(dataset => dataset.data.some(data => data > 0)),
  };

  return (
  <div className='wrapper'>
    {isLoading ? <LoadingSpinner /> 
      : (<Chart type='bar' options={options} data={data}/>)}
  </div>
  )
}
