import { useContext, useState, useEffect } from 'react';
import { Context } from '../../../context/ProposalContext';
import { Context as userContext } from '../../../context/AuthContext';
import api from '../../../api/api';
import InputFormField from './InputFormField';
import SelectFormField from './SelectFormField';
import BooleanSelectFormField from './BooleanSelectFormField';
import SpecialChoiceFormField from './SpecialChoiceFormField';
import QuestionIds from '../../../helpers/questionIds.json';
import CustomModal from '../../CustomModal';
import SpecialNumberFormField from './SpecialNumberFormField';

const FormField = ({ id, question, metadata, setHasUpdate }) => {
    const { state, updateProposalAnswers, updateUtilityRateOptions, updateUtility } = useContext(Context);
    const formErrors = state.formErrors;

    const { state: userState } = useContext(userContext);
    const [isShowingEstimateHint, setIsShowingEstimateHint] = useState(false);
    const estimateFieldIds = [QuestionIds.cityMeters, QuestionIds.billedMeters,
QuestionIds.controllerCount, QuestionIds.zoneCount];

    useEffect(() => {
        (async () => {
            if (state.proposalAnswers[QuestionIds.utility]) {
                if (id === QuestionIds.utility) {
                    updateUtility(state.proposalAnswers[QuestionIds.utility])
                }
                if (id === QuestionIds.utilityRate) {
                    await getUtilityRateOptions(state.proposalAnswers[QuestionIds.utility])
                }
            }
        })()
    }, [])
    
    const getUtilityRateOptions = async (id) => {
        try {
            let res = await api.getRatesPerUtility(userState.token, id);
            const data = res.data.map((d) => ({ label: d.Name, value: d.Id }));
            updateUtilityRateOptions(data);
        } catch (e) {
            console.log('error getting utility rate options for id: ' + id, e);
        }
    };
    const getDefaultValue = (options, id, isMulti) => {
        const value = state.proposalAnswers[id];
        if (!value || !options) return null;
        if (isMulti) return options.filter((o) => value.includes(o.value));

        if (id === QuestionIds.utility) {
            return options.filter((o) => o.value.Id.toString() === value);
        }

        if (value === "true") return [{ label: 'Yes', value: 'true' }];
        if (value === "false") return [{ label: 'No', value: 'false' }];

        return options.filter(opt => opt.value.toString() === value);
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        let formDataCopy = state.proposalAnswers;
        formDataCopy[name] = value;
        updateProposalAnswers(formDataCopy);
        setHasUpdate(true);
    };

    const handleSelect = async (e, name, isMulti) => {
        let formDataCopy = state.proposalAnswers;
        if (isMulti) {
            let value = e.map(e => e.value);
            formDataCopy[name] = value;
        } else {
            if (name === QuestionIds.utility) {
                formDataCopy[name] = e.value.Id;
                updateUtility(e.value.Id);
                await getUtilityRateOptions(e.value.Id);
            } else {
                formDataCopy[name] = e.value;
            }
        }

        if (name === QuestionIds.dedicatedIrrigationMeters) {
            if (e.value === 'true') {
                formDataCopy[QuestionIds.percentNondedicatedMeters] = "";
            }
            if (e.value === 'false') {
                if (formDataCopy[QuestionIds.percentNondedicatedMeters] === "") {
                    formDataCopy[QuestionIds.percentNondedicatedMeters] = "40";
                }
            }
        }

        updateProposalAnswers(formDataCopy);
        setHasUpdate(true);
    };

    const handleEstimate = async (id) => {
        const squareFootage = parseFloat(state.proposalAnswers[QuestionIds.squareFootage]);
        const percentIrrigatedArea = parseFloat(state.proposalAnswers[QuestionIds.percentIrrigatedArea]);
        let irrigatedArea = squareFootage * percentIrrigatedArea / 100;
        let estimate;
        if (!!irrigatedArea) {
            if (id === QuestionIds.billedMeters || id === QuestionIds.cityMeters)
                estimate = await api.getMeterEstimate(userState.token, irrigatedArea);

            if (id === QuestionIds.controllerCount)
                estimate = await api.getControllerEstimate(userState.token, irrigatedArea);

            if (id === QuestionIds.zoneCount)
                estimate = await api.getZoneEstimate(userState.token, irrigatedArea);

            let formDataCopy = state.proposalAnswers;
            formDataCopy[id] = estimate.data.toString();
            updateProposalAnswers(formDataCopy);

            setHasUpdate(true);

        } else {
            setIsShowingEstimateHint(true);
        }
        return estimate.data.toString();
    };

    const getSquareFootage = async () => {
        const address = state.proposalAnswers[QuestionIds.address];
        const city = state.proposalAnswers[QuestionIds.city];
        const addressState = state.proposalAnswers[QuestionIds.state];

        if (address == "" || city == "" || addressState == "") {
            setIsShowingEstimateHint(true);
            return '';
        }

        const fullAddress = address + ", " + city + ", " + addressState;

        const squareFeet = (await api.getSquareFootage(userState.token, fullAddress)).data;

        let formDataCopy = state.proposalAnswers;
        formDataCopy[QuestionIds.squareFootage] = squareFeet.toString();
        updateProposalAnswers(formDataCopy);

        setHasUpdate(true);

        return squareFeet;
    };

    const type = metadata["type"];
    let inputComponent = null;

    switch (type) {
        case "string":
        default:
            inputComponent = <InputFormField
                key={id}
                id={id}
                question={question}
                type="text"
                defaultValue={state.proposalAnswers[id]}
                handleChange={handleChange}
                hasError={formErrors.includes(id)} />;
            break;
        case "number":
            inputComponent = <InputFormField
                key={id}
                id={id}
                question={question}
                type="number"
                defaultValue={state.proposalAnswers[id]}
                handleChange={handleChange}
                hasError={formErrors.includes(id)}
                hasEstimateBtn={estimateFieldIds.includes(id)}
                squareFootageBtn={id == QuestionIds.squareFootage}
                getSquareFootage={getSquareFootage}
                handleEstimate={handleEstimate} />;
            break;
        case "choice":
            inputComponent = <SelectFormField
                key={id}
                id={id}
                question={question}
                metadata={metadata}
                isMulti={false}
                getDefaultValue={getDefaultValue}
                handleSelect={handleSelect}
                hasError={formErrors.includes(id)} />;
            break;
        case "date":
            inputComponent = <InputFormField
                key={id}
                id={id}
                question={question}
                type="date"
                defaultValue={state.proposalAnswers[id]}
                handleChange={handleChange}
                hasError={formErrors.includes(id)} />;
            break;
        case "boolean":
            inputComponent = <BooleanSelectFormField
                key={id}
                id={id}
                question={question}
                getDefaultValue={getDefaultValue}
                handleSelect={handleSelect}
                hasError={formErrors.includes(id)} />;
            break;
        case "multichoice":
            inputComponent = <SelectFormField
                key={id}
                id={id}
                question={question}
                metadata={metadata}
                isMulti={true}
                getDefaultValue={getDefaultValue}
                handleSelect={handleSelect}
                hasError={formErrors.includes(id)} />;
            break;
        case "specialchoice":
            inputComponent = <SpecialChoiceFormField
                key={id}
                id={id}
                question={question}
                parentValue={state.proposalAnswers[QuestionIds.utility]}
                options={state.utilityRateOptions}
                getDefaultValue={getDefaultValue}
                handleSelect={handleSelect}
                hasError={formErrors.includes(id)} />;
            break;
        case "specialnumber":
            inputComponent = <SpecialNumberFormField
                key={id}
                id={id}
                question={question}
                parentValue={state.proposalAnswers[QuestionIds.dedicatedIrrigationMeters]}
                defaultValue={state.proposalAnswers[id]}
                handleChange={handleChange}
                hasError={formErrors.includes(id)} />;
            break;
    }

    return (
        <div>
            {inputComponent}
            <CustomModal isOpen={isShowingEstimateHint}
                closeModal={() => setIsShowingEstimateHint(false)}
                title="Invalid Estimate Inputs">
                <div className="proposal-form-hint-content">
                    <p>Please input valid</p>
                    <ul>
                        <li>Total Property Area Sqft</li>
                        <li>Assumed &#37; of Irrigated Area</li>
                    </ul>
                    <p>to get estimate.</p>
                </div>
            </CustomModal>
        </div>
    );
}

export default FormField;