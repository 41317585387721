
import '../../Common.css'
import React, { useState } from 'react';
import '../BackButton';
import { useEffect } from 'react';
import EditableTable from '../../EditableTable';


const BillTable = ({ data, updateMyData }) => {
  const tabularData = data.map(obj => {return obj;})

  const [actualData, setActualData] = useState(tabularData);
  const [skipPageReset, setSkipPageReset] = React.useState(true)

  useEffect(() => {
    setActualData(tabularData);
  }, [data]);

  const tableData = React.useMemo(
    () => actualData,
    [actualData]
  );

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Meter Number',
        accessor: 'MeterNumber', // accessor is the "key" in the data
      },
      {
        Header: 'Bill Start',
        accessor: 'Start',
      },
      {
        Header: 'Bill End',
        accessor: 'End',
      },
      {
        Header: 'Usage Volume',
        accessor: 'UsageVolume',
      },
      {
        Header: 'Total Cost',
        accessor: 'TotalCost',
      },
      {
        Header: 'Sewer Amount',
        accessor: 'SewerAmount',
      },
      {
        Header: 'Other Amount',
        accessor: 'OtherAmount',
      },
    ],
    []
  );

  React.useEffect(() => {
    setSkipPageReset(false)
  }, []);
  
  return (
    <div>
        <div className='table-container-wst'>
        <EditableTable
        columns={tableColumns}
        data={tableData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        />
        </div>
    </div>
  );
}


export default BillTable;