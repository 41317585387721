
import WaterSavingsRollup from "../../components/assetDashboardHomeIcons/WaterSavingsRollup";
import AlertCenter from '../../components/assetDashboardHomeIcons/AlertCenter';
import CarbonOffset from "../../components/assetDashboardHomeIcons/CarbonOffset";
import LeakDetection from '../../components/assetDashboardHomeIcons/LeakDetection';
import SurveyResponse from '../../components/assetDashboardHomeIcons/SurveyResponse';
import Repairs from '../../components/assetDashboardHomeIcons/Repairs';
import Installations from '../../components/assetDashboardHomeIcons/Installations';
import './Landing.css';
import '../../components/Common.css'
import Dropdown from "../../components/Dropdown";
import ComponentWithData from "../../components/ComponentWithData";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import { useContext } from "react";

const Landing = () => {
    const letterhead = require('../../../src/assets/images/letterhead.png');
    const navigate = useNavigate();
    const { signout, setToken, state } = useContext(Context);
    const isAdmin = state.isAdmin;

    const handleLogout = () => {
        window.localStorage.removeItem("rememberMe");
        localStorage.clear();
        signout();
        navigate('');
    };

    const handleBack = () => {
        navigate('/assetadminview');
    };

    return (
        <div>
            <div className='letterhead-container'>
                <img
                    src={letterhead}
                    alt="Banyan Water Letterhead with Logo"
                    className="letterhead"
                ></img>
            </div>
            <div className="back-button-container">
                {isAdmin ? <button onClick={handleBack}>Back</button> : null}
            </div>
            <div className='landing-screen-container'>
                    <br></br>
                    <ComponentWithData child={WaterSavingsRollup} url="WaterSavingsRollupNumbers" />
                    <br></br>
                    <div className='row-container'>
                        {/* {isAdmin ? <ComponentWithData child={AlertCenter} url="AlertCenterNumbers" /> : null} */}
                        <ComponentWithData child={LeakDetection} url="LeakDetectionRollupNumbers" />
                        <ComponentWithData child={CarbonOffset} url="CarbonOffsetAcrossPortfolio" />
                        <div className="right-column-container">
                            {/* {isAdmin ? <ComponentWithData child={SurveyResponse} url="SurveyResponseRate" /> : null} */}
                            <ComponentWithData child={Installations} url="InstallationMetric" />
                            <ComponentWithData child={Repairs} url="RepairsMetric" />
                        </div>
                    </div>
                    <Dropdown/>
                </div>
            <button onClick={handleLogout}>Logout</button>
        </div>)
}

export default Landing;