import React from 'react';
import { CSVLink } from 'react-csv';

export default function BebbiaExportCSVButton({ tableData }) {
    return (
        <div className="bebbia-csv-button-wrapper">
            <CSVLink
                headers={tableData.headers}
                data={tableData.data}
                filename={tableData.filename}
            >Haga clic aquí para descargar el archivo CSV</CSVLink>
        </div>
    )
};
