import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../context/AuthContext';
import api from '../../api/api';
import Table from '../Table';
import NumberFormatTwoDecimalPlaces from '../NumberFormatTwoDecimalPlaces';
import PercentFormat from '../PercentFormat';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from '../LoadingSpinner';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';

export default function BebbiaPortfolioTable() {
    registerLocale('es', es);
    const { state } = useContext(Context);
    const token = state.token;

    const [dateRange, setDateRange] = useState([null, null]);
    const [tableData, setTableData] = useState({});
    const [tableColumns, setTableColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (dateRange[0] === null || dateRange[1] === null) return;

        const options = {
            StartDate: moment(dateRange[0]).utcOffset(0, true),
            EndDate: moment(dateRange[1]).utcOffset(0, true)
        };
        (async () => {
            setIsLoading(true);
            const res = await api.getBebbiaPortfolioTableData(token, options);
            setTableData(generateTableData(res.data));
        })();
    }, [dateRange])

    useEffect(() => {
        if (!tableData.length) return;
        setTableColumns([
            {
                Header: 'Client ID',
                accessor: 'Client ID', // accessor is the "key" in the data
            },
            {
                Header: 'Purifier ID',
                accessor: 'Purifier ID',
            },
            {
                Header: 'CP',
                accessor: 'CP'
            },
            {
                Header: 'Fecha',
                accessor: 'Fecha'
            },
            {
                Header: 'Fecha lectura del purificador (dd/mm/aaaa)',
                accessor: 'Fecha lectura del purificador (dd/mm/aaaa)'
            },
            {
                Header: 'Fecha instalación',
                accessor: 'Fecha instalación'
            },
            {
                Header: 'Fecha último mtto',
                accessor: 'Fecha último mtto'
            },
            {
                Header: 'Último tipo work order',
                accessor: 'Último tipo work order'
            },
            {
                Header: '% Vida útil del cartucho - Primario',
                accessor: '% Vida útil del cartucho - Primario',
                Cell: ({ cell: { value } }) => <PercentFormat data={value} />
            },
            {
                Header: '% Vida útil del cartucho - Membrane',
                accessor: '% Vida útil del cartucho - Membrane',
                Cell: ({ cell: { value } }) => <PercentFormat data={value} />
            },
            {
                Header: 'Promedio rango TDS(o)',
                accessor: 'Promedio rango TDS(o)',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Max TDS(o) día',
                accessor: 'Max TDS(o) día',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Min TDS(o) día',
                accessor: 'Min TDS(o) día',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Promedio Delta de TDS',
                accessor: 'Promedio Delta de TDS',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Flujo promedio día',
                accessor: 'Flujo promedio día',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Flujo ultimos 7 días',
                accessor: 'Flujo ultimos 7 días',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Consumo ultimo día',
                accessor: 'Consumo ultimo día',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Consumo ultimos 7 días',
                accessor: 'Consumo ultimos 7 días',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Conectado Wifi?',
                accessor: 'Conectado Wifi?'
            }
        ]);
        setIsLoading(false);
    }, [tableData]);

    const generateTableData = (data) => {
        return data.map(d => ({
            'Client ID': d.ClientId,
            'Purifier ID': d.PurifierId,
            'CP': d.ZipCode,
            'Fecha': moment.utc(d.RowDate).format("DD/MM/YYYY"),
            'Fecha lectura del purificador (dd/mm/aaaa)': moment.utc(d.LastEventDate).format("DD/MM/YYYY"),
            'Fecha instalación': moment.utc(d.InstallationDate).format("DD/MM/YYYY"),
            'Fecha último mtto': moment.utc(d.LastMaintenanceDate).format("DD/MM/YYYY"),
            'Último tipo work order': d.WorkOrderType,
            '% Vida útil del cartucho - Primario': d.RemainingLifetimePrimary,
            '% Vida útil del cartucho - Membrane': d.RemainingLifetimeMembrane,
            'Promedio rango TDS(o)': d.AverageTDS,
            'Max TDS(o) día': d.MaxTDS,
            'Min TDS(o) día': d.MinTDS,
            'Promedio Delta de TDS': d.AverageTDSDelta,
            'Flujo promedio día': d.AverageFlowRate,
            'Flujo ultimos 7 días': d.AverageFlowRateInLastSevenDays,
            'Consumo ultimo día': d.WaterConsumption,
            'Consumo ultimos 7 días': d.WaterConsumptionInLastSevenDays,
            'Conectado Wifi?': d.IsConnectedToWIFI ? "TRUE" : "FALSE"
        }));
    }

    return (
        <>
            <div className='bebbia-opertaions-table-wrapper'>
                <div className="bebbia-operations-date-range-wrapper">
                    <div className='bebbia-date-range'>
                        <DateRangePicker
                            locale="es"
                            clearIcon={null}
                            onChange={(e) => setDateRange([e[0], e[1]])}
                            value={dateRange} />
                    </div>
                </div>
                {isLoading
                    ? <LoadingSpinner />
                    : tableData.length > 0
                    && <Table
                        columns={tableColumns}
                        defaultData={tableData}
                        scrollXMax={'100%'}
                        fileName={`${moment.utc(dateRange.startDate).format("DDMMYYYY")} - ${moment.utc(dateRange.endDate).format("DDMMYYYY")} - Tabla de Informe de Portafolio`}
                        isES={true} />
                }
            </div>
        </>
    )
}
