export const ReactSelectCustomTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        neutral20: 'rgba(94, 101, 94, 1)',
        primary: '#C4DAE0',
    }
})

export const ReactSelectCustomStyles = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? '3px solid #C4DAE0' : '3px solid transparent',
        '&:hover': { borderColor: '#C4DAE0' },
    }),
    option: base => ({
        ...base,
        color: '#5E655E'
    }),
    menu: base => ({
        ...base,
        border: '3px solid #C4DAE0'
    }),
    multiValueLabel: base => ({
        ...base,
        color: '#5E655E',
        backgroundColor: '#C4DAE0'
    }),
    multiValueRemove: base => ({
        ...base,
        color: '#5E655E',
        backgroundColor: '#C4DAE0',
        '&:hover': {
            backgroundColor: '#E4B363',
            color: '#5E655E'
        }
    })
};