import './SurveyResponse.css'
import '../Common.css'
import { useNavigate } from 'react-router-dom';

export default function SurveyResponse({ data }) {
  const navigate = useNavigate();

  return (
    <div className="survey-response common-box" 
      onClick={() => navigate("/responserates")}>
          <div className='survey-panel'>
            <div>
              <div className="survey-big-number title">
                {Math.round(data.ResponseRate)}%
              </div>
              <div className='big-survey-text title'>
                Customer Survey Response Rate
              </div>
            </div>
          </div>
        </div>
  );
}
