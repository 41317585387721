
import '../Common.css' 
import './LeakAverageResponseTime.css'
import NumberHeader from '../NumberHeader'

export default function LeakAverageResponseTime({leakDetectionResponseData}){

 return(   
    <div className='common-panel'>
        <span className='leak-response-time-panel-title title'>
        <NumberHeader data={leakDetectionResponseData}></NumberHeader> days
            </span>
        <span className='common-panel-text-white'>Average Time of Resolution</span>
    </div>
 )
}