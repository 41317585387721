import { useState, useEffect, useContext } from 'react';
import { Context } from '../../context/AuthContext';
import api from '../../api/api';
import LoadingSpinner from '../../components/LoadingSpinner';
import './moisture.css';

const tableRows = (data) => {
    return data.map(d => {
        return <div className='table-row'>
            <div className='table-cell table-data'>
                {d.Area}
            </div>
            <div className='table-cell table-data'>
               {d.IsSaturated == true ? 'Wet' : 'Dry'}
            </div>
            <div className='time-cell table-data'>
                {d.ReadingTime}
            </div>
        </div>
    });
}

const Moisture = () => {
    const { state } = useContext(Context);
    const token = state.token;
    const [data, setData] = useState('');

    const getData = async () => {
        const response = await api.getYahooData(token);
        setData(response.data);
    };

    useEffect(() => {
        getData();
    }, [])

    if (data == '')
        return <div style={{marginTop:150, marginBottom: 365}}>
            <LoadingSpinner /></div>;

    return <div>
        <h2 style={{marginLeft: 50}}>Yahoo! Evap Cooling 
            <button className='table-show-more' onClick={() => getData()} style={{marginLeft: 25, display: 'inline'}}>
                Reload data
            </button>
        </h2>
        <div className='table-container-evap'>
            <div className='column-headers table-row'>
                <div className='table-cell'>
                    Area
                </div>
                <div className='table-cell'>
                    Saturation
                </div>
                <div className='table-cell time-header'>
                    Time
                </div>
            </div>
            {tableRows(data)}
        </div>
    </div>
};

export default Moisture;