import { useState, useContext, useEffect } from 'react'
import api from '../api/api';
import { Context } from "../context/AuthContext";
import LoadingSpinner from './LoadingSpinner';

export default function ComponentWithDataForProperty({ child, url, controller='/AssetDashboard'}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(false);
  
  const { state } = useContext(Context);
  const token = state.token;
  const startDate = state.startDate;
  const endDate = state.endDate;
  const propertyId = state.propertyId;
  const accountId = state.accountId;

  useEffect(() => {
    (async () => {
      setIsLoaded(false);
      await fetchData();
    })();
  }, [startDate, endDate]);

  const fetchData = () => {
    api.getDataPerProperty(propertyId, startDate, endDate, accountId, controller + url, token)
        .then((response)=>{
            setData(response.data);
            setIsLoaded(true);
        })
        .catch((error) => {
          console.log('failed to get data for component');
          console.log(controller + url);
          console.log(error);
        });
  }

  const Component = child;
  return isLoaded
    ? <Component data={data} />
    : <LoadingSpinner />;
}
