import React from 'react'
import Select from 'react-select'
import { ReactSelectCustomTheme, ReactSelectCustomStyles } from '../../../constants/selectFomFieldStyle'


export default function BooleanSelectFormField({ id, question, getDefaultValue, handleSelect, hasError }) {
    const options = [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }];
    return (<div className='proposal-form-field-wrapper' key={`q-${id}`}>
        <div className='proposal-form-row' >
            <label >{question}</label>
            <div className='proposal-form-select-wrapper'>
                <Select name={id}
                    defaultValue={() => getDefaultValue(options, id)}
                    onChange={(e) => handleSelect(e, id, false)}
                    placeholder='Select...'
                    theme={(theme) => ReactSelectCustomTheme(theme)}
                    styles={ReactSelectCustomStyles}
                    options={options} />
            </div>
        </div>
        {hasError && <div className='proposal-form-error'>This field has invalid input.</div>}
    </div>);
}
