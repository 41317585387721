import React, {useContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import api from '../../api/api';
import { Context } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { Context as proposalContext } from '../../context/ProposalContext';
import QuestionIds from '../../helpers/questionIds.json';

export default function Archive() {
    const navigate = useNavigate()
    const location = useLocation();
    const [proposals, setProposals] = useState([]);
    const { state } = useContext(Context);
    const { updateProposalId, updateProposalAnswers } = useContext(proposalContext);
    const token = state.token;

    useEffect(() => {
        (async () => {
          await fetchData();
        })();
      }, []);
    
      const fetchData = () => {
        api.getProposals(token, location.state.status)
            .then((response)=>{
                setProposals(response.data);
            })
            .catch((error) => {
                console.log(error)
            })  
      }

    var status = "";

    if (location.state.status ==="won") {
        status =  "Won";
    }
    else if (location.state.status==="lost"){
        status = "Lost";
    }
    else if (location.state.status==="disqualified"){
        status = "Disqualified";
    }
    else {
        status = "Stale";
    }

    const chooseProposal = async (proposal) => {
        const id = proposal.Id;
        const response = await api.getProposalDraft(state.token, id);

        let formResponses = {};
        response.data.Responses.forEach((element, index) => {
            if (index + 1 === QuestionIds.plantType) {
                formResponses[index + 1] = JSON.parse(element.ResponseValue);
            } else {
                formResponses[index + 1] = element.ResponseValue;
            }
        });

        updateProposalId(id);
        updateProposalAnswers(formResponses);

        navigate("/sales/billproposal", {state:{Id:id, Status:proposal.Status}});
    };

    return (
        <div className="sales-home-container">
        <h2 className="sales-title">{status} Proposals</h2>
        <section className="sales-tiles-container">
            {[...proposals].map((e) => (<div className="clickable-tile" 
                onClick={() => chooseProposal(e)}key={e.Id}>
                    <span> {e.PropertyName}</span>
                </div>))}
        </section>
        </div>
    );
  }