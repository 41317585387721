import React, { useState, useEffect, useContext } from 'react';
import { Context as userContext } from '../../../context/AuthContext';
import api from '../../../api/api';
import LoadingSpinner from '../../LoadingSpinner';
import "./ImportConserviceBillForm.css"

export const ImportConserviceBillForm = ({ propertyName, accountName, importFromConservice }) => {
    const { state: userState } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true);
    const [meterData, setMeterData] = useState([]);
    const [selectedMeters, setSelectedMeters] = useState({});
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const res = await api.getUtilityMeters(userState.token, propertyName, accountName);
                setMeterData(res.data);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [])

    const handleCheck = (e) => {
        const { name } = e.target;
        var isSelected = !!selectedMeters[name];
        if (!isSelected) {
            const targetMeter = meterData.find(m => m.UniqueMeterId == name);
            setSelectedMeters(data => ({ ...data, [name]: targetMeter }));
        } else {
            setSelectedMeters(data => {
                var dataCopy = { ...data };
                delete dataCopy[name];
                return dataCopy;
            })
        }
    }

    const handleImport = () => {
        if (selectedMeters.length == 0) {
            setErrorMsg("Please select at least one meter.");
        } else {
            const meterBills = [];
            for (let k in selectedMeters) {
                const bills = selectedMeters[k].Bills.map(b => ({
                    MeterNumber: selectedMeters[k].UniqueMeterId,
                    Start: b.Start,
                    End: b.End,
                    UsageVolume: b.UsageVolume,
                    TotalCost: b.TotalCost,
                    SewerAmount: b.SewerAmount,
                    OtherAmount: b.OtherAmount,
                }))
                meterBills.push(...bills);
            }
            importFromConservice(meterBills);
        }
    }

    const renderConserviceRow = (meter) => {
        return <div className="conservice-bill-modal-row" key={meter.UniqueMeterId}>
            <div className="conservice-bill-modal-label">
                <label htmlFor={meter.UniqueMeterId}>{meter.UniqueMeterId}</label>
            </div>
            <input
                checked={!!selectedMeters[meter.UniqueMeterId]}
                type="checkbox"
                id={meter.UniqueMeterId}
                name={meter.UniqueMeterId}
                onChange={handleCheck}
            />
        </div>;
    }

    return (
        <div className="conservice-bill-modal-wrapper">
            {isLoading ?
                <div className="conservice-bill-modal-spinner-wrapper"><LoadingSpinner /></div> :
                meterData.length ?
                    <>
                        {meterData.map((m) => renderConserviceRow(m))}
                        <div className="conservice-bill-modal-button-wrapper">
                            {errorMsg.length > 0 && <div>{errorMsg}</div>}
                            <button
                                className="proposal-form-btn proposal-form-submit-btn"
                                onClick={handleImport}>Import</button>
                        </div>
                    </>
                    :
                    <div>Didn't find meter data for this property.</div>
            }
        </div >
    )
}
