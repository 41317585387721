import { useEffect, useState } from 'react';

function TextInput ({defaultValue, action, placeholder, password, login}) {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (v) => {
        action(v);
        setValue(v);
    };

    return (
        <input
            className="password-input sign-in-input" 
            placeholder={placeholder}
            value={value}
            onChange={e => handleChange(e.target.value)}
            onKeyDown={e => {
                 if (e.key === 'Enter') {
                    login();
                 }
             }}
            type={password ? "password" : "text"}
        ></input>
    );
}

export default TextInput;