import { useState , useContext, useEffect } from 'react';
import { Context } from '../../context/AuthContext';
import Dropdown from "../../components/Dropdown";
import RepairsTable from '../../components/repairs/RepairsTable';
import ComponentWithDataForProperty from '../../components/ComponentWithDataForProperty';
import '../../components/Common.css'

const RepairsPage = () => {

  return (
    <div>
      <div className='landing-screen-container-ws'>
        <Dropdown />
        <ComponentWithDataForProperty child={RepairsTable}  url="/PropertiesRepairsMetric" />
      </div>
    </div>
  );
};

export default RepairsPage;