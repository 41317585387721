import './Common.css';

export default function NumberFormat({data}){

 return( 
   <span>
        {new Intl.NumberFormat("us-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
              }).format(data)} 
   </span>
 )
}