import { useState, useContext, useEffect } from 'react'
import api from '../api/api';
import { Context } from "../context/AuthContext";
import LoadingSpinner from './LoadingSpinner';

export default function ComponentWithData({ child, url, controller='/AssetDashboard/' }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(false);
  
  const { state } = useContext(Context);
  const token = state.token;
  const startDate = state.startDate;
  const endDate = state.endDate;
  const accountId = state.accountId;

  useEffect(() => {
    (async () => {
      setIsLoaded(false);
      await fetchData();
    })();
  }, [startDate, endDate]);

  const fetchData = () => {
    api.getData(startDate, endDate, accountId, controller + url, token)
        .then((response)=>{
            setData(response.data);
            setIsLoaded(true);
        })
        .catch((error) => {
            console.log(error)
        })  
  }

  const Component = child;
  return isLoaded
    ? <Component data={data} />
    : <LoadingSpinner />;
}
