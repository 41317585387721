import React, { useContext, useEffect, useState } from 'react';
import Navbar from "../../components/Navbar";
import api from '../../api/api';
import { Table, Select } from "antd";
import { Context } from '../../context/AuthContext';
import "../commsDown/CommsDown.css";
import { CSVLink } from "react-csv";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const CommsDownReport = () => {
    const [dataSource, setDataSource] = useState([]);
    const [pieData, setPieData] = useState({datasets: []});
    const [newReasonsAndNotes, setNewReasonsAndNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useContext(Context);
    const token = state.token;

    const onChange = (e, index) => {
      const newData = [...dataSource];
      const newItem = { ...newData[index] };
      newItem.LastNote = e.target.value;
      newItem.CommsDownReason = "Unknown";
      setNewReasonsAndNotes(newReasonsAndNotes => [...newReasonsAndNotes, newItem]);
    };
    const onReasonChange = (e, index) => {
      const newData = [...dataSource];
      const newItem = { ...newData[index] };
      newItem.LastNote = "-";
      newItem.CommsDownReason = e.toString();
      setNewReasonsAndNotes(newReasonsAndNotes => [...newReasonsAndNotes, newItem]);
      
    };

    const getCountsForPie = (c) => {
      let powerCount = 0;
      let stolenCount = 0;
      let cellCount = 0;
      let damagedCount = 0;
      let weatherCount = 0;
      let bugCount = 0;
      let unknownCount = 0;

      c.forEach(function(dataPoint) {
        if(dataPoint.CommsDownReason == "Power"){
          powerCount++;
        }
        else if(dataPoint.CommsDownReason == "Stolen"){
          stolenCount++;
        }
        else if(dataPoint.CommsDownReason == "Cellular"){
          cellCount++;
        }
        else if(dataPoint.CommsDownReason == "Damaged"){
          damagedCount++;
        }
        else if(dataPoint.CommsDownReason == "WeatherDamage"){
          weatherCount++;
        }
        else if(dataPoint.CommsDownReason == "BugDamage"){
          bugCount++;
        }
        else if(dataPoint.CommsDownReason == "Unknown"){
          unknownCount++;
        }
      });

      const pieDataCounts = {
        labels: ["Power", "Stolen", "Cellular", "Damaged", "Weather Damage", "Bug Damage", "Unknown"],
        datasets: [
          {
            data: [powerCount, stolenCount, cellCount, damagedCount, weatherCount, bugCount, unknownCount],
            backgroundColor: [
              '#034C8C',
              '#3D588D',
              '#93D0FF',
              '#7B9DC3',
              '#6783A2',
              '#526982',
              '#20A286',
            ],
            borderColor: [
              '#034C8C',
              '#3D588D',
              '#93D0FF',
              '#7B9DC3',
              '#6783A2',
              '#526982',
              '#20A286',
            ],
            borderWidth: 1,
          },
        ],
      };

      return pieDataCounts;
    }


    const columns = [
        {
          title: 'Account',
          dataIndex: 'Account',
          filters: mapFilter('Account'),
          filterSearch: true,
          onFilter: (value, record) => record.Account.startsWith(value),  
          sorter: {
            compare: (a, b) => a.Account.localeCompare(b.Account),
            multiple: 1,
          },
        },
        {
          title: 'Property',
          dataIndex: 'Property',
          filters: mapFilter('Property'),
          filterSearch: true,
          onFilter: (value, record) => record.Property.startsWith(value),  
          sorter: {
            compare: (a, b) => a.Property.localeCompare(b.Property),
            multiple: 1,
          },
        },
        {
          title: 'Install Date',
          dataIndex: 'InstallDate',
          filters: mapFilter('InstallDate'),
          filterSearch: true,
          onFilter: (value, record) => record.InstallDate.startsWith(value), 
          sorter: {
            compare: (a, b) => new Date(a.InstallDate) - new Date(b.InstallDate),
            multiple: 2,
          },
        },
        {
          title: 'Warranty',
          dataIndex: 'Warranty',
          filters: mapFilter('Warranty'),
          filterSearch: true,
          onFilter: (value, record) => record.Warranty.startsWith(value), 
          sorter: {
            compare: (a, b) => a.Warranty.localeCompare(b.Warranty),
            multiple: 3,
          },
        },
        {
          title: 'Controller',
          dataIndex: 'Controller',
          filters: mapFilter('Controller'),
          filterSearch: true,
          onFilter: (value, record) => record.Controller.startsWith(value), 
          sorter: {
            compare: (a, b) => a.Controller.localeCompare(b.Controller),
            multiple: 4,
          },
        },
        {
            title: 'Last Comms',
            dataIndex: 'LastComms',
            filters: mapFilter('LastComms'),
            filterSearch: true,
            onFilter: (value, record) => record.LastComms.startsWith(value), 
            sorter: {
              compare: (a, b) => new Date(a.LastComms) - new Date(b.LastComms),
              multiple: 4,
            },
          },
        {
          title: 'Type',
          dataIndex: 'ControllerType',
          filters: mapFilter('ControllerType'),
          filterSearch: true,
          onFilter: (value, record) => record.ControllerType.startsWith(value), 
          sorter: {
            compare: (a, b) => a.ControllerType.localeCompare(b.ControllerType),
            multiple: 5,
          }
        },
        {
          title: 'Last Note',
          dataIndex: 'LastNote',
          filters: mapFilter('LastNote'),
          filterSearch: true,
          onFilter: (value, record) => record.LastNote.startsWith(value), 
          sorter: {
            compare: (a, b) => a.LastNote.localeCompare(b.LastNote),
            multiple: 6,
          }
        },
        {
          title: 'Reason',
          dataIndex: 'Reason',
          key: 'Reason',
          render: (select, record, index) => 
         <Select placeholder={record.Reason} onChange={(v) => onReasonChange(v, index)}>
          <Select.Option value="Power">Power</Select.Option>
          <Select.Option value="Stolen">Stolen</Select.Option>
          <Select.Option value="Cellular">Cellular</Select.Option>
          <Select.Option value="Damaged">Damaged</Select.Option>
          <Select.Option value="WeatherDamage">Weather Damage</Select.Option>
          <Select.Option value="BugDamage">Bug Damage</Select.Option>
          <Select.Option value="Unknown">Unknown</Select.Option>
        </Select>
        },
        {
          title: 'New Note',
          dataIndex: 'NewNote',
          key: 'NewNote',
          render: (text, _, index) => <input value={text} onBlur={(v) => onChange(v, index)} />,                                 
          shouldCellUpdate: (record, prevRecord) => {
            return record.name !== prevRecord.name;
          }
        },
      ];

      const generatePayload = () => {
        const json = JSON.stringify({
            "DownControllerChanges": newReasonsAndNotes
        });
        return json;
    };

      useEffect(() => {
        (async () => {
          generateReport();
        })()
      }, []);

      const generateReport = async () => {
        let jsonRes = await fetchStatus();
        setPieData(getCountsForPie(jsonRes.data));
        setDataSource(map(jsonRes.data));
      }

      const fetchStatus = async () => {
        setIsLoading(true);
        try {
            const res = await api.getCommsDownReport(token);
            setIsLoading(false);
            return res;
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
      };

      const handleUpdate = async () => {
        setIsLoading(true);
        try {
          const payload = generatePayload();
          const res = await api.updateCommsDownReport(token, payload);
          setPieData(getCountsForPie(res.data));
          setDataSource(map(res.data));
          setIsLoading(false);
          return res;
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
      }

      const map = (data) => {
        return data.map((d) => ({
          'key': d.Id,
          'Id': d.Id,
          'Account': d.Account ? d.Account : '-',
          'Property': d.Property ? d.Property : '-',
          'InstallDate': d.InstallDate ? d.InstallDate.split('T')[0] : null,
          'Warranty': d.Warranty ? d.Warranty : null,
          'Controller': d.Controller ? d.Controller : null,
          'LastComms': d.LastComms ? d.LastComms.split('T')[0] : '-',
          'ControllerType': d.ControllerType ? d.ControllerType : '-',
          'LastNote': d.LastNote ? d.LastNote : '-',
          'Reason': d.CommsDownReason, 
        }));
      }
      
      function mapFilter(column) {
         let distinctValues = [...new Set(dataSource.map(a => a[column]))];
         return distinctValues.map((d) => ({
           'text': d,
           'value': d
         }));
      }

    const printer = require('../../../src/assets/images/printer.png');
    return (
        <div className='container'>
          <Navbar category='tracking'/>
        <div className='title'>
          <h3>Comms Down Report</h3>
        </div>
        <div className='pie'>
         { isLoading == false ? 
        <div style={{ width: '500px', height: '400px', alignContent: 'center' }}>
          <Pie data={pieData} />
        </div> : <div>Loading...</div>
          }
        </div> 
          {isLoading == false &&
            <div className='action'>
              <button onClick={async => handleUpdate()}>
                Save
              </button>
              <CSVLink data={dataSource}
              filename={"comms-down-report.csv"}>
                <button className='button-ws'>
                  <span className='image-container'>
                    <img
                      src={printer}
                      alt="CSV"
                      className="printer-image"
                    ></img>
                  </span>
                  <span className='button-text-ws'>Export Table</span>
                </button>
                </CSVLink>
            </div>
          }
          <Table 
            dataSource={dataSource} 
            columns={columns} 
            loading={isLoading}
            pagination={false}
            size='middle'
          />
        </div>
    );
  }
export default CommsDownReport;