import './InstallationsDrilldownWidget.css';
import '../Common.css';
import '../assetDashboardHomeIcons/AlertCenter.css';
import '../assetDashboardHomeIcons/CarbonOffset.css';

export default function InstallationsDrilldownWidget({ data }) {

  return (
    <span>
      <div className="widget-drilldown-li common-box">
            <div className='common-panel'>
                <div className="title">
                    {new Intl.NumberFormat("us-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                    }).format(data.NumberOfQueuedProperties)} 
                </div>
                <span className='common-panel-text-white'> Number Of Queued Properties</span>
            </div>
            <div className='vl'></div>
            <div className='common-panel'>
                <div className='title'>
                    {new Intl.NumberFormat("us-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                    }).format(data.NumberOfInstallationProperties)}
                </div>
                <span className='common-panel-text-white'> Number Of Installation Properties</span>
            </div>
            <div className='vl'></div>
            <div className='common-panel'>
                <div className='title'>
                    {new Intl.NumberFormat("us-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                    }).format(data.NumberOfActiveProperties)}
                </div>
                <span className='common-panel-text-white'> Number Of Active Properties</span>
            </div>
        </div>
    </span>
  );
}
