
import { useContext, useState } from 'react';
import { useNavigate } from "react-router";
import { Context } from "../../../context/ProposalContext";
import { Context as userContext } from "../../../context/AuthContext";
import api from "../../../api/api";
import QuestionIds from '../../../helpers/questionIds.json';
import '../../Common.css';
import './Verification.css';

const Verification = () => {
    const navigate = useNavigate();

    const { state } = useContext(Context);
    const { state: userState } = useContext(userContext);

    const handleSaveAsDraft = () => {
        navigate('/home');
    };

    const handleBack = () => {
        navigate('/sales/billinput');
    };

    return (
        <>
            <div className='verification-btn-container'>
                <a href={"portal.banyanwater.com/UtilityRate/Edit/"
                        + state.proposalAnswers[QuestionIds.utilityRate]}>
                    <button className='verification-btn banyan-blue-box'>
                        The rate for this city is not consistent with BWC. Click here to update BWC.</button>
                    </a>
            </div>
            <div className='verification-buttons'>
                <button className='proposal-form-btn banyan-blue-box'
                    onClick={handleSaveAsDraft}>Save As Draft</button>
                <button
                    className='proposal-form-btn banyan-blue-box'
                    onClick={handleBack}
                >Go Back</button>
            </div>  
        </>
    );
};

export default Verification;