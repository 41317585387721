import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import resetpassword from '../../api/resetpassword';
import TextInput from '../../components/TextInput';
import Box from '../../components/Box';
import { Context } from '../../context/AuthContext';
import './ResetPassword.css';

function ResetPassword () {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [thankYouMessage, setThankYouMessage] = useState();

    const { state } = useContext(Context);
    const token = state.token;

    const urlEndingResetPassword = "/ForgotPassword";


    const sendPasswordResetEmail = (e) => {
        resetpassword.postData(email, urlEndingResetPassword, token)
            .then((response) => {
                setThankYouMessage(response.data);
            })
            .catch((error) => {
                setThankYouMessage("Something went wrong. Please make sure your email is valid and try again.")
            console.log(error)
            })
        }

    const letterhead = require('../../assets/images/letterhead.png');
    return (
        <div>
            <div className='letterhead-container'>
            <img
                src={letterhead}
                alt="Banyan Water Letterhead with Logo"
                className="letterhead"
            ></img>
            </div>    
            <div className='login-screen-container'>
                <Box>
                    <div className='rp-login-container'>
                        <TextInput 
                            defaultValue={email} 
                            action={setEmail} 
                            placeholder="Email"
                            password={false}
                        />
                        <button className='blue-btn' 
                            onClick={() => sendPasswordResetEmail()}>
                            Reset Password
                        </button>
                    </div>
                </Box>

                <p>{error}</p>
                <div className='rp-thank-you'>
                {thankYouMessage}
                </div>
                
            </div>
        </div>
    );
}

export default ResetPassword;