import React, { useState } from 'react'
import Select from 'react-select'

export default function DropdownEditableCell({
    value, row, column, options, handleUpdateData
}) {
    const [label, setLabel] = useState(value)
    const [isEditing, setIsEditing] = useState(false)

    const onChange = e => {
        setLabel(e.label)
        handleUpdateData(row, column, e.value)
        setIsEditing(false)
    }

    const onBlur = () => setIsEditing(false)

    const getDefaultValue = () => options.filter(opt => opt.label === label)

    const EditableCellSelectCustomStyles = {
        control: base => ({
            ...base,
            minHeight: 'fit-content'
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 0
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 5px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        }),
    };

    const EditableCellSelectCustomTheme = (theme) => ({
        ...theme,
        borderRadius: 2,
        colors: {
            ...theme.colors,
            neutral0: '#EAF5FF',
            neutral20: 'rgba(94, 101, 94, 1)',
            primary: 'rgba(94, 101, 94, 1)',
        }
    })

    return <div className='dropdown-container'>
        {
            isEditing ?
                <Select
                    className='dropdown-select'
                    onChange={onChange}
                    autoFocus
                    onBlur={onBlur}
                    defaultValue={() => getDefaultValue()}
                    options={options}
                    styles={EditableCellSelectCustomStyles}
                    theme={(theme) => EditableCellSelectCustomTheme(theme)}>
                </Select>
                : <div className="clickable-content"
                    onClick={() => setIsEditing(true)}>{label}</div>
        }
    </div>
}
