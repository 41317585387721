import React, { useContext, useEffect, useState } from 'react';
import Navbar from "../../components/Navbar";
import Select from "react-dropdown-select"
import api from '../../api/api';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Context } from '../../context/AuthContext';

const AssemblyTracker = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({})
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const [assemblies, setAssemblies] = useState([]);
    const [selectedAssembly, setSelectedAssembly] = useState('');
    const [selectedAction, setSelectedAction] = useState('');
    const [controllerKey, setControllerKey] = useState('');
    const { state } = useContext(Context);
    const actions = [
    {
        'value': 1,
        'label': 'Assemble'
    },
    {
        'value': 2,
        'label': 'Disassemble'
    }
    ];
    
    useEffect(() => {
    (async () => {
        const jsonRes = await fetchAssemblyTypes();
        setIsLoading(false);
        await setAssemblies(generateSelectAssemblyData(jsonRes.data));
    })()
    }, []);

    const generateSelectAssemblyData = (data) => {
        return data.map((d) => ({
          'value': d.Id,
          'label': d.Name
        }));
      }

    const fetchAssemblyTypes = async () => {
        try {
            const res = await api.getAssemblies(state.token);
            return res;
        } catch (e) {
            console.log(e)
        }
      };

    const handleSave = async (e) => {
    try {
        if (formValidation()) {
            const dataForPost = {
                AssemblyId: selectedAssembly,
                ControllerKey: controllerKey,
                Assemble: selectedAction
            };

            setIsLoading(true);
            const response = await api.updateAssemblies(state.token, dataForPost);
            setIsLoading(false);
            setIsSuccess(true);
        }

    
    } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsFail(true);
    }
    }

    const formValidation = () => {
        setIsFail(false);
        setIsSuccess(false);
        let newErrors = []
        let formIsValid = true;

        if (selectedAssembly === "") {
            newErrors.push('Please select an assembly')
            formIsValid = false;
        }

        if (selectedAction === "") {
            newErrors.push('Please select an action')
            formIsValid = false;
        }

        if (controllerKey.length < 4) {
            newErrors.push('Please enter a valid key')
            formIsValid = false;
        }
    
        setErrors(newErrors);
        
        return formIsValid;
      }
    
    return (
        <div className='container'>
            <Navbar category='inventory'/>
            <h1 className="sunray">Assembly Tracker</h1>
            <div className="content">
                <div className="select-row">
                    <h3>Assembly Type</h3>
                    <Select options={assemblies} onChange={(e) => setSelectedAssembly(e[0].value)}/>
                </div>
                <div className="select-row">
                    <h3>Controller Key</h3>
                    <input placeholder='0' onChange={(e) => setControllerKey(e.target.value)}/>
                </div>
                <div className="select-row">
                    <h3>Action</h3>
                    <Select options={actions} onChange={(e) => setSelectedAction(e[0].value === 1 ? true : false)}/>
                </div>
                <div className='options'>
                    {isLoading === true &&
                    <LoadingSpinner/>
                    }
                    {isLoading === false &&
                    <button 
                    style={{marginTop: 10}}
                    onClick={() => handleSave()}
                    >
                        Submit
                    </button>
                    }
                </div>
                <div>
                    {errors[0] && <p>{errors[0]}</p>}
                    {errors[1] && <p>{errors[1]}</p>}
                    {errors[2] && <p>{errors[2]}</p>}
                    {
                        isSuccess &&
                        <p className='success-message'>Submit Successful</p>
                    }
                    {
                        isFail &&
                        <p className='fail-message'>Submit Unsuccessful</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default AssemblyTracker;