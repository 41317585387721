import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../context/AuthContext';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import api from '../../api/api';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LoadingSpinner from '../LoadingSpinner';

export default function BebbiaTDSBarChart({ date, position, isNumber }) {
    registerLocale('es', es);
    const { state } = useContext(Context);
    const accountId = state.accountId;
    const token = state.token;

    const [selectedDate, setSelectedDate] = useState(new Date(date));
    const [chartDataRaw, setChartDataRaw] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const labels = [['Muy Mala', '(>1000)'], ['Mala', '(900-1000)'], ['Buena', '(500-899)'], ['Muy Buena', '(200-499)'], ['Excelente', '(<200)']];

    useEffect(() => {
        setSelectedDate(new Date(date));
    }, [date])

    useEffect(() => {
        if (chartDataRaw === null) return;
        updateChartData();
    }, [chartDataRaw, isNumber])

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const options = {
                AccountId: accountId,
                RequestedDate: selectedDate
            }
            const res = await api.getBebbiaTDSChartData(token, options)
            const dataRaw = generateChartDataRaw(res.data);
            setChartDataRaw(dataRaw);
            setIsLoading(false);
        })()
    }, [selectedDate])

    const options = {
        responsive: true,
        scales: {
            x: {
                maxTicksLimit: 5,
                grid: { display: false },
            },
            y: {
                display: false
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        let title = context[0].label || '';
                        if (title) {
                            title = title.replace(/,/g, ' ');
                        }
                        return title;
                    }
                }
            },
            datalabels: {
                anchor: 'center',
                align: 'center',
                color: '#5E655E',
                font: {
                    weight: 'bold',
                },
                formatter: function (value) {
                    if (value > 0) {
                        return isNumber ? value : `${(value * 100).toFixed(0)}%`;
                    } else {
                        return '';
                    }
                },
            },
            layout: {
                padding: {
                    left: 10,
                    right: 10
                },
            },
        }
    }

    const updateChartData = () => {
        setChartData({
            labels: labels,
            datasets: [{
                data: isNumber ? chartDataRaw[0] : chartDataRaw[1],
                backgroundColor: [
                    'rgba(228, 179, 99, 0.7)',
                    'rgba(228, 179, 99, 0.5)',
                    'rgba(123, 157, 195, 0.3)',
                    'rgba(123, 157, 195, 0.5)',
                    'rgba(123, 157, 195, 0.7)'
                ],
                borderColor: 'none',
                borderWidth: 0,
            }]
        })
    }

    const generateChartDataRaw = (resData) => {
        const deviceCount = resData.length;
        if (deviceCount === 0) return {};

        const countData = new Array(labels.length).fill(0);
        for (let data of resData) {
            let tdsOut = data.TDSOut;
            if (tdsOut > 1000) {
                countData[0]++;
            } else if (tdsOut >= 900 && tdsOut <= 1000) {
                countData[1]++;
            } else if (tdsOut >= 500 && tdsOut <= 899) {
                countData[2]++;
            } else if (tdsOut >= 200 && tdsOut <= 499) {
                countData[3]++;
            } else if (tdsOut < 200) {
                countData[4]++;
            }
        }
        const percentData = countData.map(d => (d / deviceCount).toFixed(2));
        return [countData, percentData];
    }

    return (
        <div className={`bebbia-operations-${position}-chart`} >
            <div className='bebbia-operations-datepicker-wrapper'>
                <DatePicker
                    maxDate={new Date()}
                    className="bebbia-operations-datepicker"
                    selected={selectedDate}
                    placeholderText='Seleccione una fecha...'
                    onChange={(date) => {
                        setSelectedDate(new Date(date))
                    }}
                    locale="es"
                    dateFormat="dd/MM/yyyy" />
            </div>
            {isLoading
                ? <LoadingSpinner />
                : (chartData && <Bar
                    data={chartData}
                    options={options}
                    plugins={[ChartDataLabels]}
                ></Bar>)
            }
        </div >
    )
}
