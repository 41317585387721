import Select from 'react-select';
import { ReactSelectCustomTheme, ReactSelectCustomStyles } 
from '../../../constants/selectFomFieldStyle';


export default function SpecialChoiceFormField({ id, question, parentValue,
    options, getDefaultValue, handleSelect, hasError }) {
    return (
        <div className='proposal-form-field-wrapper' key={`q-${id}`}>
            {!!parentValue ? <div style={{ textAlign: 'right' }}>Cannot find utility rate? Click
                <a href={`${process.env.REACT_APP_PLATFORM_BASE_URL}/UtilityRate/CreateBeneath?p=${parentValue || 0}`} target="blank"> here </a>
                to create one</div> : null}
            <div className='proposal-form-row'>
                <label >{question}</label>
                <div className='proposal-form-select-wrapper'>
                    <Select name={id}
                        defaultValue={() => getDefaultValue(options, id)}
                        isDisabled={!parentValue}
                        options={options}
                        onChange={(e) => handleSelect(e, id, false)}
                        placeholder='Select...'
                        theme={(theme) => ReactSelectCustomTheme(theme)}
                        styles={ReactSelectCustomStyles}
                    />
                </div>
            </div>
            {hasError && <div className='proposal-form-error'>This field has invalid input.</div>}
        </div>
    );
}
