import { Badge, Flex, Tag, Divider } from 'antd';

const StatusCounts = (data) => {

    const groupedCounts = (statuses) => {
        const statusCounts = statuses.data.reduce((acc, item) => {
            const status = item.InstallStatus;
            acc[status] = (acc[status] || 0) + 1;
                return acc;
          }, {});

        const orderStatusCounts = Object.keys(statusCounts)
            .map(k => [k, statusCounts[k]])
            .sort((a, b) => b[1] - a[1]);

        return orderStatusCounts;
    }

    return (
        <div>
            <Divider/>
            <Flex gap='16px' justify='center' align='center'>
            {
              groupedCounts(data).map((value) =>
                <>
                    <Badge count={value[1]}>
                        <Tag style={{ fontSize: '10px' }} color={`rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`}>{value[0]}</Tag>
                    </Badge>
                </>
              )
            }
            </Flex>
            <Divider/>
        </div>
    );
  }

export default StatusCounts;