import './Landing';
import '../../components/Common.css'
import './InstallationsDrilldown.css';
import Dropdown from "../../components/Dropdown";
import React from 'react';
import InstallationsDrilldownWidget from '../../components/installations/InstallationsDrilldownWidget';
import ComponentWithData from '../../components/ComponentWithData';

const InstallationsDrilldown = () => {


    return (
          <div>
            <div className='landing-screen-container-installations'>
                <br></br>
                <ComponentWithData child={InstallationsDrilldownWidget} 
                  controller="/PropertyStatuses/" url="RollupNumbersForPropertyStatuses" />
                <Dropdown/>
             </div>
        </div>
    )
}

export default InstallationsDrilldown;