
import '../screens/salesAnalysis/BillProposal.css'
import TextInput from './TextInput'

export default function InnerCellProposal({title, defaultValue, action}){

 return( 
    <tr>
        <td className='proposal-td-title'>{title}</td>
        <td className='proposal-td'><div className='widget-white-sm'>
            <TextInput 
                defaultValue={defaultValue} 
                action={action}
                placeholder="Enter a value..."
                password={false}
            /></div>
        </td>
    </tr>
 )
}