
import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/Account',
});

export default {
    postData: (email, urlEnding, token) =>
    instance({
        'method': 'POST',
        'headers': {
            'Authorization': 'Bearer ' + token,
        },
        'url':urlEnding,
        'data': {
            'email':email
        }
    }),
    
}