import React, { useState, useContext, useEffect } from 'react'
import BebbiaDateDropdown from './BebbiaDateDropdown'
import { Context } from '../../context/AuthContext';
import api from '../../api/api';
import Table from '../Table';
import LoadingSpinner from '../LoadingSpinner';
import moment from 'moment';
import NumberFormatTwoDecimalPlaces from '../NumberFormatTwoDecimalPlaces';

export default function BebbiaPurificationTable() {
    const { state } = useContext(Context);
    const token = state.token;

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [selectedDateOption, setSelectedDateOption] = useState(null);
    const [customDates, setCustomDates] = useState({ startDate: null, endDate: null });

    useEffect(() => {
        (async () => {
            if (selectedDateOption === null) return;
            if (selectedDateOption === 2
                && (customDates.startDate === null
                    || customDates.endDate === null)) return;
            setIsLoading(true);
            const options = {
                dateRangeOption: selectedDateOption,
                startDate: customDates.startDate,
                endDate: customDates.endDate,
            };
            var res = await api.getBebbiaPurificationTableData(token, options);
            setTableData(generateTableData(res.data));
        })();
    }, [selectedDateOption, customDates]);

    useEffect(() => {
        if (!tableData.length) return;
        setTableColumns([
            {
                Header: 'Purifier ID',
                accessor: 'Purifier ID', // accessor is the "key" in the data
            },
            {
                Header: 'Installation Date',
                accessor: 'Installation Date',
            },
            {
                Header: 'Location/Zip Code',
                accessor: 'Location/Zip Code',
            },
            {
                Header: 'Colonia',
                accessor: 'Colonia',
            },
            {
                Header: 'Ciudad',
                accessor: 'Ciudad',
            },
            {
                Header: 'Estado',
                accessor: 'Estado',
            },
            {
                Header: 'Last day of primary cartridge change (in days)',
                accessor: 'Last day of primary cartridge change (in days)',
            },
            {
                Header: 'Last day of membrane cartridge change (in days)',
                accessor: 'Last day of membrane cartridge change (in days)',
            },
            {
                Header: 'Fecha lectura del purificador',
                accessor: 'Fecha lectura del purificador',
            },
            {
                Header: 'TDS (i) MIN to date',
                accessor: 'TDS (i) MIN to date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'TDS (o) MAX to date',
                accessor: 'TDS (o) MAX to date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'MIN Delta TDS since installation/maintenance date',
                accessor: 'MIN Delta TDS since installation/maintenance date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'MAX Delta TDS since installation/maintenance date',
                accessor: 'MAX Delta TDS since installation/maintenance date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Flow rate MIN to date',
                accessor: 'Flow rate MIN to date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Flow rate MAX to date',
                accessor: 'Flow rate MAX to date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Water consumption MAX since installation',
                accessor: 'Water consumption MAX since installation',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Water consumption MIN since installation',
                accessor: 'Water consumption MIN since installation',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Accummulated water consumption since installation date',
                accessor: 'Accummulated water consumption since installation date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: 'Accummulated water consumption since last maintenance date',
                accessor: 'Accummulated water consumption since last maintenance date',
                Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
            },
            {
                Header: '# of days with zero water consumption since installation date',
                accessor: '# of days with zero water consumption since installation date',
            },
        ]);
        setIsLoading(false);
    }, [tableData])

    const handleSelectDateOption = async (e) => {
        setSelectedDateOption(e.value);
    }

    const handleCustomDates = async (e) => {
        const startDate = e[0];
        const endDate = e[1];
        setCustomDates({ startDate, endDate });
    }

    const generateTableData = (data) => {
        return data.map((d) => ({
            'Purifier ID': d.DeviceId,
            'Installation Date': moment.utc(d.InstallationDate).format("DD/MM/YYYY"),
            'Location/Zip Code': d.ZipCode,
            'Colonia': d.Colony,
            'Ciudad': d.City,
            'Estado': d.State,
            'Last day of primary cartridge change (in days)': moment.utc(d.LastFilterChangePrimary).format("DD/MM/YYYY"),
            'Last day of membrane cartridge change (in days)': moment.utc(d.LastFilterChangeMembrane).format("DD/MM/YYYY"),
            'Fecha lectura del purificador': moment.utc(d.LastDataUpdate).format("DD/MM/YYYY"),
            'TDS (i) MIN to date': d.MinTDSi,
            'TDS (o) MAX to date': d.MaxTDSo,
            'MIN Delta TDS since installation/maintenance date': d.MinDeltaTDS,
            'MAX Delta TDS since installation/maintenance date': d.MaxDeltaTDS,
            'Flow rate MIN to date': d.MinFlowRate,
            'Flow rate MAX to date': d.MaxFlowRate,
            'Water consumption MAX since installation': d.MaxWaterConsumption,
            'Water consumption MIN since installation': d.MinWaterConsumption,
            'Accummulated water consumption since installation date': d.WaterConsumptionSinceInstallation,
            'Accummulated water consumption since last maintenance date': d.WaterConsumptionSinceMaintenance,
            '# of days with zero water consumption since installation date': d.ZeroConsumptionDays,
        }))
    }
    return (<>
        <div className='bebbia-purification-title-container'>
            <div className='bebbia-purification-graph-title'>Tabla de Información de Purificación</div>
            <div className='bebbia-single-dropdown-wrapper'>
                <BebbiaDateDropdown
                    className="bebbia-date-dropdown"
                    handleDateOption={handleSelectDateOption}
                    handleCustomDates={handleCustomDates} />
            </div>
        </div>
        <div className="bebbia-purification-exportable-table-container">
            {isLoading
                ? <LoadingSpinner />
                : tableData.length > 0
                && <Table
                    className="bebbia-purification-exportable-table-container"
                    columns={tableColumns}
                    defaultData={tableData}
                    scrollXMax={'100%'}
                    fileName={`${moment.utc().format("DDMMYYYY")} - Tabla de Información de Purificación`}
                    isES={true} />
            }
        </div>
    </>
    )
}
