import React, { useState, useEffect, useMemo } from 'react'
import Select from 'react-select'
import './AddNewUserForm.css'

export default function AddNewUserForm({ userRoleOptions, propertyOptions, addNewUser }) {

  const initFormValues = useMemo(() => ({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    userRole: '',
    property: '',
  }), [])

  const [formData, setFormData] = useState(initFormValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      let res = addNewUser(formData)
      if (res.success) {
        setFormData(initFormValues)
      } else {
        setIsSubmitting(false)
      }
    }
  }, [formErrors, initFormValues, addNewUser, formData, isSubmitting])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((data) => ({ ...data, [name]: value }))
  }

  const handleSelect = (e, name) => {
    setFormData((data) => ({ ...data, [name]: e.value }))
  }

  const validate = (values) => {
    const errors = {}
    const emailRegex = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/i
    const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i
    if (!values.firstName) {
      errors.firstName = 'First name is required'
    }

    if (!values.lastName) {
      errors.lastName = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    if (values.phoneNumber && !phoneRegex.test(values.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number'
    }

    if (!values.userRole) {
      errors.userRole = 'Please select a user role'
    }

    if (!values.property) {
      errors.property = 'Please assign user to a property'
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setFormErrors(validate(formData))
  }


  const ReactSelectCustomStyles = {
    control: base => ({
      ...base,
      minWidth: '304px',
      minHeight: '30px',
      borderRadius: '2px',
      border: 'none'
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0 5px',
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 5px',
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? null : '#5E655E'
    }),
  };

  const ReactSelectCustomTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: '#EAF5FF',
      neutral20: 'rgba(94, 101, 94, 1)',
      primary: 'rgba(94, 101, 94, 1)',
    }
  })

  return (
    <form onSubmit={handleSubmit} className='add-new-user-form'>
      <div className='add-new-user-input-group'>
        <label className='add-new-user-form-label' htmlFor='firstName'>First Name: </label>
        <input
          name='firstName'
          id='firstName'
          value={formData.firstName}
          onChange={handleChange}
          autoFocus
          autoComplete='off'
        />
      </div>
      <p className='error-msg'>{formErrors.firstName}</p>

      <div className='add-new-user-input-group'>
        <label htmlFor='lastName'>Last Name: </label>
        <input
          name='lastName'
          id='lastName'
          value={formData.lastName}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.lastName}</p>

      <div className='add-new-user-input-group'>
        <label htmlFor='email'>Email: </label>
        <input name='email'
          id='email'
          value={formData.email}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.email}</p>

      <div className='add-new-user-input-group'>
        <label htmlFor='phoneNumber'>Phone Number: </label>
        <input
          name='phoneNumber'
          id='phoneNumber'
          value={formData.phoneNumber}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.phoneNumber}</p>

      <div className='add-new-user-input-group'>
        <label htmlFor='userRole'>User Role: </label>
        <Select
          name='userRole'
          id='userRole'
          onChange={(e) => handleSelect(e, 'userRole')}
          menuPlacement='auto'
          defaultValue={formData.userRole}
          options={userRoleOptions}
          styles={ReactSelectCustomStyles}
          theme={(theme) => ReactSelectCustomTheme(theme)}>
        </Select>
      </div>
      <p className='error-msg'>{formErrors.userRole}</p>

      <div className='add-new-user-input-group'>
        <label htmlFor='property'>Property: </label>
        <Select
          name='property'
          onChange={(e) => handleSelect(e, 'property')}
          menuPlacement='auto'
          defaultValue={formData.property}
          options={propertyOptions}
          styles={ReactSelectCustomStyles}
          theme={(theme) => ReactSelectCustomTheme(theme)}>
        </Select>
      </div>
      <p className='error-msg'>{formErrors.property}</p>

      <div className='add-new-user-form-btn-wrapper'>
        <button
          type='submit'
          className='rr-btn add-new-user-submit'
          onClick={handleSubmit}>Submit</button>
      </div>

    </form>

  )
}
