import '../assetDashboardHomeIcons/AlertCenter.css'
import '../Common.css'
import Tree from './Tree';
import './VirtualForest.css'

export default function VirtualForest({ data }) {

const numberOfTrees =  Math.round(data.TreesPlanted / 100);
var rows = [];

for (var i = 0; i < numberOfTrees; i++) {
    rows.push(<Tree key={i} />);
}

  return (
      <div className="widget-virtual-forest vf-box">
        <div className='vf-header'>Virtual Forest</div>
        <br></br>
        <div className='vf-subheader'>Every drop saved counts!</div>
        <div className='display-trees'>
        {rows}
        </div>
          </div>
      
  );
}
