import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './Common.css'
import './SalesHome.css'
import api from '../../api/api'
import { Context as userContext } from "../../context/AuthContext";
import { Context } from "../../context/ProposalContext";
import QuestionIds from '../../helpers/questionIds.json';

export default function SalesHome() {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [draftProposals, setDraftProposals] = useState([]);
    const [completeProposals, setCompleteProposals] = useState([]);
    const [approvedProposals, setApprovedProposals] = useState([]);
    const { clearProposal, updateProposalAnswers, updateProposalId } = useContext(Context);
    const { state, setPropertyName } = useContext(userContext);
    const token = state.token;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        (async () => {
          clearProposal();
          await fetchData();
        })();
      }, []);
    
      const fetchData = () => {
        var draftResponses = [];
        var completeResponses = [];
        var approvedResponses = [];
        api.getGenericSalesAnalysis(token, '/GetAllProposals')
            .then((response)=>{
                response.data.forEach(element => {
                    if (element.Status==="draft"){
                        draftResponses.push(element); 
                    }
                    else if (element.Status==="complete"){
                        completeResponses.push(element);
                    } 
                    else if (element.Status==="approved"){
                        approvedResponses.push(element);
                    } 
                });
                setDraftProposals(draftResponses);
                setCompleteProposals(completeResponses);
                setApprovedProposals(approvedResponses);
            })
            .catch((error) => {
                console.log(error)
            })  
        };

    const chooseProposal = async (proposal, path) => {
        const id = proposal.Id;
        const response = await api.getProposalDraft(state.token, id);

        let formResponses = {};
        response.data.Responses.forEach((element, index) => {
            if (index + 1 === QuestionIds.plantType) {
                formResponses[index + 1] = JSON.parse(element.ResponseValue);
            } else {
                formResponses[index + 1] = element.ResponseValue;
            }
        });

        updateProposalId(id);
        setPropertyName(formResponses[1].toString());
        updateProposalAnswers(formResponses);

        navigate("/sales/" + path, {state:{Id:id, Status:proposal.Status}});
    };

    return (
        <div className="sales-home-container">
            <h2 className="sales-title">Sales Analysis</h2>
            <section className="sales-tiles-container sales-tile-spacing">
                <div
                    className="clickable-tile new-proposal-tile"
                    onClick={() => navigate("/sales/proposal")}>
                    <span>+ Irrigation Insight Proposal</span>
                </div>
                <div
                    className="clickable-tile new-proposal-tile"
                    onClick={() => navigate("/sales/indoor-proposal")}>
                    <span>+ Indoor Insight Proposal</span>
                </div>
                <div
                    className="clickable-tile new-proposal-tile"
                    onClick={() => navigate("/sales/bulkcreation")}>
                    <span>+ Bulk Creation</span>
                </div>
            </section>
            <h2 className="sales-title">Completed Proposals for Review</h2>
            <section className="sales-tiles-container">
                {[...completeProposals].map((e) => (<div className="clickable-tile" 
                onClick={() => chooseProposal(e, 'billproposal')}key={e.Id}>
                    <span> {e.PropertyName}</span>
                </div>))}
            </section>
            <h2 className="sales-title">Proposal Drafts</h2>
            <section className="sales-tiles-container">
                {[...draftProposals].map((e) => (<div className="clickable-tile" 
                onClick={() => chooseProposal(e, 'proposal')}key={e.Id}>
                    <span>Draft {e.PropertyName}</span>
                </div>))}
            </section>
            <h2 className="sales-title">Approved Proposals</h2>
            <section className="sales-tiles-container">
                {[...approvedProposals].map((e) => (<div className="clickable-tile" 
                onClick={() => chooseProposal(e, 'billproposal')}key={e.Id}>
                    <span> {e.PropertyName} </span>
                </div>))}
            </section>
            <h2 className="sales-title">Archive</h2>
            <section className="sales-tiles-container">
                <div className="clickable-tile"
                    onClick={() => navigate("/sales/archive", {state:{status:"won"}})}>
                    <span>Won Proposals</span>
                </div>
                <div className="clickable-tile"
                    onClick={() => navigate("/sales/archive", {state:{status:"lost"}})}>
                    <span>Lost Proposals</span>
                </div>
                <div className="clickable-tile"
                    onClick={() => navigate("/sales/archive", {state:{status:"disqualified"}})}>
                    <span>Disqualified Proposals</span>
                </div>
                <div className="clickable-tile"
                    onClick={() => navigate("/sales/archive", {state:{status:"stale"}})}>
                    <span>Stale Proposals</span>
                </div>
            </section>
        </div>
    )
}
