import React, { useState, useEffect } from 'react';
import BebbiaDeviceDropdown from './BebbiaDeviceDropdown';
import BebbiaDateDropdown from './BebbiaDateDropdown';

const BebbiaDropdowns = ({ handleUpdateOptions, deviceOptions }) => {
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedDateOption, setSelectedDateOption] = useState(null);
    const [customDates, setCustomDates] = useState({ startDate: null, endDate: null });

    useEffect(() => {
        if (selectedDevice != null && selectedDateOption !== null) {
            if (selectedDateOption === 2
                && (customDates.startDate === null
                    || customDates.endDate === null)) return;
            const dropdownOptions = {
                readerKey: selectedDevice,
                dateRangeOption: selectedDateOption,
                startDate: customDates.startDate,
                endDate: customDates.endDate
            };
            (async () => {
                await handleUpdateOptions(dropdownOptions);
            })();
        }
    }, [selectedDevice, selectedDateOption, customDates]);

    const handleSelectDevice = async (e) => {
        setSelectedDevice(e.value);
    }

    const handleSelectDateOption = async (e) => {
        setSelectedDateOption(e.value);
    }

    const handleCustomDates = async (e) => {
        const startDate = e[0];
        const endDate = e[1];
        setCustomDates({ startDate, endDate });
    }

    return <div className="bebbia-dropdown-wrapper">
                <BebbiaDeviceDropdown options={deviceOptions} handleSelect={handleSelectDevice} />
                <BebbiaDateDropdown
                    className="bebbia-date-dropdown"
                    handleDateOption={handleSelectDateOption}
                    handleCustomDates={handleCustomDates}
                />
           </div>
}

export default BebbiaDropdowns;