

export default function TextFormField({ id, question, type, value, changeValue }) {
    return (
        <div className='proposal-form-row'>
            <label htmlFor={id}>{question}</label>
            <input className='proposal-form-input'
                type={type}
                value={value}
                onChange={(e) => changeValue(e)}
                name={id}
                id={`q-${id}`}
                autoComplete='off' />

        </div>
    );
}