import React, { useContext, useState } from 'react';
import api from '../../api/api';
import { Context } from '../../context/AuthContext';
import './OrderImport.css'
import LoadingSpinner from '../../components/LoadingSpinner';

const OrderImport = ({ onCancel }) => {
    const [files, setFiles] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const [serverMessage, setServerMessage] = useState('');
    const { state } = useContext(Context);
    const token = state.token;

    const handleFileEvent = (e) => {
        setFiles(e.target.files);
      };

    const handleImport = async (e) => {
        try {
            setIsFail(false);
            setIsSuccess(false);
            setIsLoading(true);

            e.preventDefault();
            const formData = new FormData();
            for (let i = 0 ; i < files.length ; i++) {
                formData.append('files', files[i]);
            }

            await api.orderImport(token, formData)
            .then(res => {
                console.log(res);
                setServerMessage(res.data);
                setIsLoading(false);
                setIsSuccess(true);
            });
        }
        catch(error) {
            setIsLoading(false);
            setIsFail(true);
        }
    }

    return (
        <div>
            <div className='section'>
                <input
                type={"file"}
                id={"fileUpload"}
                multiple={false}
                accept={".xlsx"}
                onChange={handleFileEvent}
                />
            </div>
            <div className='section'>
            {
                isSuccess ? (
                    <div>
                        <p className='success-message'>Submit Successful</p>
                        <p>{ serverMessage.SuccessfulOrderImportCount } Orders Imported</p>
                        <button onClick={onCancel}>
                            Done
                        </button> 
                    </div>
                ) : (
                    <div>
                        <button onClick={handleImport}>
                            Submit
                        </button> 
                    </div>
                )
            }
            {
                isFail &&
                <p className='fail-message'>Submit Unsuccessful</p>
            }
            {isLoading === true &&
                <LoadingSpinner/>
            }
            </div>
        </div>
    )
};

export default OrderImport;