import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { Context } from "../../context/AuthContext";

const Bebbia = () => {
    const letterhead = require('../../../src/assets/images/letterhead.png');
    const { signout } = useContext(Context);
    let navigate = useNavigate();

    const handleLogout = () => {
        window.localStorage.removeItem("rememberMe");
        localStorage.clear();
        signout();
        navigate('');
    };

    return (
        <>
            <div className='letterhead-container'>
                <img
                    src={letterhead}
                    alt="Banyan Water Letterhead with Logo"
                    className="letterhead"
                ></img>
            </div>
            <div className='bebbia-links-container'>
                <div className='bebbia-links'>
                    <div className='clickable-tile bebbia-tile' onClick={() => { navigate('/bebbia/purification') }}>Purificación</div>
                    <div className='clickable-tile bebbia-tile' onClick={() => { navigate('/bebbia/operations') }}>Operaciones</div>
                    <div className='clickable-tile bebbia-tile' onClick={() => { navigate('/bebbia/marketing') }}>Marketing</div>
                </div>
            </div>
            <button onClick={handleLogout}>Logout</button>
        </>
    );
};

export default Bebbia;