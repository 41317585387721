import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function BebbiaTDSAverageGraph({ graphData }) {
  const graphLabels = {
    tdsi: "TDSi",
    tdso: "TDSo",
    delta: "Delta"
  };

  const tdsIns = graphData.map(d => d.WaterQuality.TDSIn);
  const tdsOuts = graphData.map(d => d.WaterQuality.TDSOut);
  const deltas = graphData.map(d => d.WaterQuality.Delta);
  const labels = graphData.map(d => moment(d.Date).format("DD-MMM-YY"));

  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: graphLabels.tdsi,
        borderColor: '#7B9DC3',
        backgroundColor: 'rgba(123, 157, 195, 0.5)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(123, 157, 195, 0.5)',
        fill: false,
        data: tdsIns
      },
      {
        type: 'line',
        label: graphLabels.tdso,
        borderColor: '#E4B363',
        backgroundColor: 'rgba(228, 179, 99, 0.5)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(228, 179, 99, 0.5)',
        fill: false,
        data: tdsOuts
      },
      {
        type: 'line',
        fill: true,
        label: graphLabels.delta,
        borderWidth: 1,
        borderColor: 'rgba(94, 101, 94, 0.2)',
        pointRadius: 2,
        pointBackgroundColor: 'rgba(94, 101, 94, 0.3)',
        backgroundColor: 'rgba(94, 101, 94, 0.2)',
        data: deltas
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'TDSi y TDSo',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            var label = tooltipItem.formattedValue || "";
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false }
      },
      y: {
        grid: { display: false }
      }
    }
  };

  return (
    graphData.length > 0 ?
      <div className='bebbia-chart'>
        <Chart options={options} data={data} />
      </div>
      : <div className='bebbia-no-data'>Sin datos</div>
  )
}
