import api from '../api/api'

export default class FetchInventoryHelper {
    constructor(api_token) {
        this.token = api_token;
    }

    fetchProperties = async () => {
        try {
            const res = await api.getProperties(this.token);
            return res.data.map((d) => ({
                'value': d.Id,
                'label': d.Name
              }));
        } catch (e) {
            console.log(e)
        }
      };
    
    fetchControllersOrSources = async (controllerOrSource, selectedProperty) => {
        try {
            let data = [];
    
            if(controllerOrSource === 'Controller') {
                let res = await api.getPropertyControllers(this.token, selectedProperty);
                data = res.data;
            } else if (controllerOrSource === 'WaterSource') {
                let res = (await api.getPropertyWaterSources(this.token, selectedProperty));
                data = res.data;
            }
    
            return data.map((d) => ( (d.ControllerKey) ? {
                'value': d.Id,
                'label': `${d.Name} (${d.ControllerKey})`
              } : {
                'value': d.Id,
                'label': `${d.Name}`
              }));
    
        } catch (e) {
            console.log(e)
        }
    };

    fetchComponents = async (controllerOrSources, selectedControllersOrSources) => {
        try {
            let data = [];

            if(controllerOrSources === 'Controller') {
                let res = await api.getComponentsByController(this.token, selectedControllersOrSources);
                data = res.data;
            } else if (controllerOrSources === 'Water Source') {
                let res = (await api.getComponentsByWaterSource(this.token, selectedControllersOrSources));
                data = res.data;
            }

            return data.map((d) => ({
                'value': d.Id,
                'label': `${d.ComponentName}`
              }));
        } catch (e) {
            console.log(e)
        }
    };

    fetchLimbo = async () => {
        try {
            let res = await api.getInventoryInLimbo(this.token);

            return res.data.map((d) => ( (d.Serial) ? {
                'value': d.Id,
                'label': `${d.Id}: ${d.ComponentName} (${d.Serial})`
              } : {
                'value': d.Id,
                'label': `${d.Id}: ${d.ComponentName}`
              }));
        } catch (e) {
            console.log(e)
        }
    };
}


