import React, {useContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import api from '../../api/api';
import { Context } from "../../context/AuthContext";
import './RepairPhotos.css'
import LoadingSpinner from '../../components/LoadingSpinner';

export default function RepairPhotos() {
    const location = useLocation();
    const [photos, setPhotos] = useState([]);
    const { state } = useContext(Context);
    const token = state.token;
    const urlEnding = 'PropertyRepairsPhotos';
    const photoURLPrefix = 'https://bwdata.blob.core.windows.net';
    const [isLoaded, setIsLoaded] = useState(false);

useEffect(() => {
    (async () => {
      setIsLoaded(false);
      await fetchData();
    })();
  }, []);

  const fetchData = () => {
    api.getPhotos(location.state.propertyName, urlEnding, token)
        .then((response)=>{
            setPhotos(response.data);
            setIsLoaded(true);
        })
        .catch((error) => {
            console.log(error)
        })  
  }

  const photosExist = photos.length > 0;
  const photoBlock = photosExist ? 
        <section className="photo-container"> 
        {[...photos].map((e) => <div>
          <div> <img src={photoURLPrefix + e.PhotoUrl}/></div>
          <div> {e.ZoneDescription}</div></div>)}
        </section> 
        : <div>No Audit Photos For Property.</div>;

return ( isLoaded ? 
    <div className="photo-home-container">
    <h2 className="sales-title">{location.state.propertyName} Photos</h2>
    {photoBlock}
    </div>
    : <LoadingSpinner />);
}
