
import '../Common.css'
import React from 'react';
import Table from '../Table';
import './WaterSavingsTable.css'
import NumberFormat from '../NumberFormat';
import CurrencyFormat from '../CurrencyFormat';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/AuthContext';
import { useContext } from 'react';

const PaybackTable = ({ url }) => {
  const navigate = useNavigate();

  const { setPropertyId, setPropertyName } = useContext(Context); 
  const to = 'propertywatersavings';

  const callback = (value, index, data) => {
    const property = value.original;
    setPropertyName(property.PropertyName);
    setPropertyId(property.PropertyId);
    navigate(`/${to}`);
  };

 const tableSortees = [{
    id: "PropertyName",
    desc: false
  }];

  const tableColumns = [
      {
        Header: 'Property',
        accessor: 'PropertyName',
        Cell: ({ row: { index }, value }) => 
        <div>{value}</div>  // accessor is the "key" in the data
      },
      {
        Header: 'Install Date',
        accessor: 'InstallDate',
        Cell: ({ cell: { value } }) => {
          return (
            <div>{value.split('T')[0]}</div>
          );
        }
      },
      {
        Header: 'Program Length',
        accessor: 'ProgramLength',
        Cell: ({ cell: { value } }) => {
          return (
            <div>{value}</div>
          );
        }
      },
      {
        Header: 'Installation Cost',
        accessor: 'CapEx',
        Cell: ({ cell: { value } }) => {
          return (
            <>
             <CurrencyFormat data={value}></CurrencyFormat> 
            </>
          );
        }
      },
      {
        Header: 'Gallons Saved',
        accessor: 'WaterSavings',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
      {
        Header: 'Dollars Saved',
        accessor: 'CostSavings',
        Cell: ({ cell: { value } }) => {
          return (
            <>
             <CurrencyFormat data={value}></CurrencyFormat> 
            </>
          );
        }
      },
      {
        Header: 'OpEx Costs',
        accessor: 'OpEx',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <CurrencyFormat data={value}></CurrencyFormat>
            </>
          );
        }
      },
      {
        Header: 'Leak Cost Avoidance',
        accessor: 'LeakCostAvoidance',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <CurrencyFormat data={value}></CurrencyFormat>
            </>
          );
        }
      },
      {
        Header: 'Net Savings',
        accessor: 'NetSavings',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <CurrencyFormat data={value}></CurrencyFormat>
            </>
          );
        }
      },
    ];
    
  return (
        <div className='table-container-wst'>
          <Table url={url}
            title='Contract to Date Water Savings'
            columns={tableColumns}
            sortees={tableSortees}
            callback={callback} />
        </div>
  );
}

export default PaybackTable;