
import '../Common.css'
import React from 'react';
import Table from '../Table';
import './WaterSavingsTable.css'
import NumberFormat from '../NumberFormat';
import CurrencyFormat from '../CurrencyFormat';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/AuthContext';
import { useContext } from 'react';

const WaterSavingsTable = ({ url }) => {
  const navigate = useNavigate();

  const { setPropertyId, setPropertyName } = useContext(Context); 
  const to = 'propertywatersavings';

  const callback = (value, index, data) => {
    const property = value.original;
    setPropertyName(property.PropertyName);
    setPropertyId(property.PropertyId);
    navigate(`/${to}`);
  };

 const tableSortees = [{
    id: "PropertyName",
    desc: false
  }];

  const tableColumns = [
      {
        Header: 'Property',
        accessor: 'PropertyName',
        Cell: ({ row: { index }, value }) => 
        <div>{value}</div>  // accessor is the "key" in the data
      },
      {
        Header: 'Baseline Consumption (gallons)',
        accessor: 'BaselineUsage',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
      {
        Header: 'Baseline Cost',
        accessor: 'BaselineCost',
        Cell: ({ cell: { value } }) => {
          return (
            <>
             <CurrencyFormat data={value}></CurrencyFormat> 
            </>
          );
        }
      },
      {
        Header: 'Cumulative Consumption (gallons)',
        accessor: 'Usage',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
      {
        Header: 'Cumulative Cost',
        accessor: 'Cost',
        Cell: ({ cell: { value } }) => {
          return (
            <>
             <CurrencyFormat data={value}></CurrencyFormat> 
            </>
          );
        }
      },
      {
        Header: 'Water Savings',
        accessor: 'Performance',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>%
            </>
          );
        }
      },
    ];
    
  return (
        <div className='table-container-wst'>
          <Table url={url} 
            title='Portfolio Water Savings'
            columns={tableColumns} 
            sortees={tableSortees} 
            callback={callback} />
        </div>
  );
}


export default WaterSavingsTable;