import './Landing';
import '../../components/Common.css'
import React from 'react';
import NotFullyOperationalTable from '../../components/alertCenter/NotFullyOperationalTable';
import ScheduleACallWidget from '../../components/ScheduleACallWidget';
import './AlertCenter.css'
import PayingHighestWaterRatesTable from '../../components/alertCenter/PayingHighestWaterRatesTable';
import OfflineDevicesTable from '../../components/alertCenter/OfflineDevicesTable';
import ReactGA from 'react-ga';
import { useEffect } from 'react';


const AlertCenter = () => {

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    return (
        <div>
            <div className='landing-screen-container-ac'>
                <div className='tables-column'>
                    <OfflineDevicesTable />
                    <NotFullyOperationalTable />
                    <PayingHighestWaterRatesTable />
                </div>
                <div className='schedule-a-call-box'>
                    <ScheduleACallWidget />
                </div>
            </div>
        </div>
    );
}


export default AlertCenter;