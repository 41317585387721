import React, { useState, useEffect, useMemo } from 'react'
import './AddNewBillForm.css'

export default function AddNewBillForm({ addBillInfo }) {

  const initFormValues = useMemo(() => ({
    MeterNumber: '',
    Start: '',
    End: '',
    UsageVolume: '',
    TotalCost: '',
    SewerAmount: '',
    OtherAmount: '',
  }), [])

  const [formData, setFormData] = useState(initFormValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      let res = addBillInfo(formData)
      if (res.success) {
        setFormData(initFormValues)
      } else {
        setIsSubmitting(false)
      }
    }
  }, [formErrors, initFormValues, addBillInfo, formData, isSubmitting])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((data) => ({ ...data, [name]: value }))
  }

  const validate = (values) => {
    const errors = {}

    if (!values.MeterNumber) {
      errors.MeterNumber = 'Meter Number is required'
    }

    if (!values.Start) {
      errors.Start = 'Start Date is required'
    }

    if (!values.End) {
        errors.End = 'End Date is required'
      }

    if (!values.UsageVolume) {
      errors.UsageVolume = 'Usage Volume is required'
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setFormErrors(validate(formData))
  }


  const ReactSelectCustomStyles = {
    control: base => ({
      ...base,
      minWidth: '304px',
      minHeight: '30px',
      borderRadius: '2px',
      border: 'none'
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0 5px',
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 5px',
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? null : '#5E655E'
    }),
  };

  const ReactSelectCustomTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: '#EAF5FF',
      neutral20: 'rgba(94, 101, 94, 1)',
      primary: 'rgba(94, 101, 94, 1)',
    }
  })

  return (
    <form onSubmit={handleSubmit} className='add-bill-user-form'>
      <div className='add-bill-user-input-group'>
        <label className='add-bill-user-form-label' htmlFor='meter-number'>Meter Number: </label>
        <input
          name='MeterNumber'
          id='MeterNumber'
          value={formData.MeterNumber}
          onChange={handleChange}
          autoFocus
          autoComplete='off'
        />
      </div>
      <p className='error-msg'>{formErrors.MeterNumber}</p>

      <div className='add-new-bill-input-group'>
        <label htmlFor='Start'>Start Date: </label>
        <input
          name='Start'
          id='Start'
          value={formData.Start}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.Start}</p>

      <div className='add-new-bill-input-group'>
        <label htmlFor='End'>End Date: </label>
        <input name='End'
          id='End'
          value={formData.End}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.End}</p>

      <div className='add-new-bill-input-group'>
        <label htmlFor='UsageVolume'>Usage Volume: </label>
        <input
          name='UsageVolume'
          id='UsageVolume'
          value={formData.UsageVolume}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.UsageVolume}</p>

      <div className='add-new-bill-input-group'>
        <label htmlFor='TotalCost'>Total Cost: </label>
        <input
          name='TotalCost'
          id='TotalCost'
          value={formData.TotalCost}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.TotalCost}</p>

      <div className='add-new-bill-input-group'>
        <label htmlFor='SewerAmount'>Sewer Amount: </label>
        <input
          name='SewerAmount'
          id='SewerAmount'
          value={formData.SewerAmount}
          onChange={handleChange}
          autoComplete='off' />
      </div>
      <p className='error-msg'>{formErrors.SewerAmount}</p>

      <div className='add-new-bill-input-group'>
        <label htmlFor='OtherAmount'>Other Amount: </label>
        <input
          name='OtherAmount'
          id='OtherAmount'
          value={formData.OtherAmount}
          onChange={handleChange}
          autoComplete='off' />
      </div>

      <div className='add-new-bill-form-btn-wrapper'>
        <button
          type='submit'
          className='rr-btn add-new-bill-submit'
          onClick={handleSubmit}>Submit</button>
      </div>

    </form>

  )
}
