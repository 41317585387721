import { useContext } from 'react';
import "./ProposalForm.css";
import "./BillDataInput.css";
import BackButton from '../../components/salesAnalysis/BackButton';
import { useNavigate } from 'react-router-dom';
import { Context } from "../../context/ProposalContext";
import BillDataInput from '../../components/salesAnalysis/billDataInput/BillDataInput';

export default function BillDataInputScreen() {
  const navigate = useNavigate();
  const { state } = useContext(Context);

  const handleBack = async () => {
    navigate('/sales/proposal', {state: {Id: state.proposalId, Status: "draft"}});
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <BackButton handleBack={handleBack} />
      <h1 className='bill-title'>Billing Information</h1>
      <BillDataInput />
    </div>
  );
}
