import { useState, useRef, useContext } from 'react';
import ReactToPrint from 'react-to-print';
import html2canvas from 'html2canvas';
import api from '../../../api/api';
import { Context as userContext } from '../../../context/AuthContext';
import { Context } from '../../../context/ProposalContext';
import { useLocation } from 'react-router-dom';
import BillProposalRow from './BillProposalRow';
import './BillProposal.css';

const BillProposal = ({ data }) => {
    const { state } = useContext(Context);
    const { state: userState } = useContext(userContext);
    const location = useLocation();
    let componentRef = useRef();    
    const printRef = useRef();
    const [updateMessage, setUpdateMessage] = useState('');

    const updateStatus = async (status) => {
        try {
            await api.updateStatus(userState.token, state.proposalId, status);
            setUpdateMessage('Thanks, the status of this proposal has been updated!');
        } catch (e) {
            console.log(e);
        }
    };

    const handleDownloadImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
    
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
    
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = 'image.jpg';
    
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };

    const message = updateMessage == '' ? null 
        : <div className='errors'>{updateMessage}</div>;

    const siteSetupFee = Math.round(data.SiteSetupFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const waterSavingsPercentrage = Math.round(data.WaterSavingsPercentage);
    
    return (
    <div>
        <div className='banyan-blue-box site-setup-fee'>
            {userState.propertyName}</div>
        <div className='banyan-blue-box site-setup-fee'>
            Margin: {data.MarkupPercentage}%</div>
        <div ref={(el) => (componentRef = el)}>
            <div ref={printRef}>
                <div className='banyan-blue-box site-setup-fee'>
                    Site Setup Fee: ${siteSetupFee}</div>
                <div className='banyan-blue-box site-setup-fee'>
                    Irrigation Water Savings: {waterSavingsPercentrage}%</div>
                <div className='widget-proposal common-box' >
                <table className='proposal-table'>
                    <tr>
                        <th></th>
                        <th>Year 1</th>
                        <th>Year 2</th>
                        <th>Year 3</th>
                        <th>Year 4</th>
                    </tr>
                    <BillProposalRow value={data.BaselineUsageKiloGallons} numberOfValues={4} title="Baseline Irrigation (kGals)" />
                    <BillProposalRow value={data.ExpectedUsageKiloGallons} numberOfValues={4} title="Projected Irrigation (kGals)" />               
                    <BillProposalRow value={(data.RateIncrease * 100) + '%'} numberOfValues={4} title="Projected Rate Increase" />
                    <BillProposalRow value={data.BaselineCost} compounder={data.RateIncrease} numberOfValues={4} title="Baseline Utility Cost" currency={true} />
                    <BillProposalRow value={data.ExpectedCost} compounder={data.RateIncrease} numberOfValues={4} title="Projected Utility Cost" currency={true} />
                    <BillProposalRow value={data.GrossSavings} compounder={data.RateIncrease} numberOfValues={4} title="Gross Savings" currency={true} />
                    <BillProposalRow value={data.AnnualFees} numberOfValues={1} title="Banyan Annual Fees" currency={true} />
                    <BillProposalRow value={data.NetSavings} numberOfValues={1} title="Net Savings" currency={true} />
                    <BillProposalRow value={data.LeakCostAvoidance} numberOfValues={1} title="Est. Cost Prevention Through Leak Detection" currency={true} />
                    <BillProposalRow value={data.AssetValuationIncrease} numberOfValues={1} title="Increase in Asset Value at 7% Cap Rate" currency={true} />
                    <BillProposalRow value={data.PaybackYears} numberOfValues={1} title="Payback, Years:" />
                    <BillProposalRow value={data.CarbonOffset} numberOfValues={1} title="Annual Carbon Offset (Pounds of CO2)" />
                    <BillProposalRow value={data.TreesSaved} numberOfValues={1} title="Equivalent to this many trees capturing CO2 annually" />
                </table>
                </div>
                {data.LightProposalDetail &&
                    <div>
                        <div className='banyan-blue-box site-setup-fee'>
                            Irrigation Light Proposal
                        </div>
                        <div className='widget-proposal common-box' >
                        <table className='proposal-table'>
                            <BillProposalRow value={data.BaselineUsageKiloGallons} numberOfValues={1} title="Baseline Irrigation (kGals)"/>
                            <BillProposalRow value={data.BaselineCost} numberOfValues={1} title="Baseline Utility Cost" currency={true} />
                            <BillProposalRow value={data.LightProposalDetail.SiteSetupFee} numberOfValues={1} title="Site Setup Fee" currency={true} />
                            <BillProposalRow value={data.LightProposalDetail.TotalPossibleLeakWasteKG} numberOfValues={1} title="Possible Leak Waste (KGal)"/>
                            <BillProposalRow value={data.LightProposalDetail.LeakCost} numberOfValues={1} title="Possible Leak Cost"/>
                            <BillProposalRow value={data.LightProposalDetail.AnnualFee} numberOfValues={1} title="Estimated Annual Fees" currency={true} />
                            <BillProposalRow value={data.LightProposalDetail.PaybackYears} numberOfValues={1} title="Estimated Payback Years"/>              
                        </table>
                        </div>
                    </div>
                }
            </div>
        </div>

        <div className='download-button-container'>
            <ReactToPrint
                trigger={() => <span className='download'>Download PDF</span>}
                content={() => componentRef} />
            <span className='download' onClick={handleDownloadImage}> Download PNG</span>
        </div>
        {message}
        {location.state === null || location.state.Status !== "approved" ?
        <div className='bill-proposal-form-button-wrapper'>
            <button className='bill-proposal-form-btn banyan-blue-box'
                onClick={() => updateStatus('approved')}>Approve</button>
            <button className='bill-proposal-form-btn banyan-blue-box'
                onClick={() => updateStatus('disqualified')}>Disqualify</button>
        </div>
        : 
        <div className='bill-proposal-form-button-wrapper'>
            <button className='bill-proposal-form-btn banyan-blue-box'
                onClick={() => updateStatus('won')}>Mark As Won</button>
            <button className='bill-proposal-form-btn banyan-blue-box'
                onClick={() => updateStatus('lost')}>Mark As Lost</button>
        </div>}
    </div>
    );
};

export default BillProposal;