import React, { useContext, useEffect, useState } from 'react';
import Navbar from "../../components/Navbar";
import './inventory.css'
import Select from "react-dropdown-select"
import { Context } from '../../context/AuthContext';
import LoadingSpinner from '../../components/LoadingSpinner';
import FetchInventoryHelper from '../../helpers/fetchInventoryHelper'
import api from '../../api/api';

const Testing = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [limboInventory, setLimboInventory] = useState([]);
    const [selectedLimboItem, setSelectedLimboItem] = useState(null);
    const [showFailText, setShowFailText] = useState(false);
    const [failReason, setFailReason] = useState(null);
    const [isPass, setIsPass] = useState(null);
    const [errors, setErrors] = useState({})
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const { state } = useContext(Context);
    const token = state.token;
    const helper = new FetchInventoryHelper(token);

    useEffect(() => {
        (async () => {
            const jsonResProperties = await helper.fetchLimbo();
            setLimboInventory(jsonResProperties);

            setIsLoading(false);
        })()
      }, []);

    const handleFailButton = () => {
        setShowFailText(true);
        setIsPass(false);
    }

    const handlePassButton = () => {
        setShowFailText(false);
        setFailReason(null);
        setIsPass(true);
    }

    const refresh = () => {
        setSelectedLimboItem(null);
        setFailReason(null);
    }

    const handleSubmit = async () => {
        try {
            if(formValidation()) {
                const dataForPost = {
                    InventoryItemId: selectedLimboItem,
                    Pass: isPass,
                    FailReason: failReason
                };
                
                setIsLoading(true);
                const response = await api.refurbishItem(state.token, dataForPost);
                setIsLoading(false);
                setIsSuccess(true);
                refresh();
            }
        }

        catch(error) {
            console.log(error);
            setIsLoading(false);
            setIsFail(true);
        }
    }

    const formValidation = () => {
        setIsFail(false);
        setIsSuccess(false);
        let newErrors = []
        let formIsValid = true;

        if (selectedLimboItem === null) {
            newErrors.push('Please select an item from limbo');
            formIsValid = false;
        }

        if (isPass === false && failReason === null) {
            newErrors.push('Please enter why it failed');
            formIsValid = false;
        }

        if (isPass === null) {
            newErrors.push('Please specify pass or fail');
            formIsValid = false;
        }
    
        setErrors(newErrors);
        
        return formIsValid;
      }

        return (
          <div className='container'>
              <Navbar category='inventory'/>
              <h1 className="sunray">Limbo Testing Form</h1>
              <div className="content">
                <div className='select-row'>
                    <h3>Inventory in Limbo</h3>
                    <Select options={limboInventory} onChange={(e) => setSelectedLimboItem(e[0].value)}/>
                </div>
                <div className='options'>
                    <h3>Fail</h3>
                    <input
                    type="radio"
                    name="pass_option"
                    onChange={handleFailButton}
                    />
                    <h3>Pass</h3>
                    <input
                    type="radio"
                    name="pass_option"
                    onChange={handlePassButton}
                    />
                </div>
                {showFailText &&
                    <div className='select-row'>
                        <textarea 
                        placeholder='Why did it fail?'
                        onChange={(e) => setFailReason(e.target.value)}
                        />
                    </div>
                }
                <div className='options'>
                    {isLoading === true &&
                    <LoadingSpinner/>
                    }
                    {isLoading === false &&
                    <button
                    onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                    }
                </div>
                <div>
                    {errors[0] && <p>{errors[0]}</p>}
                    {errors[1] && <p>{errors[1]}</p>}
                    {errors[2] && <p>{errors[2]}</p>}
                    {
                        isSuccess &&
                        <p className='success-message'>Submit Successful</p>
                    }
                    {
                        isFail &&
                        <p className='fail-message'>Submit Unsuccessful</p>
                    }
                </div>
              </div>
          </div>
        );
};
  
export default Testing;