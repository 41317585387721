
import '../Common.css' 
import NumberHeader from '../NumberFormat'

export default function VolumeActionImpact({leakDetectionResponseData}){

 return(   
    <div className='common-panel'>
        <span className='title'>
        <NumberHeader data={leakDetectionResponseData}></NumberHeader> 
            </span>
        <span className='common-panel-text-white'> Volume Avoidance (gallons)</span>
    </div>
 )
}