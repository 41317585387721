import '../Common.css'
import React from 'react';
import AlertCenterTable from './AlertCenterTable';
import moment from 'moment';
import './NotFullyOperationalTable.css';

const NotFullyOperationalTable = () => {
  const urlEndingNFOTable = '/NotFullyOperationalSites';

  const tableSortees = [{
    id: "PropertyName",
    desc: false
  }];

  const tableColumns = [
      {
        Header: 'Property Name',
        accessor: 'PropertyName',
        Cell: ({ row: { index }, value }) => <div>{value}</div> 
      },
      {
        Header: 'Location',
        accessor: 'Location',
        Cell: ({ row: { index }, value }) => <div>{value}</div>
      },
      {
        Header: 'Time Triggered',
        accessor: 'AlertTimestamp',
        Cell: ({ cell: { value } }) => {
            return (
              moment(value).format("h:mm a MM-DD-YYYY")
            );
          },
      },
      {
        Header: 'Impact',
        accessor: 'Impact',
        Cell: ({ row: { index }, value }) => <div>{value}</div> 
      },
    ];

    return (
      <AlertCenterTable 
          columns={tableColumns} 
          title="Not Fully Operational" 
          url={urlEndingNFOTable} 
          sortees={tableSortees} 
          getRowProps={row => ({
          style: {
            background: "white"
          }
      })}/>
    );
}


export default NotFullyOperationalTable;