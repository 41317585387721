import PaybackTable from "../../components/waterSavings/PaybackTable";
import Dropdown from "../../components/Dropdown";

const PaybackCalcs = () => {
    return (
        <div className='landing-screen-container-ws'>
            <Dropdown />
            <PaybackTable url="PaybackData" />
        </div>
    );
};

export default PaybackCalcs;