import './CarbonOffsetValue.css'
import '../Common.css';

export default function CarbonOffsetValue(carbonOffset) {
    const poundsToTonsDivisor = 2000;
    const carbonOffsetToTons = carbonOffset.carbonOffset / poundsToTonsDivisor;
    
    if (carbonOffset.carbonOffset > 1000000){
        return <div className='full-box'>
            <span className='co-tons-data'>
            {new Intl.NumberFormat("us-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
              }).format(carbonOffsetToTons)}
        </span>
        <span className='big-co-text'> Tons of Carbon Offset</span></div>
    }
        return <div><span className='co-data'>
        {new Intl.NumberFormat("us-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
              }).format(carbonOffset.carbonOffset)} 
    </span>
    <span className='big-co-text'> Pounds of Carbon Offset</span></div>
  }