import React, { useContext, useEffect, useState } from 'react';
import Navbar from "../../components/Navbar";
import api from '../../api/api';
import { Table, Steps, Flex } from 'antd';
import { Context } from '../../context/AuthContext';
import "../installReport/statusReport.css";
import StatusCounts from '../../components/installTracking/statusCounts';

const StatusReport = () => {
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { Step } = Steps;
    const { state } = useContext(Context);
    const token = state.token;

    const columns = [
        {
          title: 'Account',
          dataIndex: 'Account',
          filters: mapFilter('Account'),
          filterSearch: true,
          onFilter: (value, record) => record.Account.startsWith(value),  
          sorter: {
            compare: (a, b) => a.Account.localeCompare(b.Account),
            multiple: 1,
          },
        },
        {
          title: 'Property',
          dataIndex: 'Property',
          filters: mapFilter('Property'),
          filterSearch: true,
          onFilter: (value, record) => record.Property.startsWith(value),  
          sorter: {
            compare: (a, b) => a.Property.localeCompare(b.Property),
            multiple: 1,
          },
        },
        {
          title: 'Oppurtunity Close Date',
          dataIndex: 'OppurtunityCloseDate',
          filters: mapFilter('OppurtunityCloseDate'),
          filterSearch: true,
          onFilter: (value, record) => record.OppurtunityCloseDate.startsWith(value), 
          sorter: {
            compare: (a, b) => new Date(a.OppurtunityCloseDate) - new Date(b.OppurtunityCloseDate),
            multiple: 2,
          },
        },
        {
          title: 'Actual Completion Date',
          dataIndex: 'ActualCompletionDate',
          filters: mapFilter('ActualCompletionDate'),
          filterSearch: true,
          onFilter: (value, record) => record.ActualCompletionDate.startsWith(value), 
          sorter: {
            compare: (a, b) => new Date(a.ActualCompletionDate) - new Date(b.ActualCompletionDate),
            multiple: 3,
          },
        },
        {
          title: 'Estimated Completion Date',
          dataIndex: 'EstimatedCompletionDate',
          filters: mapFilter('EstimatedCompletionDate'),
          filterSearch: true,
          onFilter: (value, record) => record.EstimatedCompletionDate.startsWith(value), 
          sorter: {
            compare: (a, b) => new Date(a.EstimatedCompletionDate) - new Date(b.EstimatedCompletionDate),
            multiple: 4,
          },
        },
        {
          title: 'Subcontractor',
          dataIndex: 'SubContractor',
          filters: mapFilter('SubContractor'),
          filterSearch: true,
          onFilter: (value, record) => record.SubContractor.startsWith(value), 
          sorter: {
            compare: (a, b) => a.SubContractor.localeCompare(b.SubContractor),
            multiple: 5,
          }
        },
        {
          title: 'Install/Ops Manager',
          dataIndex: 'InstallManager',
          filters: mapFilter('InstallManager'),
          filterSearch: true,
          onFilter: (value, record) => record.InstallManager.startsWith(value), 
          sorter: {
            compare: (a, b) => a.InstallManager.localeCompare(b.InstallManager),
            multiple: 6,
          }
        },
        {
          title: 'City',
          dataIndex: 'City',
          filters: mapFilter('City'),
          filterSearch: true,
          onFilter: (value, record) => record.City.startsWith(value), 
          sorter: {
            compare: (a, b) => a.City.localeCompare(b.City),
            multiple: 7,
          }
        },
        {
          title: 'State',
          dataIndex: 'State',
          filters: mapFilter('State'),
          filterSearch: true,
          onFilter: (value, record) => record.State.startsWith(value), 
          sorter: {
            compare: (a, b) => a.State.localeCompare(b.State),
            multiple: 8,
          }
        },
        {
          title: 'Install Stage',
          dataIndex: 'InstallStatus',
          filters: mapFilter('InstallStatus'),
          filterSearch: true,
          onFilter: (value, record) => record.InstallStatus.startsWith(value), 
          sorter: {
            compare: (a, b) => a.InstallStatus.localeCompare(b.InstallStatus),
            multiple: 9,
          }
        },
      ];

      const expandedRowRender = (record) => {
        let lastCompletedNumber = record.Progress.filter(obj => obj['Pass'] === true).length;
        return (
          <Flex vertical='true' justify='start' align='flex-start'>
            <h3>Progress: </h3>
            <Steps direction='vertical' size="small" current={lastCompletedNumber}>
            {record.Progress.map((step, index) => (
              <Step title={step.Category}/>
            ))}
            </Steps>
          </Flex>
        )
      };

      useEffect(() => {
        (async () => {
          generateReport();
        })()
      }, []);
      const generateReport = async () => {
        let jsonRes = await fetchStatus();
        setDataSource(map(jsonRes.data));
      }

      const fetchStatus = async () => {
        setIsLoading(true);
        try {
            const res = await api.getInstallTrackingStatus(token);
            setIsLoading(false);
            return res;
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
      };

      const handleUpdate = async () => {
        setIsLoading(true);
        try {
          const res = await api.updateInstallStatus(token);
          setDataSource(map(res.data));
          setIsLoading(false);
          return res;
        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }
      }

      const highlightOldInstall = (record) => {
        const today = new Date();
        const month = today.getMonth()+1;
        const day = today.getDate();
        const year = today.getFullYear();
        const formattedToday = new Date(`${year}/${month}/${day}`);
        const fourWeeksOffset = (24*60*60*1000) * 28;
        const fourWeeksAgo = new Date(formattedToday - fourWeeksOffset);
        const installStartDate = new Date(record.OppurtunityCloseDate);

        if (installStartDate <= fourWeeksAgo && record.InstallStatus !== 'Completed') {
          return 'alert-row';
        }
      }

      const map = (data) => {
        return data.map((d) => ({
          'key': d.Id,
          'Account': d.Account ? d.Account : '-',
          'Property': d.Property ? d.Property : '-',
          'OppurtunityCloseDate': d.OppurtunityCloseDate ? d.OppurtunityCloseDate.split('T')[0] : null,
          'ActualCompletionDate': d.ActualCompletionDate ? d.ActualCompletionDate.split('T')[0] : null,
          'EstimatedCompletionDate': d.EstimatedCompletionDate ? d.EstimatedCompletionDate.split('T')[0] : null,
          'SubContractor': d.SubContractor ? d.SubContractor : '-',
          'InstallManager': d.InstallManager ? d.InstallManager : '-',
          'City': d.City ? d.City : '-',
          'State': d.State ? d.State : '-',
          'InstallStatus': d.InstallStatus ? d.InstallStatus : '-',
          'Progress': d.Progress
        }));
      }
      function mapFilter(column) {
         let distinctValues = [...new Set(dataSource.map(a => a[column]))];
         return distinctValues.map((d) => ({
           'text': d,
           'value': d
         }));
      }

    return (
        <div className='container'>
          <Navbar category='tracking'/>
        <div className='title'>
          <h3>Install Tracking Report</h3>
        </div>
          {isLoading == false &&
            <div className='action'>
              <button onClick={async => handleUpdate()}>
                Update
              </button>
            </div>
          }
          {dataSource.length > 0 && <StatusCounts data={dataSource}/>}
          <Table 
            dataSource={dataSource} 
            columns={columns} 
            loading={isLoading}
            pagination={false}
            size='middle'
            expandable={{expandedRowRender}}
            rowClassName={highlightOldInstall}
          />
        </div>
    );
  }

export default StatusReport;