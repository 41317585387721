import React, { useState } from 'react';
import Select from 'react-select';
import DateRangePick from '@wojtekmaj/react-daterange-picker';
import { ReactSelectCustomTheme, ReactSelectCustomStyles } from "../../constants/selectFomFieldStyle"

export default function BebbiaDateDropdown({ handleDateOption, handleCustomDates: handleDateRange }) {
    const options = [
        { value: 0, label: 'Instalación hasta la fecha' },
        { value: 1, label: 'Último mantenimiento hasta la fecha' },
        { value: 2, label: 'Personalizado' }
    ];

    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [dateRange, setDateRange] = useState([null, null]);

    const changeDateRange = (e) => {
        setDateRange([e[0], e[1]]);
        handleDateRange(e);
    }

    const handleSelect = (e) => {
        if (e.value !== 2) {
            changeDateRange([null, null]);
        }
        setSelectedOption(e.value);
        handleDateOption(e);
    }

    return (<><div className="bebbia-date-dropdown" >
        <Select
            defaultValue={null}
            onChange={(e) => handleSelect(e)}
            placeholder='Seleccionar fecha...'
            theme={(theme) => ReactSelectCustomTheme(theme)}
            styles={ReactSelectCustomStyles}
            options={options}
        />
    </div>
        <div className="bebbia-date-range" style={{ visibility: selectedOption === 2 ? "visible" : "hidden" }}><DateRangePick
            locale="es"
            clearIcon={null}
            onChange={changeDateRange}
            value={dateRange} /></div>
    </>)
}