import React, { useState, useMemo } from 'react';
import { format } from 'date-fns';
import Table from '../../components/Table';
import DropdownEditableCell from './DropdownEditableCell';
import CustomModal from '../CustomModal';
import "./ResponseRateTable.css";
import NumberFormat from '../NumberFormat';

export default function ResponseRateTable({ userRoleOptions }) {
    const [isShowingResponses, setIsShowingResponses] = useState(false);
    const [pastResponses, setPastResponses] = useState(null);
    const urlEndingUserRoleTable = '/SurveyResponseRatePerUser';

    const updateUserRole = (rowIndex, columnId, value) => {
        //TODO: send request
        //TODO: refresh table
        // console.log(tableData[rowIndex])
    };

    const deleteRow = (rowData) => {
        //TODO: send delete row request
        //TODO: refresh table
        // console.log(rowData);
    };

    const callback = (value, index, data) => {
        data = data[index];
        setPastResponses({
            fullName: data.FullName,
            feedbacks: data.Feedbacks,
            questions: data.Questions,
            answers: data.Answers
        });
        setIsShowingResponses(true);
    };


    const columns = useMemo(() => [
        {
            Header: 'Full Name',
            accessor: 'FullName'
        },
        {
            Header: 'Day Assigned',
            accessor: 'DayAssigned',
            Cell: ({ value }) => value && format(new Date(value), 'MM/dd/yyyy')
        },
        {
            Header: 'Number Of Responses',
            accessor: 'NumberOfResponses',
            Cell: ({ row: { index }, value }) => <div>{value}</div>
        },
        {
            Header: 'Response Rate',
            accessor: 'ResponseRate',
            Cell: ({ cell: { value } }) => {
                return (
                    <>
                        <NumberFormat data={value}></NumberFormat>%
                    </>
                );
            }
        },
        {
            Header: 'Role',
            accessor: 'Role',
            editable: true,
            Cell: ({
                value: initialValue,
                row: { index },
                column: { id }
            }) => <DropdownEditableCell
                    value={initialValue}
                    row={index}
                    column={id}
                    options={userRoleOptions}
                    handleUpdateData={updateUserRole} />

        },
        {
            Header: 'Property Name',
            accessor: 'PropertyName'
        },
        {
            Header: '',
            accessor: 'Delete',
            disableSortBy: true,
            Cell: (cellObj) => <div className='buttons-wrapper'>
                <button className='rr-btn rr-delete-btn' onClick={() => deleteRow(cellObj.row.values)}>Delete</button>
            </div>

        }
    ], [userRoleOptions]);

    const buildSurveyComponents = () => {
        let surveys = [];
        pastResponses.forEach((res, i) => surveys.push(
            <li key={`survey${i}`}>
                <span>{res.questions[i]}:</span>
                <span>{res.answers[i]}</span>
            </li>));

        return surveys;
    };

    return (
        <div className='table-container-ws table-container-rr'>
            <Table columns={columns} url={urlEndingUserRoleTable} updateData={updateUserRole} callback={callback} />

            <CustomModal
                isOpen={isShowingResponses}
                closeModal={() => setIsShowingResponses(false)}
                title={pastResponses && pastResponses.fullName}
            >
                {pastResponses &&
                    <div className='response-detail-wrapper'>
                        <h3 className='response-category'>Feedback</h3>
                        <ul>
                            {pastResponses.feedbacks.length ?
                                pastResponses.feedbacks.map((feedback, i) => (
                                    <li key={`feedback${i}`}>{feedback}</li>
                                ))
                                : <p>No feedback record</p>}
                        </ul>
                        <h3 className='response-category'>Survey</h3>
                        <ul>
                            {pastResponses.questions.length ?
                                buildSurveyComponents()
                                : <p>No Survey record</p>}
                        </ul>
                    </div>
                }
            </CustomModal>
        </div>
    );
}
