import React from 'react';
import LoadingSpinner from '../LoadingSpinner';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeScale,
  LineController,
  BarController
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Chart } from 'react-chartjs-2';
import { useState, useContext, useEffect } from 'react';
import { Context } from '../../context/AuthContext';
import api from '../../api/api';
import './WaterUsageGraph.css';


ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale,
  LineController,
  BarController,
  Legend,
  Tooltip,
);

const causeTypeLabels = {
  scheduled: 'Scheduled',
  unscheduled: 'Unscheduled',
  manual: 'Manual',
  nonBanyan: 'Non-Banyan Battery Operated',
  waterFeatures: 'Water Features',
  plantEstablishment: 'Plant Establishment',
  leakWasteUsage: 'Leak Waste'
};

export function WaterUsageGraph({ callback, urlEnding = "WaterSavingsGraphData" }) {
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState(['']);
  const [baselineData, setBaselineData] = useState(['']);
  const [gallonsSaved, setGallonsSaved] = useState(0);
  const [netSavings, setNetSavings] = useState(0);
  const [scheduled, setScheduled] = useState(['']);
  const [unscheduled, setUnscheduled] = useState(['']);
  const [manual, setManual] = useState(['']);
  const [nonBanyan, setNonBanyan] = useState(['']);
  const [waterFeatures, setWaterFeatures] = useState(['']);
  const [plantEstablishment, setPlantEstablishment] = useState(['']);
  const [leakWasteUsage, setLeakWasteUsage] = useState(['']);

  const urlEndingWSGraph = '/AssetDashboard/' + urlEnding;

  const { state } = useContext(Context);
  const token = state.token;
  const startDate = state.startDate;
  const endDate = state.endDate;
  const accountId = state.accountId;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      fetchGraphData();
    })();
  }, [startDate, endDate]);

  const getDataset = (legendText) => {
    switch (legendText) {
      case causeTypeLabels.scheduled:
        return scheduled;
      case causeTypeLabels.unscheduled:
        return unscheduled;
      case causeTypeLabels.manual:
        return manual;
      case causeTypeLabels.nonBanyan:
        return nonBanyan;
      case causeTypeLabels.waterFeatures:
        return waterFeatures;
      case causeTypeLabels.plantEstablishment:
        return plantEstablishment;
      case causeTypeLabels.leakWasteUsage:
        return leakWasteUsage;
    }
  };

  const handleLegendClick = function (e, legendItem, legend) {
    const text = legendItem.text;
    const dataset = getDataset(text);

    const gallons = dataset.map(x => x.Usage).reduce((a, b) => a + b, 0);
    const cost = dataset.map(x => x.Cost).reduce((a, b) => a + b, 0);

    let gallonsDelta = 0;
    let savingsDelta = 0;

    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
      gallonsDelta += gallons;
      savingsDelta += cost;
    } else {
      ci.show(index);
      legendItem.hidden = false;
      gallonsDelta -= gallons;
      savingsDelta -= cost;
    }

    const baselineUsage = baselineData.reduce((a, b) => a + b);
    const waterSavingsPercent = gallonsDelta / baselineUsage * 100;

    callback(text, gallonsDelta, savingsDelta, waterSavingsPercent);
  }

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Water Usage',
      },
      legend: {
        onClick: handleLegendClick
      }
    },
    responsive: true,
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          unit: 'month',
        },
        stacked: true,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false
        }
      },
    },
  };

  const fetchGraphData = () => {
    api.getData(startDate, endDate, accountId, urlEndingWSGraph, token)
      .then((response) => {
        var labelsReadings = [];
        var baselineDataReadings = [];
        var scheduledReadings = [];
        var unscheduledReadings = [];
        var manualReadings = [];
        var nonBanyanReadings = [];
        var waterFeaturesReadings = [];
        var plantEstablishmentReadings = [];
        var leakWasteUsageReadings = [];

        let waterSavings = 0;
        let netDollarSavings = 0;
        response.data.WaterSavingsGraphData.forEach(element => {
          const baseline = element.Baseline;
          const scheduled = element.Scheduled;
          const unscheduled = element.Unscheduled;
          const manual = element.Manual;
          const nonBanyan = element.NonBanyanBatteryOperated;
          const waterFeatures = element.WaterFeatures;
          const plantEstablishment = element.PlantEstablishment;
          const leakWasteUsage = element.LeakWasteUsage;
          labelsReadings.push(element.Month);
          baselineDataReadings.push(baseline)
          scheduledReadings.push(scheduled);
          unscheduledReadings.push(unscheduled);
          manualReadings.push(manual);
          nonBanyanReadings.push(nonBanyan);
          waterFeaturesReadings.push(waterFeatures);
          plantEstablishmentReadings.push(plantEstablishment);
          leakWasteUsageReadings.push(leakWasteUsage);

          const periodSavings = element.Baseline - scheduled.Usage
            - unscheduled.Usage - manual.Usage - nonBanyan.Usage
            - waterFeatures.Usage - plantEstablishment.Usage - leakWasteUsage.Usage;

          waterSavings += periodSavings;
          netDollarSavings += (element.BaselineCost - element.Cost);
        });

        const banyanFees = response.data.BanyanFees;
        const costAvoidance = response.data.LeakCostAvoidance;
        setNetSavings(netDollarSavings - banyanFees + costAvoidance);
        setGallonsSaved(waterSavings);
        setLabels(labelsReadings);
        setBaselineData(baselineDataReadings);
        setScheduled(scheduledReadings);
        setUnscheduled(unscheduledReadings);
        setManual(manualReadings);
        setNonBanyan(nonBanyanReadings);
        setWaterFeatures(waterFeaturesReadings);
        setPlantEstablishment(plantEstablishmentReadings);
        setLeakWasteUsage(leakWasteUsageReadings);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error)
      });
    return { isLoading };
  }

  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Baseline',
        borderColor: '#000000',
        borderWidth: 2,
        fill: false,
        data: baselineData,
      },
      {
        type: 'bar',
        label: 'Scheduled',
        backgroundColor: '#7B9DC3',
        data: scheduled.map(x => x.Usage),
      },
      {
        type: 'bar',
        label: 'Unscheduled',
        backgroundColor: '#F8E472',
        data: unscheduled.map(x => x.Usage),
      },
      {
        type: 'bar',
        label: 'Manual',
        backgroundColor: '#E4B363',
        data: manual.map(x => x.Usage),
      },
      {
        type: 'bar',
        label: 'Plant Establishment',
        backgroundColor: '#96A480',
        data: plantEstablishment.map(x => x.Usage),
      },
      {
        type: 'bar',
        label: 'Non-Banyan Battery Operated',
        backgroundColor: '#5E655E',
        data: nonBanyan.map(x => x.Usage),
      },
      {
        type: 'bar',
        label: 'Water Features',
        backgroundColor: '#C4DAE0',
        data: waterFeatures.map(x => x.Usage),
      },
      {
        type: 'bar',
        label: 'Leak Waste',
        backgroundColor: '#FFA8A8',
        data: leakWasteUsage.map(x => x.Usage),
      },
    ].filter(dataset => dataset.data.some(data => data > 0))
  };

  return (
    <div className='wrapper'>
      {isLoading ? <LoadingSpinner />
        : (<Chart type='bar' options={options} data={data} />)}
    </div>
  )
}
