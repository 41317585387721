import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function BebbiaWaterConsumptionGraph({ graphData }) {
    const graphLabels = {
        accumulated: "Consumo acumulado de agua",
        consumption: "Consumo de agua"
    };

    const accumulatedConsumptions = graphData.map(d => d.AccumulatedWaterConsumption.toFixed(2));
    const dailyWaterConsumptions = graphData.map(d => d.DailyWaterConsumption.toFixed(2));
    const labels = graphData.map(d => moment(d.Date).format("DD-MMM-YY"));

    const data = {
        labels,
        datasets: [
            {
                type: 'line',
                label: graphLabels.consumption,
                borderColor: '#7B9DC3',
                backgroundColor: 'rgba(123, 157, 195, 0.5)',
                borderWidth: 2,
                pointBackgroundColor: 'rgba(123, 157, 195, 0.5)',
                fill: false,
                data: dailyWaterConsumptions
            },
            {
                type: 'line',
                fill: true,
                label: graphLabels.accumulated,
                borderWidth: 1,
                borderColor: 'rgba(94, 101, 94, 0.2)',
                pointRadius: 2,
                pointBackgroundColor: 'rgba(94, 101, 94, 0.3)',
                backgroundColor: 'rgba(94, 101, 94, 0.2)',
                data: accumulatedConsumptions
            }
        ]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Consumo de agua',
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        var label = tooltipItem.formattedValue || "";
                        return label;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: { display: false }
            },
            y: {
                grid: { display: false }
            }
        }
    };

    return (
        graphData.length > 0 ?
            <div className='bebbia-chart'>
                <Chart options={options} data={data} />
            </div>
            : <div className='bebbia-no-data'>No Data</div>
    )
}
