import { useState, useEffect } from "react";
import { Select } from 'antd';
import LoadingSpinner from "../LoadingSpinner";
import './OrderForm.css';

const OrderForm = (props) => {
    const [product, setProduct] = useState('');
    const [property, setProperty] = useState('');
    const [orderType, setOrderType] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [hasReturnLabel, setHasReturnLabel] = useState(false);
    const [isReimbursable, setIsReimbursable] = useState(false);
    const [description, setDescription] = useState('');
    const [lineDescription, setLineDescription] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const orderTypes = [
        {
            'value': 'Repair',
            'label': 'Repair'
        },
        {
            'value': 'Replace',
            'label': 'Replace'
        },
        {
            'value': 'New Install',
            'label': 'New Install'
        }
        ];

    const generateSelectData = (data) => {
        return data.map((d) => ({
            'value': d.Id,
            'label': d.Name
        }));
        }

    const handleFormSubmit = async (e) => {
        try
        {
            if(formValidation()) {
                setIsLoading(true);

                await props.handleNewOrderSubmit(
                    {
                        'ComponentId': product,
                        'PropertyId': property,
                        'OrderType': orderType,
                        'SFOrderNumber': orderNumber,
                        'HasReturnLabel': hasReturnLabel,
                        'IsReimbursable': isReimbursable,
                        'Quantity': quantity,
                        'CreatedBy': createdBy,
                        'Description': description,
                        'LineDescription': lineDescription
                    }
                )
                
                setIsLoading(false);
                setIsSuccess(true);
            }
        }

        catch(error)
        {
            console.log(error);
            setIsLoading(false);
            setIsFail(true);
        }
    }

    const formValidation = () => {
        setIsFail(false);
        setIsSuccess(false);
        let newErrors = []
        let formIsValid = true;

        if (product === "") {
            newErrors.push('Please select a product')
            formIsValid = false;
        }

        if (property === "") {
            newErrors.push('Please select an action')
            formIsValid = false;
        }

        if (quantity === "" || quantity <= 0) {
            newErrors.push('Please enter a valid quantity')
            formIsValid = false;
        }

        if (createdBy === "") {
            newErrors.push('Please enter Created By')
            formIsValid = false;
        }

        if (orderType === "") {
            newErrors.push('Please select an order type')
            formIsValid = false;
        }
    
        setErrors(newErrors);
        
        return formIsValid;
      }

    return (
        <div className="container">
            <div className="input-section">
                <div className="input-group">
                    <h3>Product Name</h3>
                    <Select 
                        options={generateSelectData(props.components)}
                        onChange={(label) => setProduct(label)}
                        style={{minWidth:500}}
                    />
                </div>
            </div>
            <div className="input-section">
                <div className="input-group">
                    <h3>Property</h3>
                    <Select 
                        options={generateSelectData(props.properties)}
                        onChange={(label) => setProperty(label)}
                        style={{minWidth:500}}
                    />
                </div>
            </div>
            <div className="input-section">
                <div className="input-group">
                    <h3>Order Number</h3>
                    <input type="number" onChange={(e) => setOrderNumber(e.target.value)}/>
                </div>
                <div className="input-group">
                    <h3>Return Label</h3>
                    <input type="checkbox" onChange={(e) => setHasReturnLabel(e.target.checked)}/>
                </div>
                <div className="input-group">
                    <h3>Reimbursable</h3>
                    <input type="checkbox" onChange={(e) => setIsReimbursable(e.target.checked)}/>
                </div>
            </div>
            <div className="input-section">
                <div className="input-group">
                    <h3>Quantity</h3>
                    <input type="number" min='0' onChange={(e) => setQuantity(e.target.value)}/>
                </div>
                <div className="input-group">
                    <h3>Created By</h3>
                    <input onChange={(e) => setCreatedBy(e.target.value)}/>
                </div>
                <div className="input-group">
                    <h3>Order Type</h3>
                    <Select 
                        options={orderTypes}
                        onChange={(label) => setOrderType(label)}
                        style={{minWidth:150}}
                    />
                </div>
            </div>
            <div className="input-section">
                <div className="input-group">
                    <h3>Description</h3>
                    <textarea onChange={(e) => setDescription(e.target.value)}/>
                </div>
            </div>
            <div className="input-section">
                <div className="input-group">
                    <h3>Line Description</h3>
                    <textarea onChange={(e) => setLineDescription(e.target.value)}/>
                </div>
            </div>
            <div className="input-section">
                {
                    isSuccess ? (
                        <button onClick={props.onCancel}>
                            Done
                        </button> 
                    ) : (
                        <button onClick={handleFormSubmit}>
                            Submit
                        </button> 
                    )
                }
            </div>
            {isLoading === true &&
                <LoadingSpinner/>
            }
            <div>
                {errors[0] && <p>{errors[0]}</p>}
                {errors[1] && <p>{errors[1]}</p>}
                {errors[2] && <p>{errors[2]}</p>}
                {errors[3] && <p>{errors[3]}</p>}
                {errors[4] && <p>{errors[4]}</p>}
                {
                    isSuccess &&
                    <p className='success-message'>Submit Successful</p>
                }
                {
                    isFail &&
                    <p className='fail-message'>Submit Unsuccessful</p>
                }
            </div>
        </div>
    );
};

export default OrderForm;