import './LeakCostAvoidanceDashboard.css';
import '../../components/Common.css'
import Dropdown from "../../components/Dropdown";
import api from '../../api/api';
import { Context } from "../../context/AuthContext";
import { useState, useContext, useEffect } from "react";
import IndoorSavingsDrilldownWidget from '../../components/leakCostAvoidance/IndoorSavingsDrilldownWidget';
import '../assetDashboard/WaterSavingsDrilldown.css';
import IndoorSavingsTable from '../../components/leakCostAvoidance/IndoorSavingsTable';
import { useNavigate } from "react-router-dom";

const LeakCostAvoidanceDashboard = () => {
    const letterhead = require('../../../src/assets/images/letterhead.png');
    const { state } = useContext(Context);
    const navigate = useNavigate();
    const [rollupData, setRollupData] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const token = state.token;
    const isAdmin = state.isAdmin;
    const isIrr = state.isIrr;
    const startDate = state.startDate;
    const endDate = state.endDate;
    const accountId = state.accountId;
    const controller = '/AssetDashboard/';
    const url = "IndoorSavingsRollupNumbers";

    useEffect(() => {
      (async () => {
        fetchData();
      })();
    }, [startDate, endDate]);

    const fetchData = () => {
      api.getData(startDate, endDate, accountId, controller + url, token)
          .then((response)=>{
              setRollupData(response.data);
              setIsLoading(false);
          })
          .catch((error) => {
              console.log(error)
          })  
    }

    const handleBackForSelectProduct = () => {
      navigate('/selectproduct');
    };

    return (
      <div>
        <div className='letterhead-container'>
          <img
            src={letterhead}
            alt="Banyan Water Letterhead with Logo"
            className="letterhead"
          ></img>
        </div>
        <div className="back-button-container">
            {!isAdmin && isIrr ? <button onClick={handleBackForSelectProduct}>Back</button> : null}
        </div>
        <div>
          <div className='landing-screen-container-lca'>
              <IndoorSavingsDrilldownWidget data={rollupData} isLoading={isLoading} />
              <Dropdown />
              <IndoorSavingsTable url="TabularIndoorPropertyData" />
          </div>
        </div>
      </div>
    );
}


export default LeakCostAvoidanceDashboard;