import React, { useState, useContext, useEffect } from 'react';
import { useSortBy, useGlobalFilter } from 'react-table';
import { useTable } from 'react-table/dist/react-table.development';
import GlobalFilter from '../GlobalFilter';
import '../Table.css';
import { Context as userContext } from '../../context/AuthContext'
import { CSVLink } from "react-csv";
import api from '../../api/api';
import LoadingSpinner from '../LoadingSpinner';
import CustomModal from '../CustomModal';

export default function AlertCenterTable({ columns, url, title, sortees, getRowProps = () => ({})}) {
  const rowsPerRequest = 20;

  const [isLoading, setIsLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isShowingResponses, setIsShowingResponses] = useState(false);
  const [emailBodyHTML, setEmailBodyHTML] = useState('');
  const [showAllRecords, setShowAllRecords] = useState(false);

  const updateShowAllRecords = () => {
    setShowAllRecords(true);
    setTableData(data);
  };

  const { state: userState } = useContext(userContext);
  const token = userState.token;
  const accountId = userState.accountId;
  const urlEndingEmailBody = '/AlarmEmailBody';

  useEffect(() => {
    fetchTableData();
  }, [skip]);

  const displayEmailBody = (index) => {
    const alert = data[index];
    fetchEmailBody(alert.PropertyId, alert.AlarmConfigId);
    setIsShowingResponses(true)
  };

  const fetchEmailBody = (propId, alarmConfigId) => {
    api.getAlarmEmailBody(propId, alarmConfigId, accountId, urlEndingEmailBody, token)
      .then((response)=>{
          const responseData = response.data;
          setEmailBodyHTML(responseData.emailBody);  
      })
      .catch((error) => {
          console.log(error)
      })
  };

  const fetchTableData = () => {
    api.getTableData(new Date(), new Date(), accountId, skip, url, token)
        .then((response)=> {
            const responseData = response.data;
            if (isLoading)
              setTableData(responseData);
            setIsLoading(false);

            const currentData = data;
            setData([...currentData, ...responseData]);
            if (showAllRecords){
              setTableData([...currentData, ...responseData]);
            }

            if (responseData.length == rowsPerRequest) {
              setSkip(skip + rowsPerRequest);
            }
        })
        .catch((error) => {
            console.log(error)
        });
  };

  const printer = require('../../../src/assets/images/printer.png');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    } = useTable({ columns, data: tableData
  }, useGlobalFilter, useSortBy);

  if (isLoading) 
    return <LoadingSpinner/>;

  return (
    <div className='table-container'>
      <div className='table-header'>
        <div className='filter-container'>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <span className='title table-title'>{title}</span>      
        <CSVLink data={data}
              filename={"water-savings.csv"}>
                <button className='button-ws'>
                  <span className='image-container'>
                    <img
                      src={printer}
                      alt="CSV"
                      className="printer-image"
                    ></img>
                  </span>
                  <span className='button-text-ws'>Export Table</span>
                </button>
        </CSVLink>
      </div>

      <table {...getTableProps()} style={{ border: 'solid 1px' }}>
        <thead>
          {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              style={{
                background: '#034C8C',
                border: 'solid 1px gray',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Raleway, san-serif',
                minWidth: 40,
                width: 200, 
                cursor: 'pointer'
              }}>
              {column.render('Header')}
              <span>
                {column.isSorted
                  ? column.isSortedDesc ? ' 🔽' : ' 🔼'
                  : ''}
                </span>
            </th>))}
          </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map(cell => {
                return (
                  <td onClick={() => displayEmailBody(index)}
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      border: 'solid 1px gray',
                      color: 'black',
                      background: cell.row.index % 2 === 0 ? 'white' : '#EAF5FF',
                      fontFamily: 'Raleway, san-serif',
                      cursor: 'pointer'
                    }}>
                      {cell.render('Cell')}
                  </td>)})}
              </tr>
            );
          })}
        </tbody>
      </table>

      {data.length > 20 
        ? <div className='table-show-more' onClick={() => updateShowAllRecords()}>
          <span className='plus'>+</span> Show all records
          </div>
        : null}

      <CustomModal
          isOpen={isShowingResponses}
          closeModal={() => setIsShowingResponses(false)}
          title="Communications Down Alert">
          <div className='od-response-detail-wrapper'>
            {
              <div>
                <div dangerouslySetInnerHTML={{ __html: emailBodyHTML }} />
              </div>}
          </div>
        </CustomModal>
    </div>
  );
}