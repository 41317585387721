
import React from 'react';
import Table from '../Table';
import NumberFormat from '../NumberFormat';

const CarbonOffsetTable = ({ url }) => {
  const tableSortees = [{
    id: "PropertyName",
    desc: false
  }];

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Property Name',
        accessor: 'PropertyName', // accessor is the "key" in the data
      },
      {
        Header: 'Location',
        accessor: 'LocationText', // accessor is the "key" in the data
      },
      {
        Header: 'Pounds Saved',
        accessor: 'CarbonOffset',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
      {
        Header: 'Trees Planted',
        accessor: 'TreesPlanted',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
    ],
    []
  );

  return (
    <div className='table-container-wst'>
      <Table columns={tableColumns} url={url} sortees={tableSortees} callback={() => {}}/>
    </div>
  );
}


export default CarbonOffsetTable;