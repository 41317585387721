
import '../Common.css'
import React from 'react';
import AlertCenterTable from './AlertCenterTable';
import moment from 'moment';

const OfflineDevicesTable = () => {
  const url = '/OfflineDevices';

  const tableSortees = [{
    id: "PropertyName",
    desc: false
  }];

  const tableColumns = [
      {
        Header: 'Property Name',
        accessor: 'PropertyName', 
        Cell: ({ row: { index }, value }) => <div>{value}</div> 
      },
      {
        Header: 'Location',
        accessor: 'Location',
        Cell: ({ row: { index }, value }) => <div>{value}</div>
      },
      {
        Header: 'Time Triggered',
        accessor: 'AlertTimestamp',
        Cell: ({ cell: { value } }) => {
            return (
              moment(value).format("h:mm a MM-DD-YYYY")
            );
          }
      },
    ];
    
  return (
        <AlertCenterTable
          columns={tableColumns}
          url={url}
          title="Offline Devices"
          sortees={tableSortees}
          getRowProps={row => ({ style: { background: "white" }})} />
    );
}


export default OfflineDevicesTable;