import React from 'react'

export default function SpecialNumberFormField({ id, question, type, parentValue,
    defaultValue, handleChange, hasError }) {
    return (
        <div className='proposal-form-field-wrapper' key={`q-${id}`}>
            {parentValue === 'false' &&
                <div className='proposal-form-row'>
                    <label htmlFor={id}>{question}</label>
                    <input className='proposal-form-input'
                        type={type}
                        defaultValue={defaultValue}
                        onChange={handleChange}
                        name={id}
                        id={`q-${id}`}
                        autoComplete='off' />
                </div>}
            {hasError && <div className='proposal-form-error'>This field has invalid input.</div>}
        </div>
    )
}