import './Tree.css';
export default function Tree(){

    const tree = require('../../../src/assets/images/tree.png');  
 return(   
    <div className='tree-container'>
        <img
                src={tree}
                alt="Tree"
                className="tree-image"
                ></img>
    </div>
 )
}