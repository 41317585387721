const groupBy = (objectArray, property) => {
  let groupedArray = objectArray.reduce((acc, obj) => {
    if (!acc[obj[property]])
      acc[obj[property]] = [];
    acc[obj[property]].push(obj);
    return acc;
  }, {});

  return groupedArray;
}

const flattenObject = (obj, parentKey = '') => {
  if (parentKey !== '') parentKey += '.';
  let flattened = {};
  Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
          Object.assign(flattened, flattenObject(obj[key], parentKey + key))
      } else {
          flattened[parentKey + key] = obj[key]
      }
  })
  return flattened;
}

const flattenArray = (array) => {
  let flattenedArray = [];
  array.forEach(item => {
    flattenedArray.push(flattenObject(item))
  })
  return flattenedArray;
}

const FormulaHelper = {
  groupBy,
  flattenObject,
  flattenArray
};

export default FormulaHelper;