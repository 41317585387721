
import '../Common.css';
import React from 'react';
import Table from '../Table';
import './WaterSavingsTable.css'
import NumberFormat from '../NumberFormat';
import CurrencyFormat from '../CurrencyFormat';

const PropertyWaterSavingsTable = ({ data }) => {
  const newData = data.PropertyWaterSavingsAssetDashboard;
  const tableData = newData.map(obj => {
    obj.UsePeriod = obj.UsePeriod;
    obj.Baseline = obj.Baseline;
    obj.BaselineCost = obj.BaselineCost;
    obj.Usage = obj.Usage;
    obj.Cost = obj.Cost;
    obj.PerformancePercentage = obj.PerformancePercentage;
    obj.Manual = obj.Manual?.Usage;
    obj.NonBanyanBatteryOperated = obj.NonBanyanBatteryOperated?.Usage;
    obj.PlantEstablishment = obj.PlantEstablishment?.Usage;
    obj.Scheduled = obj.Scheduled?.Usage;
    obj.Unscheduled = obj.Unscheduled?.Usage;
    obj.WaterFeatures = obj.WaterFeatures?.Usage;
    obj.LeakWasteUsage = obj.LeakWasteUsage?.Usage;
    return obj;
  });
  const tabularData = [...tableData].reverse();
  
  const tableColumns = [
      {
        Header: 'Use Period',
        accessor: 'UsePeriod',// accessor is the "key" in the data
      },
      {
        Header: 'Baseline Consumption (gallons)',
        accessor: 'Baseline',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Baseline Cost',
        accessor: 'BaselineCost',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Cumulative Consumption (gallons)',
        accessor: 'Usage',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Cumulative Cost',
        accessor: 'Cost',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Water Savings',
        accessor: 'PerformancePercentage',
        Cell: ({ cell: { value } }) => {
          const percentString = Math.round(value * 100) + '%';
          return (<span>{percentString}</span>);
        }
      },
      {
        Header: 'Scheduled',
        accessor: 'Scheduled',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Unscheduled',
        accessor: 'Unscheduled',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Manual',
        accessor: 'Manual',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Non Banyan Battery Operated',
        accessor: 'NonBanyanBatteryOperated',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Plant Establishment',
        accessor: 'PlantEstablishment',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Water Features',
        accessor: 'WaterFeatures',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Leak Waste',
        accessor: 'LeakWasteUsage',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
    ];

  return (
    <div className='table-container-wst'>
      <Table columns={tableColumns} defaultData={tabularData} title={"Property Water Savings"}/>
    </div>
  );
}


export default PropertyWaterSavingsTable;