import React, { useContext, useEffect, useState, useRef } from 'react';
import "../inventory/inventory.css"
import '../../App.css';
import api from '../../api/api';
import { Context } from '../../context/AuthContext';
import Select from "react-dropdown-select"
import Navbar from '../../components/Navbar';
import LoadingSpinner from '../../components/LoadingSpinner';


const NewArrivals = () => {

  const [hardware, setHardware] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [components, setComponents] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [count, setCount] = useState(0);
  const { state } = useContext(Context);
  const token = state.token;

  useEffect(() => {
    (async () => {
      const jsonRes = await fetchHardware();
      setIsLoading(false);
      setHardware(jsonRes.data);
      setComponents(mapSelectData(jsonRes.data))
    })()
  }, []);

  const fetchHardware = async () => {
    try {
        const res = await api.getHardwareItems(token);
        return res;
    } catch (e) {
        console.log(e)
    }
  };

  const mapSelectData = (data) => {
    return data.map((d) => ({
      'value': d.Id,
      'label': d.Name
    }));
  }

  const isAssembly = () => {
    const match = hardware.find((element) => {
      return (element.Id === selectedItemId && element.Name === selectedItemName)
    });

    if(match.IsAssembly) {
      return true;
    }

    return false;
  }

  const setSelection = (item) => {
    try {
      setSelectedItemId(item[0].value);
      setSelectedItemName(item[0].label);
    } catch {
      
    }
  }

  const handleSave = async (e) => {
    try {
      const dataForPost = {
          ItemId: selectedItemId,
          Name: selectedItemName,
          Count: count,
          IsAssembly: isAssembly()
      };
      setIsFail(false);
      setDisableSave(true);
      setIsLoading(true);
      const response = await api.updateInventoryCount(state.token, dataForPost);
      setDisableSave(false);
      setIsLoading(false);
      setIsSuccess(true);

    } catch (error) {
      console.log(error);
      setDisableSave(false);
      setIsLoading(false);
      setIsSuccess(false);
      setIsFail(true);
    }
  }
  
  return (
    <div className='container'>
      <Navbar category='inventory'/>
      <h1 className="sunray">New Arrivals</h1>
      <div className='content'>
          <div className='select-row'>
            <h3>Item:</h3>
            <Select options={components} onChange={(e) => setSelection(e)}/>
          </div>
          <div className='select-row'>
            <h3>Count:</h3>
            <input placeholder='0' type='number' min='0' onChange={(e) => setCount(e.target.value)}/>
          </div>
          <div className='options'>
            {isLoading === true &&
              <LoadingSpinner/>
            }
            {isLoading === false &&
              <button
              onClick={() => handleSave()}
              disabled={disableSave}
              >
                Submit
              </button>
            }
          </div>
          {
            isSuccess &&
              <p className='success-message'>Submit Successful</p>
          }
          {
            isFail &&
              <p className='fail-message'>Submit Unsuccessful</p>
          }
      </div>
    </div>
  );
};

export default NewArrivals;