import './AdminView.css';
import '../../components/Common.css'
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import { useState, useContext, useEffect } from 'react';
import api from '../../api/api';
import ReactGA from 'react-ga';


const AdminView = () => {
    const letterhead = require('../../../src/assets/images/letterhead.png');
    const navigate = useNavigate();
    const { signout, setToken, setAccountId } = useContext(Context);
    const [searchInput, setSearchInput] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const url = 'GetAllAccounts';
    const to = 'landing';
    
    const { state } = useContext(Context);
    const token = state.token;

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    useEffect(() => {
        (async () => {
          setIsLoaded(false);
          await fetchData();
        })();
      }, []);

    const handleLogout = () => {
        window.localStorage.removeItem("rememberMe");
        localStorage.clear();
        signout();
        navigate('');
    };

    const listOfAccounts = [];

    const fetchData = () => {
        api.getAccounts(url, token)
            .then((response)=>{
                response.data.forEach(element => {
                    listOfAccounts.push(element);
                });
                setData(listOfAccounts);
                setIsLoaded(true);
            })
            .catch((error) => {
                console.log(error)
            })  
    }

    const updateContextWithAccountInformation = (id) => {
        setAccountId(id);
        navigate(`/${to}`)
    }

    let updatedList = data.sort(function(a, b) {
        if(a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
        if(a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
        return 0;
       }).filter(account => {
        if (searchInput === '') {
          return account;
        } else if (account.Name.toLowerCase().includes(searchInput.toLowerCase())) {
          return account;
        }}).map((account, index)=>{
        return <li key={account.Id} onClick={()=>updateContextWithAccountInformation(account.Id)}>{account.Name}</li>;
    });

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
      };

    return (
        <div>
            <div className='letterhead-container'>
            <img
                src={letterhead}
                alt="Banyan Water Letterhead with Logo"
                className="letterhead"
            ></img>
            </div>
            <div className='search-bar-container'> 
                <input
                    type="search"
                    placeholder="Search"
                    onChange={handleChange}
                    value={searchInput} />
            </div> 
            <div className='list-of-accounts'>
                <ul>
                {updatedList}
                </ul>
            </div>
             <button onClick={handleLogout}>logout</button>
        </div>
    )
}

export default AdminView;