import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function BebbiaFlowRateGraph({ graphData }) {
    const graphLabels = {
        flowRateMax: "Tasa de flujo máxima",
    };
    const maxFlowRates = graphData.map(d => d.MaxFlowRate);
    const labels = graphData.map(d => moment(d.Date).format("DD-MMM-YY"));
    const data = {
        labels,
        datasets: [
            {
                type: 'line',
                label: graphLabels.flowRateMax,
                borderColor: '#7B9DC3',
                backgroundColor: 'rgba(123, 157, 195, 0.5)',
                borderWidth: 2,
                pointBackgroundColor: 'rgba(123, 157, 195, 0.5)',
                fill: false,
                data: maxFlowRates
            }]
    };
    
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Tasa de flujo',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            var label = tooltipItem.formattedValue || "";
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false }
      },
      y: {
        grid: { display: false }
      }
    }
  };

    return (
        graphData.length > 0 ?
          <div className='bebbia-chart'>
            <Chart options={options} data={data} />
          </div>
          : <div className='bebbia-no-data'>Sin datos</div>
      )
}
