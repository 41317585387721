import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../context/AuthContext';
import api from '../../api/api';
import Table from '../Table';
import LoadingSpinner from '../LoadingSpinner';
import moment from 'moment';

export default function BebbiaClientsTable() {
    const { state } = useContext(Context);
    const token = state.token;
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([])
    const [tableColumns, setTableColumns] = useState([]);

    useEffect(() => {
        (async () => {
            var clientsDataRes = await api.getBebbiaClientsTableData(token);
            setTableData(generateTableData(clientsDataRes.data));
        })()
    }, []);

    useEffect(() => {
        if (!tableData.length) return;
        setTableColumns([
            {
                Header: 'ID de cliente',
                accessor: 'ID de cliente', // accessor is the "key" in the data
            },
            {
                Header: 'Nombre de cliente',
                accessor: 'Nombre de cliente',
            },
            {
                Header: 'Correo electrónico',
                accessor: 'Correo electrónico',
            },
            {
                Header: 'Fecha de cumpleaños',
                accessor: 'Fecha de cumpleaños'
            },
            {
                Header: 'Edad',
                accessor: 'Edad',
            },
            {
                Header: 'Cliente desde',
                accessor: 'Cliente desde'
            },
            {
                Header: '# de purificadores',
                accessor: '# de purificadores'
            },
            {
                Header: 'Nombre de purificador',
                accessor: 'Nombre de purificador'
            },
            {
                Header: 'Fecha de envío de respuestas bebbia smart',
                accessor: 'Fecha de envío de respuestas bebbia smart'
            },
            {
                Header: 'Pregunta 1: ¿Tienes dispositivo inteligente?',
                accessor: 'Pregunta 1: ¿Tienes dispositivo inteligente?'
            },
            {
                Header: 'Pregunta 2: ¿Te gustaría tener un dispositivo bebbia inteligente?',
                accessor: 'Pregunta 2: ¿Te gustaría tener un dispositivo bebbia inteligente?'
            },
        ]);
        setIsLoading(false);
    }, [tableData])

    const generateTableData = (data) => {
        return data.map(d => ({
            'ID de cliente': d.salesforceId,
            'Nombre de cliente': d.name,
            'Correo electrónico': d.mail,
            'Fecha de cumpleaños': moment.utc(d.birthDate).format("DD/MM/YYYY"),
            'Edad': d.age,
            'Cliente desde': moment(d.fromDate).format("DD/MM/YYYY"),
            '# de purificadores': d.assetSubscriptions,
            'Nombre de purificador': d.devices,
            'Fecha de envío de respuestas bebbia smart': moment(d.surveyDate).format("DD/MM/YYYY"),
            'Pregunta 1: ¿Tienes dispositivo inteligente?': d.wantToHave ? "TRUE" : "FALSE",
            'Pregunta 2: ¿Te gustaría tener un dispositivo bebbia inteligente?': d.smartDevice ? "TRUE" : "FALSE"
        }));
    }

    return (<div className='bebbia-marketing-table-wrapper'>
        {isLoading
            ? <LoadingSpinner />
            : tableData.length > 0 &&
            <Table
                columns={tableColumns}
                defaultData={tableData}
                scrollXMax={'100%'}
                fileName={`${moment.utc().format("DDMMYYYY")} - Tabla de Informe de Marketing`}
                isES={true} />
        }
    </div>
    )
}
