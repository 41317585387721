import './Common.css';

export default function CurrencyHeader({data}){

    return( 
       <span>
           {new Intl.NumberFormat("us-US", {
               style: "currency",
               currency: "USD",
               minimumFractionDigits: 0,
               maximumFractionDigits: 0
           }).format(data)}
       </span>
    )
   }