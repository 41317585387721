import './intake.css';
import PercentFormat from '../PercentFormat';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const IntakeResults = ({ name, address, percentSavings }) => {
    const notSaved = 1 - percentSavings;

    const data = {
        labels: ['Savings with Banyan', 'Irrigation Requirement'],
        datasets: [
          {
            data: [percentSavings, notSaved],
            backgroundColor: [
              'rgba(150, 164, 128, 0.4)',
              'rgba(64, 92, 154, 0.3)'
            ],
            borderColor: [
              'rgba(150, 164, 128, 1)',
              'rgba(64, 92, 154, 1)'
            ],
            borderWidth: 0.5,
          },
        ],
      };

    let opt = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(tooltipItem) {
                        const percent = tooltipItem.raw * 100;
                        const label = tooltipItem.label;
                        return percent + '% ' + label;
                      }
                }
            }
        }
    }

    return <div className='intake-results'>
        <h2>{name}</h2>
        <h3>{address}</h3>
        <div className="intake-display-stats">
            <h3 style={{paddingBottom: '0px', marginBottom: '0px'}} >Potential water savings of:</h3>
            <span className='sage-text' style={{ padding: '8px', marginTop: '0',  fontSize: '48pt'}}><PercentFormat data={percentSavings}/></span>
            <Doughnut data={data} options={opt} />
        </div>
    </div>;
};

export default IntakeResults;