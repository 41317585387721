import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../context/AuthContext';
import { useNavigate, useLocation } from "react-router-dom";
import BebbiaDropdowns from '../../components/bebbia/BebbiaDropdowns';
import BebbiaWaterConsumptionGraph from '../../components/bebbia/BebbiaWaterConsumptionGraph';
import BebbiaTDSAverageGraph from '../../components/bebbia/BebbiaTDSAverageGraph';
import api from '../../api/api';
import "./Bebbia.css";
import LoadingSpinner from '../../components/LoadingSpinner';
import BebbiaExportCSVButton from '../../components/bebbia/BebbiaExportCSVButton';
import moment from 'moment';
import BebbiaFlowRateGraph from '../../components/bebbia/BebbiaFlowRateGraph';
import BebbiaPurificationTable from '../../components/bebbia/BebbiaPurificationTable';

export default function BebbiaPurification() {
    const { state } = useContext(Context);
    const letterhead = require('../../../src/assets/images/letterhead.png');
    const navigate = useNavigate();
    const location = useLocation();
    const accountId = state.accountId;
    const token = state.token;

    const [waterConsumptionData, setWaterConsumptionData] = useState(null);
    const [isLoadingWaterConsumption, setIsLoadingWaterConsumption] = useState(false);
    const [tdsData, setTdsData] = useState(null);
    const [isLoadingTDSAverage, setIsLoadingTDSAverage] = useState(false);
    const [flowRateData, setFlowRateData] = useState(null)
    const [isLoadingFlowRate, setIsLoadingFlowRate] = useState(false);

    const [deviceOptions, setDeviceOptions] = useState([]);
    const [isLoadingDeviceOptions, setIsLoadingDeviceOptions] = useState(true);
    const [isLoadingTableData, setIsLoadingTableData] = useState(false);
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        import('moment/locale/es').then(() => {
            moment.locale('es');
        });

        return () => {
            moment.locale('en');
        }
    })

    useEffect(() => {
        (async () => {
            const res = await api.getBebbiaDevicesList(token, accountId);
            setDeviceOptions(res.data);
            setIsLoadingDeviceOptions(false);
        })()
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleBack = () => {
        navigate('/bebbia');
    };

    const sendTableRequest = (options) => {
        setIsLoadingTableData(true);
        const requests = [
            api.getBebbiaDailyTDSAverages(token, options),
            api.getBebbiaDailyWaterConsumptions(token, options),
            api.getBebbiaDailyFlowRates(token, options)
        ];

        Promise.all(requests)
            .then(res => {
                const results = res.map(res => res.data);
                setTableData(createTableData(results));
                setIsLoadingTableData(false);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const createTableData = (resData) => {
        const headers = ["Días seleccionados", ...resData[0].map(d => moment(d.Date).local('es').format("DD-MMM-YY"))];
        const waterConsumptions = ["Consumo de agua (por día)", ...resData[1].map(d => d.DailyWaterConsumption)];
        const tdsIns = ["Promedio TDS (i) (por día)", ...resData[0].map(d => d.WaterQuality.TDSInAverage)];
        const tdsOuts = ["Promedio TDS (o) (por día)", ...resData[0].map(d => d.WaterQuality.TDSOutAverage)];
        const tdsDeltas = ["Promedio Delta TDS (por día)", ...resData[0].map(d => d.WaterQuality.Delta)];
        const maxflowRates = ["Tasa de flujo máxima (por día)", ...resData[2].map(d => d.MaxFlowRate)];
        const filename = `Desde ${headers[1]} hasta ${headers[headers.length - 1]} Purificación.csv`;
        return { headers, data: [waterConsumptions, tdsIns, tdsOuts, maxflowRates, tdsDeltas], filename };
    }

    const sendTDSRequest = async (options) => {
        setIsLoadingTDSAverage(true);
        var res = await api.getBebbiaDailyTDSAverages(token, options);
        setTdsData(res.data);
        setIsLoadingTDSAverage(false);
    }

    const sendFlowRateRequest = async (options) => {
        setIsLoadingFlowRate(true);
        var res = await api.getBebbiaDailyFlowRates(token, options);
        setFlowRateData(res.data);
        setIsLoadingFlowRate(false);
    }

    const sendWaterConsumptionRequest = async (options) => {
        setIsLoadingWaterConsumption(true);
        var res = await api.getBebbiaDailyWaterConsumptions(token, options);
        setWaterConsumptionData(res.data);
        setIsLoadingWaterConsumption(false)
    }

    return (<>
        <div className='letterhead-container'>
            <img
                src={letterhead}
                alt="Banyan Water Letterhead with Logo"
                className="letterhead"
            ></img>
        </div>
        <div className="back-button-container">
            {<button onClick={handleBack}>Volver</button>}
        </div>

        <BebbiaPurificationTable />

        <div className='bebbia-graph-container'>
            <div className='bebbia-purification-graph-title'>{"Sólidos disueltos totales (TDS)"}</div>
            {isLoadingDeviceOptions
                ? <LoadingSpinner />
                : <BebbiaDropdowns handleUpdateOptions={sendTDSRequest} deviceOptions={deviceOptions} />}
            <div className='bebbia-graph-wrapper'>
                {isLoadingTDSAverage
                    ? <LoadingSpinner />
                    : tdsData !== null && <BebbiaTDSAverageGraph graphData={tdsData} />}
            </div>
        </div>

        <div className='bebbia-graph-container'>
            <div className='bebbia-purification-graph-title'>Tasa de flujo</div>
            {isLoadingDeviceOptions
                ? <LoadingSpinner />
                : <BebbiaDropdowns handleUpdateOptions={sendFlowRateRequest} deviceOptions={deviceOptions} />}
            {isLoadingFlowRate
                ? <LoadingSpinner />
                : flowRateData !== null && <BebbiaFlowRateGraph graphData={flowRateData} />}
        </div>

        <div className='bebbia-graph-container'>
            <div className='bebbia-purification-graph-title'>Consumo de agua</div>
            {isLoadingDeviceOptions
                ? <LoadingSpinner />
                : <BebbiaDropdowns handleUpdateOptions={sendWaterConsumptionRequest} deviceOptions={deviceOptions} />}
            <div className='bebbia-graph-wrapper'>
                {isLoadingWaterConsumption
                    ? <LoadingSpinner />
                    : waterConsumptionData !== null && <BebbiaWaterConsumptionGraph graphData={waterConsumptionData} />}
            </div>
        </div>


        <div className='bebbia-csv-export-container'>
            <div className='bebbia-purification-graph-title'>Exportar a CSV</div>
            {isLoadingDeviceOptions
                ? <LoadingSpinner />
                : <div>
                    <BebbiaDropdowns handleUpdateOptions={sendTableRequest} deviceOptions={deviceOptions} />
                </div>
            }
            {isLoadingTableData
                ? <LoadingSpinner />
                : tableData !== null &&
                <BebbiaExportCSVButton tableData={tableData} />}
        </div>
    </>
    )
}
