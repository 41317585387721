import React, { useState, useEffect } from 'react';
import Dropdown from '../../components/Dropdown';
import './WaterSavingsDrilldown.css';
import AddNewUserForm from '../../components/responseRatesDrilldown/AddNewUserForm';
import CustomModal from '../../components/CustomModal';
import ResponseRateTable from '../../components/responseRatesDrilldown/ResponseRateTable';

export default function ResponseRateDrilldown() {
    const [propertyOptions, setPropertyOptions] = useState([])
    const [isAddingNewUser, setIsAddingNewUser] = useState(false)

    useEffect(() => {
        (async () => {
            // let res = await fetchData()
            // if (res.success) {
            //     setTableData(res.data);
            //     setPropertyOptions(
            //         [...new Set(res.data.map((data) => data.PropertyName))]
            //             .map((ele) => ({ value: ele, label: ele }))
            //     );
            //     setIsLoading(true);
            // }
        })()
    }, []);

    const addNewUser = (userData) => {
        //TODO: send add row request
        //TODO: refresh table
        setIsAddingNewUser(false)
        return { success: true }
    }

    const userRoleOptions = [
        { value: 'CustomerLeadership', label: 'Customer Leadership' },
        { value: 'CustomerProgramManager', label: 'Customer Program Manager' },
        { value: 'CustomerOnSiteStaff', label: 'Customer On Site Staff' },
        { value: 'CustomerLandscaper', label: 'Customer Landscaper' },
    ]

    return (
        <div className='landing-screen-container-ws'>
            <Dropdown />
            <ResponseRateTable userRoleOptions={userRoleOptions} />
            <div>
                <button className='rr-btn rr-add-new-user-btn' 
                    onClick={() => setIsAddingNewUser(true)}
                >+ Add New User</button>
                <CustomModal
                    isOpen={isAddingNewUser}
                    closeModal={() => setIsAddingNewUser(false)}
                    title="Add New User">
                        <AddNewUserForm
                            userRoleOptions={userRoleOptions}
                            propertyOptions={propertyOptions}
                            addNewUser={addNewUser} />
                </CustomModal>
            </div>
        </div>
    );
}
