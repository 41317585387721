import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Radio } from 'antd';
import { useLocation } from 'react-router-dom';
import BulkProposalTable from '../../components/salesAnalysis/BulkProposalTable';
import '../../screens/salesAnalysis/BulkProposalDetail.css';

const { TabPane } = Tabs;

export default function BulkProposalDetail() {
    const location = useLocation();
    const [data, setData] = useState(location.state?.data || []);
    const [proposalTypes, setProposalTypes] = useState([]);
    const [meterSizes, setMeterSizes] = useState([]);
    const [selectedProposalType, setSelectedProposalType] = useState('');
    const [selectedMeterSize, setSelectedMeterSize] = useState('');
    const [selectedMeterKey, setSelectedMeterKey] = useState('');
    const [selectedProposals, setSelectedProposals] = useState([]);

    const initializeData = useCallback(() => {
        if (data.length) {
            const typesOfProposals = [...new Set(data.map(p => p.ProposalType))];
            const sizes = [...new Set(data.map(p => p.Proposal.MeterSizeInInches))];
            setProposalTypes(typesOfProposals);
            setMeterSizes(sizes);
            
            if (typesOfProposals.length && sizes.length) {
                setSelectedProposalType(typesOfProposals[0]);
                setSelectedMeterSize(sizes[0]);
                setSelectedMeterKey('0');
                handleFilter(typesOfProposals[0], sizes[0], '0');
            }
        }
    }, [data]);

    useEffect(() => {
        initializeData();
    }, [initializeData]);

    const handleChangeType = e => {
        const type = e.target.value;
        setSelectedProposalType(type);
        handleFilter(type, selectedMeterSize, selectedMeterKey);
    };

    const handleChangeMeterSize = key => {
        const size = meterSizes[key];
        setSelectedMeterSize(size);
        setSelectedMeterKey(key);
        handleFilter(selectedProposalType, size, key);
    };

    const handleFilter = (proposalType, meterSize, meterKey) => {
        const proposals = data
            .filter(p => p.ProposalType === proposalType && p.Proposal.MeterSizeInInches === parseFloat(meterSize))
            .map(p => p.Proposal);
        setSelectedProposals(proposals);
    };

    return (
        <div className='detail-table-container'>
            <Radio.Group value={selectedProposalType} onChange={handleChangeType}>
                {proposalTypes.map(proposalType => (
                    <Radio.Button key={proposalType} value={proposalType}>{proposalType}</Radio.Button>
                ))}
            </Radio.Group>
            
            <div className='centered-tabs'>
                <Tabs activeKey={selectedMeterKey} onChange={handleChangeMeterSize}>
                    {meterSizes.map((meterSize, index) => (
                        <TabPane tab={`Meter Size: ${meterSize}`} key={index.toString()}>
                            <BulkProposalTable data={selectedProposals} type={selectedProposalType}/>
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </div>
    );
}
