import Select from 'react-select';
import { useContext } from 'react';
import './DropdownUnits.css';
import { Context } from '../../../context/ProposalContext';

export default function DropdownUnits () {
    const { state, updateUnits } = useContext(Context);
    const billingUnits = state.billingUnits !== undefined ? state.billingUnits : 0;

    const options = [
        { value: 'Kilogallon', label: 'Kilogallon' },
        { value: 'Gallon', label: 'Gallon' },
        { value: 'CCF', label: 'CCF' },
        { value: 'CubicFeet', label: 'Cubic Feet' }
    ];

    const selectedOption = options[billingUnits];

    return(
        <div className='du-select-container'>
            <Select 
                menuPlacement='auto'
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={val => updateUnits(options.indexOf(val))}
                options={options} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 20,
                colors: {
                    ...theme.colors,
                    neutral0: '#EAF5FF',
                    neutral20: 'rgba(94, 101, 94, 1)',
                    primary: 'rgba(94, 101, 94, 1)',
                }, })} />
        </div>
    );
}
  