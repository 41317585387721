
import './Landing';
import '../../components/Common.css'
import './CarbonOffset.css';
import Dropdown from "../../components/Dropdown";
import CarbonOffsetRollup from '../../components/carbonOffset/CarbonOffsetRollup';
import ComponentWithData from '../../components/ComponentWithData';
import CarbonOffsetTable from '../../components/carbonOffset/CarbonOffsetTable';
import VirtualForest from '../../components/carbonOffset/VirtualForest';
import CustomModal from '../../components/CustomModal';
import { useState, React, useEffect } from 'react';
import ReactGA from 'react-ga';

const CarbonOffset = () => {
  const [isShowingResponses, setIsShowingResponses] = useState(false);

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  const displayModal = () => {
    setIsShowingResponses(true);
  }

  return (
    <div className='landing-screen-container-ws'>
        <br></br>
        <ComponentWithData child={CarbonOffsetRollup} url="CarbonOffsetAcrossPortfolio" />

        <Dropdown/>
        <CarbonOffsetTable url="CarbonOffsetPerProperty" />
        <ComponentWithData child={VirtualForest} url="CarbonOffsetAcrossPortfolio" />
        <div className='link-text'>
          <span className='click-here-text' onClick={displayModal}>*Click Here</span>
          <span className='view-methodology-text'> to view our methodology</span>
        </div>
        <CustomModal
          style={{ 
            overlay: 
              { width: '100vw',
                height: '100vh' }
          }}
          isOpen={isShowingResponses}
          closeModal={() => setIsShowingResponses(false)}
          title={"Carbon Offset Methodology" }
        >
          <div className='nfo-response-detail-wrapper'>{
            <div>
              Carbon offset is calculated by calculating the average carbon released into the atmosphere in the conveyance, treatment and distribution of each gallon of water saved.
            </div>}
          </div>
        </CustomModal>
      </div>
  );
}

export default CarbonOffset;