import { useContext, useEffect, useState } from 'react';
import api from "../../../api/api";
import QuestionIds from '../../../helpers/questionIds.json';
import LoadingSpinner from "../../LoadingSpinner";
import { Context } from "../../../context/ProposalContext";
import { Context as userContext } from "../../../context/AuthContext";
import InnerCellProposal from '../../InnerCellProposal';
import BillProposalDetail from './BillProposal';
import BillProposalGraph from './BillProposalGraph';

const BillProposalContainer = () => {
    const { state } = useContext(Context);
    const { state: userState } = useContext(userContext);
    const [errors, setErrors] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [markupPercentage, setMarkupPercentage] = useState(60);
    const [perZone, setPerZone] = useState(10);
    const [perZoneLabor, setPerZoneLabor] = useState(0);
    const [perVine, setPerVine] = useState(872.37);
    const [perVineLabor, setPerVineLabor] = useState(320);
    const [perExpansionBoard, setPerExpansionBoard] = useState(115.89);
    const [perSweetPea, setPerSweetPea] = useState(687.21);
    const [perSweetPeaLabor, setPerSweetPeaLabor] = useState(320);
    const [perMeter, setPerMeter] = useState(930);
    const [perMeterLabor, setPerMeterLabor] = useState(800);
    const [decoders, setDecoders] = useState(127.61);
    const [decoderLabor, setDecoderLabor] = useState(90);
    const [tucor, setTucor] = useState(1500);
    const [tucorLabor, setTucorLabor] = useState(1656.75);
    const [tucorExpansionBoard, setTucorExpansionBoard] = useState(1050);
    const [hunterACC2, setHunterACC2] = useState('');
    const [hunterACC2Labor, setHunterACC2Labor] = useState('');
    const [hunterACC2Expansion, setHunterACC2Expansion] = useState('');
    const [hunterDecoder, setHunterDecoder] = useState(106);
    const [hunterDecoderLabor, setHunterDecoderLabor] = useState(65);
    const [data, setData] = useState({});

    useEffect(() => {
        (async () => {
            try{
                const proposal = getProposalForPost();
                const dataForPost = {
                    Responses: proposal,
                    Id: state.proposalId
                };

                const response = await api.getProposal(userState.token, dataForPost);
                setData(response.data);

                const defaultCapex = await api.getGenericSalesAnalysis(userState.token, '/GetDefaultCapExValues');
                const capexData = defaultCapex.data;
                setMarkupPercentage(capexData.MarkupPercentage);
                setPerZone(capexData.ZonesCost);
                setPerMeter(capexData.MeterCost);
                setPerMeterLabor(capexData.MeterLaborCost);
                setPerVine(capexData.VinesCost);
                setPerExpansionBoard(capexData.VineExpansionsCost);
                setPerVineLabor(capexData.VineLaborCost);
                setDecoders(capexData.DecodersCost);
                setDecoderLabor(capexData.DecoderLaborCost);
                setHunterACC2(capexData.HunterACC2Cost);
                setHunterACC2Labor(capexData.HunterACC2LaborCost);
                setHunterACC2Expansion(capexData.HunterACC2ExpansionBoardsCost);
                setHunterDecoder(capexData.HunterDecoderCost);
                setHunterDecoderLabor(capexData.HunterDecoderLaborCost);
                setTucor(capexData.TucorConverterCost);
                setTucorLabor(capexData.TucorConverterLaborCost);
                setTucorExpansionBoard(capexData.TucorExpansionBoardCost);
                setPerSweetPea(capexData.SweetPeasCost);
                setPerSweetPeaLabor(capexData.SweetPeaLaborCost);
                setErrors('');
                setIsLoading(false);
            } catch (e) {
                try {
                    console.log(e);
                    setErrors(e.response.data.message);
                } catch (ex) {
                    console.log(e);
                }
            }
        })()
    }, []);

    const getProposalForPost = () => {
        return Object.keys(state.proposalAnswers).map(id => {
            let responseValue = state.proposalAnswers[id];
            if (id == QuestionIds.plantType) {
                responseValue = JSON.stringify(responseValue);
            }

            return { QuestionId: id, ResponseValue: responseValue };
        });
    };

    const handleSaveProposal = async (e) => {
        try {
            if (isNaN(markupPercentage) || markupPercentage == '') {
                alert('Margin must be a number between 30 and 70');
                return;
            }

            const margin = parseFloat(markupPercentage);

            if (margin > 70 || margin < 30) {
                alert('Margin must be between 30% and 70%');
                return;
            }

            setIsLoading(true);
            const proposal = getProposalForPost();
            const capExInputs = {
                MarkupPercentage: markupPercentage,
                ZonesCost: perZone,
                ZoneLaborCost: perZoneLabor,
                MeterCost: perMeter,
                MeterLaborCost: perMeterLabor,
                VinesCost: perVine,
                VineLaborCost: perVineLabor,
                VineExpansionsCost: perExpansionBoard,
                SweetPeasCost: perSweetPea,
                SweetPeaLaborCost: perSweetPeaLabor,
                DecodersCost: decoders,
                DecoderLaborCost: decoderLabor,
                TucorConverterCost: tucor,
                TucorConverterLaborCost: tucorLabor,
                TucorExpansionBoardCost: tucorExpansionBoard,
                HunterACC2Cost: hunterACC2,
                HunterACC2LaborCost: hunterACC2Labor,
                HunterACC2ExpansionBoardsCost: hunterACC2Expansion,
                HunterDecoderCost: hunterDecoder,
                HunterDecoderLaborCost: hunterDecoderLabor
            };
            const dataForPost = {
                Responses: proposal,
                Id: state.proposalId,
                CapExInput: capExInputs
            };
            const response = await api.saveProposal(userState.token, dataForPost);
            setData(response.data);
            setIsLoading(false);
            setErrors('');
        } catch (e) {
            try {
                console.log(e);
                setErrors(e.response.data.message);
            } catch (ex) {
                console.log(e);
            }
        }
    };

    if (isLoading) return <LoadingSpinner />;

    return (
        <>
            <BillProposalGraph data={data} />
            <BillProposalDetail data={data} />
            <div className='bill-proposal-form-button-wrapper'>
                <button className='bill-proposal-form-btn banyan-blue-box'
                        onClick={handleSaveProposal}>Save Proposal</button>
            </div>
            <h1 className='bill-title'>Update Inputs:</h1>
            <div className='widget-proposal sm common-box' >
                <table className='proposal-table'>
                    <InnerCellProposal title={"Margin:"} defaultValue={markupPercentage} action={setMarkupPercentage}/>
                    <InnerCellProposal title={"Per Zone:"} defaultValue={perZone} action={setPerZone}/>
                    <InnerCellProposal title={"Labor Per Zone:"} defaultValue={perZoneLabor} action={setPerZoneLabor}/>
                    <InnerCellProposal title={"Per Vine:"} defaultValue={perVine} action={setPerVine}/>
                    <InnerCellProposal title={"Labor Per Vine:"} defaultValue={perVineLabor} action={setPerVineLabor}/>
                    <InnerCellProposal title={"Per Meter:"} defaultValue={perMeter} action={setPerMeter}/>
                    <InnerCellProposal title={"Labor Per Meter:"} defaultValue={perMeterLabor} action={setPerMeterLabor}/>
                    <InnerCellProposal title={"Per Expansion Board:"} defaultValue={perExpansionBoard} action={setPerExpansionBoard}/>
                    <InnerCellProposal title={"Per Sweet Pea:"} defaultValue={perSweetPea} action={setPerSweetPea}/>
                    <InnerCellProposal title={"Labor Per Sweet Pea:"} defaultValue={perSweetPeaLabor} action={setPerSweetPeaLabor}/>
                    <InnerCellProposal title={"Decoders (for 2-wire systems):"} defaultValue={decoders} action={setDecoders}/>
                    <InnerCellProposal title={"Labor For Decoders:"} defaultValue={decoderLabor} action={setDecoderLabor}/>
                    <InnerCellProposal title={"Tucor Converter:"} defaultValue={tucor} action={setTucor}/>
                    <InnerCellProposal title={"Labor For Tucor Converter:"} defaultValue={tucorLabor} action={setTucorLabor}/>
                    <InnerCellProposal title={"Tucor Expansion Board:"} defaultValue={tucorExpansionBoard} action={setTucorExpansionBoard}/>
                    <InnerCellProposal title={"Hunter ACC2:"} defaultValue={hunterACC2} action={setHunterACC2}/>
                    <InnerCellProposal title={"Labor For Hunter ACC2:"} defaultValue={hunterACC2Labor} action={setHunterACC2Labor}/>
                    <InnerCellProposal title={"Hunter ACC2 Expansion:"} defaultValue={hunterACC2Expansion} action={setHunterACC2Expansion}/>
                    <InnerCellProposal title={"Hunter Decoders:"} defaultValue={hunterDecoder} action={setHunterDecoder}/>
                    <InnerCellProposal title={"Labor For Hunter Decoders:"} defaultValue={hunterDecoderLabor} action={setHunterDecoderLabor}/>
                </table>
            </div>
        </>
    );
};

export default BillProposalContainer;