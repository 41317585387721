import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import './AuditReportDetail.css'
import api from '../../api/api';
import LoadingSpinner from '../../components/LoadingSpinner';

const AuditReportDetail = () => {
    const navigate = useNavigate();
    const { auditId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [dataFetched, setDataFetched] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token || token === "") {
            navigate('/');
        } else {
            (async () => {
                try {
                    const res = await api.getAuditReport(token, auditId);
                    setData(res.data);
                } catch (e) {
                    setDataFetched(false);
                }
                setIsLoading(false);
            })();
        }
    }, []);

    const handleDownload = () => {
        const byteCharacters = atob(data.File);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${data.Subject}.xlsx`);
    }

    return isLoading ? (
        <div className='audit-report-detail-container'>
            <LoadingSpinner />
        </div>
    ) : dataFetched ? (
        <>
            <h1 className='audit-report-title'>{data.Subject}</h1>
            <div className='audit-report-detail-container'>
                <div dangerouslySetInnerHTML={{ __html: data.Body }}></div>
                <div>
                    <h3>Property Photos</h3>
                    <div className="audit-report-gallery">
                        {data.PropertyPhotos.length ? data.PropertyPhotos.map((url, index) => (
                            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                                <img src={url} alt={`Property Photo ${index + 1}`} className="audit-report-detail-photo" />
                            </a>
                        )) : <div>No property photos</div>}
                    </div>
                </div>
            </div>
            <div className='audit-report-download'>
                <a onClick={handleDownload}>Download Report</a>
            </div>
        </>
    ) : (
        <div className='audit-report-detail-container'>
            <div>Failed to get audit report. Please contact our support team for help.</div>
        </div>
    );
}

export default AuditReportDetail;