import '../Common.css' 
import CurrencyHeader from '../CurrencyHeader'

export default function CostActionImpact({leakDetectionResponseData}){

 return(   
    <div className='common-panel'>
              <span className='title'>
              <CurrencyHeader data={leakDetectionResponseData}></CurrencyHeader>
              </span>
            <span className='common-panel-text-white'> Cost Avoidance </span>
        </div>
 )
}