import React from 'react'
import Modal from 'react-modal';
import "./CustomModal.css"

export default function CustomModal({ isOpen, closeModal, title, children }) {
    const ModalCustomStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            style={ModalCustomStyles}
        >
            <div className='modal-header-wrapper'>
                <h2 className='modal-title'>{title}</h2>
                <div style={{ width: "20px" }}></div>
                <button className='modal-close-btn' onClick={closeModal}>X</button>
            </div>
            {children}
        </Modal>
    )
}
