import BillDataGraph from "../common/BillDataGraph";

const BillProposalGraph = ({ data }) => {
    if (!data?.MonthlyBaselines?.length > 0) {
        return <h1>no graph</h1>;
    }

    const baselineGraphDataset = data?.MonthlyBaselines?.map((d, i) => ({
            UsageGallons: d.Usage,
            MeterNumber: 'Baseline',
            Color: '#ff0000',
            Start: d.Start
        }));

        const projectionGraphDataset = data?.MonthlyProjections?.map(d => ({
            UsageGallons: d.Usage,
            MeterNumber: 'Projection',
            Color: '#00ff00',
            Start: d.Start
        }));

    return <BillDataGraph data={[...projectionGraphDataset, ...baselineGraphDataset]} />;
};

export default BillProposalGraph;