import './Repairs.css'
import '../Common.css'
import { useNavigate } from 'react-router-dom';

export default function Repairs({ data }) {
  const navigate = useNavigate();
  const to = 'repairs';

  return (
    <div className="repairs common-box">
      <div className='repairs-panel' onClick={() => 
        { navigate(`/${to}`) }}>
        <div>
          <div className='repairs-big-number' >
            {data.toString()}
          </div>
          <span className='big-repairs-text'>Zone Level Repairs </span>
          <br></br>
        </div>
      </div>
    </div>
  );
}