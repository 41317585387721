import { useState } from 'react';
import TextFormField from '../../components/salesAnalysis/proposalForm/TextFormField';
import './IntakeForm.css';
import post from '../../api/generic';
import IntakeResults from '../../components/intakeForm/IntakeResults';
import LoadingSpinner from '../../components/LoadingSpinner';

export default function ProposalForm() {
    const [data, setData] = useState(['', '', '', '', '']);
    const [error, setError] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [savings, setSavings] = useState(0.41);

    const onChange = (event) => {
        const id = event.target.id;
        const type = id.replace('q-', '');
        const value = event.target.value;
        switch(type){
            case 'name':
                setData([value, data[1], data[2], data[3], data[4]]);
                break;
            case 'address':
                setData([data[0], value, data[2], data[3], data[4]]);
                break;
            case 'spend':
                setData([data[0], data[1], data[2], data[3], value]);
                break;
            case 'city':
                setData([data[0], data[1], value, data[3], data[4]]);
                break;
            case 'state':
                setData([data[0], data[1], data[2], value, data[4]]);
                break;
        }
    };

    const onSubmit = async () => {
        for(var i = 0; i < data.length; i++){
            if (data[i] == '') {
                setError('Please ensure all fields have been filled out');
                return;
            }
        }

        if (isNaN(data[4])){
            setError('Please provide a number for the irrigation spend');
            return;
        }

        setShowLoading(true);

        const res = await post(data, 'SalesAnalysis/IntakeForm');

        setShowLoading(false);

        if (res.status == 200) {
            const data = res.data;
            setSavings(data);

            if (data < 0) {
                setError('Please verify the irrigation costs and address');
                return;
            }

            setShowContent(true);
        } else {
            setError("Please verify the site's address and try again");
        }
    };

    const button = showLoading ? <LoadingSpinner />
        :   <button className='intake-submit clickable-tile' onClick={onSubmit}>
                Get Savings Estimate
            </button>;

    const content = showContent ? <div>
        <button className='intake-back clickable-tile' onClick={() => setShowContent(false)}>Back</button>
        <IntakeResults 
            name={data[0]}  
            address={data[1] + ', ' + data[2] + ', ' + data[3]} 
            percentSavings={savings} /> 
        </div>
        : <div className="intake-form">
            <TextFormField question="Site Name" changeValue={onChange} id="name" />
            <TextFormField question="Street Address" changeValue={onChange} id="address" />
            <TextFormField question="City" changeValue={onChange} id="city" />
            <TextFormField question="State" changeValue={onChange} id="state" />
            <TextFormField question="Irrigation Spend" changeValue={onChange} id="spend" />
            {error != '' ? 
                    <div className='intake-error-zone'>
                    {error}
                </div>
            : null}
            {button}
        </div>;

    return (
        <div className="intake-form-wrapper">
            <div className="intake-form-banner">
                <h2 className="intake-form-header">Banyan Savings Calculator</h2>
            </div>
            {content}
        </div>
    );
}