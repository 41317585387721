export default function CurrencyFormat({data}){

 return( 
    <span>
        {new Intl.NumberFormat("us-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(data)}
    </span>
 )
}