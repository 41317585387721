import React, { useContext, useEffect, useState } from 'react';
import Navbar from "../../components/Navbar";
import './inventory.css'
import api from '../../api/api';
import { Table } from 'antd';
import { Context } from '../../context/AuthContext';
import LoadingSpinner from '../../components/LoadingSpinner';
import FormulaHelper from '../../helpers/formulaHelper';

const InventoryCount = () => {
    const [assemblyCounts, setAssemblyCounts] = useState([]);
    const [groupedComponentCounts, setGroupedComponentCounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { state } = useContext(Context);
    const token = state.token;
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name'
        },
        {
          title: 'Count',
          dataIndex: 'count'
        }
      ];

      useEffect(() => {
        (async () => {
          const jsonRes = await fetchInventoryCounts();
          setIsLoading(false);
          generateCountData(jsonRes.data);
        })()
      }, []);

      const fetchInventoryCounts = async () => {
        try {
            const res = await api.getInventoryCounts(token);
            return res;
        } catch (e) {
            console.log(e)
        }
      };

      const generateCountData = (data) => {
        let assemblyCountsData = data["AssemblyCounts"];
        let componentCountsData = data["ComponentCounts"];

        let assemblyCountsMapped = assemblyCountsData.map((d) => ({
          'name': d.Name,
          'count': d.Count
        }));

        let componentCountsMapped = componentCountsData.map((d) => ({
          'key': d.ComponentId,
          'name': d.Name,
          'count': d.Count,
          'assembly': d.ParentAssembly
        }));

        setAssemblyCounts(assemblyCountsMapped);
        setGroupedComponentCounts(FormulaHelper.groupBy(componentCountsMapped, "assembly"));
      }

  return (
    <div className='container'>
        <Navbar category='inventory'/>
        <h1 className="sunray">Inventory Count</h1>
        <div className="content">
          <h1 className='content-title'>Assemblies</h1>
          <Table dataSource={assemblyCounts} columns={columns} />
          <h1 className='content-title'>Items</h1>
            {
              Object.entries(groupedComponentCounts).map((value) =>
                <>
                  <div className='table'>
                    <h3>{value[0]}</h3>
                    <Table pagination={false} dataSource={value[1]} columns={columns} />
                  </div>
                </>
              )
            }
            {isLoading === true &&
              <LoadingSpinner/>
            }
        </div>
    </div>
  );
};

export default InventoryCount;