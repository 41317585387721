export default function PercentFormat({data}){

    return( 
       <span>
            {new Intl.NumberFormat('en-US', {
                style: "percent",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(data)}
       </span>
    )
   }