
import WaterSavingsRollup from "../../components/assetDashboardHomeIcons/WaterSavingsRollup";
import { WaterUsageGraph } from "../../components/waterSavings/WaterUsageGraph";
import './Landing.css';
import '../../components/Common.css'
import ComponentWithData from "../../components/ComponentWithData";
import Dropdown from "../../components/Dropdown";
import WaterSavingsTable from "../../components/waterSavings/WaterSavingsTable";
import { Link, useNavigate } from "react-router-dom";
import { Context } from '../../context/AuthContext';
import { useContext } from 'react';

const to = 'propertywatersavings';

const Portfolio = () => {
    const navigate = useNavigate();
    const { setPropertyId, setPropertyName } = useContext(Context); 

    const callback = (value, index, data) => {
        const property = value.original;
        setPropertyName(property.PropertyName);
        setPropertyId(property.PropertyId);
        navigate(`/${to}`);
      };

    return <div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
            <ComponentWithData style={{alignSelf: 'center'}} child={WaterSavingsRollup} url="WaterSavingsRollupPortfolioNumbers" />
        </div>
        <WaterUsageGraph callback={callback} urlEnding="WaterSavingsPortfolioGraphNumbers" />
        <Link to={'/portfolioBreakdown'}>Breakdown</Link>
        <Link to={'/portfolioLeaks'}>Leaks</Link>
        <WaterSavingsTable url="TabularPortfolioData" />
        <Dropdown/>
    </div>
};

export default Portfolio;