

const BillProposalRow = ({ title, value, numberOfValues=1, compounder=0, currency=false }) => {
    let values = [];
    for(var i = 0; i < numberOfValues; i++) {
        values.push(value);
    }
    const valueCells = values.map((v, i) => {
        const compoundingValue = i * compounder;
        if (title.includes('Rate Increase')) {
            return <td className="proposal-td">
                <div className="widget-white">{v}</div>
            </td>
        }
        v += (v * compoundingValue);

        v = title.includes('Payback') 
            ? (Math.round(v * 100) / 100) 
            : Math.round(v);

        return <td className='proposal-td'>
            <div className='widget-white'>
                {currency ? '$' : ''}
                {v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div></td>;
    });

    return (
        <tr>
            <td className='proposal-td-title'>{title}</td>
            {valueCells}
    </tr>
    );
};

export default BillProposalRow;