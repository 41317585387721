import React, { useState } from 'react'
import TextFormField from './TextFormField';

export default function InputFormField({ id, 
    question, type, defaultValue, handleChange, squareFootageBtn,
    hasError, hasEstimateBtn = false, handleEstimate = async () => { },
    getSquareFootage = async () => { } }) {
    const [value, setValue] = useState(defaultValue);

    const clickBtn = async (id) => {
        let newValue = squareFootageBtn 
            ? await getSquareFootage() 
            : await handleEstimate(id);

        setValue(newValue);
    };

    const changeValue = (e) => {
        setValue(e.target.value);
        handleChange(e);
    };

    return (<div className='proposal-form-field-wrapper' key={`q-${id}`}>
        {hasEstimateBtn && <div className='estimate-wrapper'>
            <button className="estimate-btn" onClick={() => clickBtn(id)}>Get Estimate</button>
        </div>}

        {squareFootageBtn && <div className='estimate-wrapper'>
            <button className='estimate-btn' onClick={() => clickBtn(id)}>Get Square Footage</button></div>}

        <TextFormField id={id} 
            changeValue={changeValue} 
            question={question} 
            type={type} 
            value={value} />

        {hasError && <div className='proposal-form-error'>This field has invalid input.</div>}
    </div>
    )
}
