import Papa from "papaparse";
import { useState, useEffect, useContext } from 'react';
import { Context } from "../../../context/ProposalContext";
import { Context as userContext } from "../../../context/AuthContext";
import api from "../../../api/api";
import LoadingSpinner from "../../LoadingSpinner";
import BillTable from "./BillTable";
import CustomModal from "../../CustomModal";
import AddNewBillForm from "./AddNewBillForm";
import DropdownUnits from "./DropdownUnits";
import { ImportConserviceBillForm } from "./ImportConserviceBillForm";
import QuestionIds from '../../../helpers/questionIds.json';
import SubmitButton from "../SubmitButton";
import BillDataGraph from "../common/BillDataGraph";
import { useNavigate } from 'react-router-dom';
import './BillDataInput.css';

const BillDataInput = () => {
    const navigate = useNavigate();

    const { state, updateUnits, updateBills } = useContext(Context);
    const { state: userState } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState('');
    const [isAddingBillInfo, setIsAddingBillInfo] = useState(false);
    const [isImportingFromConservice, SetIsImportingFromConservice] = useState(false);
    const [verifyingCost, setVerifyingCost] = useState(false);
  
    const [file, setFile] = useState();
    const [files, setFiles] = useState();
    const [array, setArray] = useState([]);
    const [uploadFileMessage, setUploadFileMessage] = useState();

    const fileReader = new FileReader();
  
    useEffect(() => {
      updateBills(array);
    }, [array]);
  
    useEffect(() => {
      // storing input years
      const years = [];
      array.forEach(element => {
        const str = element.End;
        const date = new Date(str);
        const year = date.getFullYear();
        years.push(year);
      });
      const uniqueYears = [];
      years.map(year => {
        if (uniqueYears.indexOf(year) === -1) {
          uniqueYears.push(year)
        }
      });
      let highestToLowest = uniqueYears.sort((a, b) => b - a)
      localStorage.setItem("uniqueYears", JSON.stringify(highestToLowest));
    }, [array]);
  
    const handleOnChange = (e) => {
      setFile(e.target.files[0]);
    };
  
    const handleFileEvent = (e) => {
      setFiles(e.target.files);
    };
  
    const csvFileToArray = string => {
      const csv = Papa.parse(string, { header: true, skipEmptyLines: true });
      var parsedData = csv?.data;
      const columns = Object.keys(parsedData[0]); 
      parsedData.forEach(element => {
        if(element.TotalCost === " " || element.TotalCost === ""){
          element.TotalCost = null;
        }
      });
      updateBillData(parsedData);
    };
  
    const loadCsvBills = (e) => {
      e.preventDefault();
  
      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          const rows = text.split("\n");
          const nonEmptyRows = rows.filter((r)=>r.replaceAll(",","").trim().length > 0);
          csvFileToArray(nonEmptyRows.join("\n"));
        };
  
        fileReader.readAsText(file);
      }
    };
  
    const scanPDFs = async (e) => {
      e.preventDefault();
      const formData = new FormData();
          for (let i = 0 ; i < files.length ; i++) {
        formData.append('files', files[i]);
      }
  
      const utilityRateId = state?.proposalAnswers['28'];
      var res = await api.uploadPDFBills(userState.token, formData, utilityRateId, state.proposalId);
      setUploadFileMessage(res.data);
  
      try {
        var res = await api.getBillInput(userState.token, state.proposalId).then(data => { return data });
        if (res.data !== undefined && res.data.length > 0) {
          const volumeIdx = res.data[0].VolumeUnits;
          updateUnits(volumeIdx);
        }
        setArray(res.data);
      } catch (e) {
        console.log(e);
      }
  
    };
    
    useEffect(() => {
        (async () => {
          try {
            var res = await api.getBillInput(userState.token, state.proposalId).then(data => { return data });
            if (res.data !== undefined && res.data.length > 0) {
              const volumeIdx = res.data[0].VolumeUnits;
              updateUnits(volumeIdx);
            }
            
            updateBillData(res.data);
          } catch (e) {
            console.log(e);
          }
          // temp solution to solve recursive form fields rendering delay issue
          setTimeout(() => setIsLoading(false), 600);
        })()
      }, []);
    
    const updateBillData = (data) => {


        setArray(data);
    };
  
    const generateBillInput = () => {
      const units = state.billingUnits === undefined ? 0 : state.billingUnits;
      array.forEach(bill => {
        if(bill.TotalCost === " " || bill.TotalCost === ""){
          bill.TotalCost = null;
        }
      });
      return {
        ProposalId: state.proposalId,
        VolumeUnits: units,
        Bills: array,
        UtilityRateId: state.proposalAnswers[QuestionIds.utilityRate]
      };
    };

    const handleSaveAsDraft = async (e) => {
        const proposalBillInfo = generateBillInput();
        e.preventDefault();
        try {
          await api.saveBillInputDraft(userState.token, proposalBillInfo);
          navigate('/sales');
        } catch (e) {
          console.log(e);
        }
      };
    
      const handleSubmit = async (e) => {
        const proposalBillInfo = generateBillInput();
        e.preventDefault();
        try {
          setVerifyingCost(true);
          const result = await api.verifyBillData(userState.token, proposalBillInfo);
          setVerifyingCost(false);
          if (result.data) {
            navigate('/sales/billproposal', { state:{ Status: "complete"}});
          } else {
            navigate('/sales/billverification');
          }
        } catch (e) {
          setVerifyingCost(false);
          try {
            setErrors(e.response.data.ExceptionMessage);
          } catch (ex) {
            console.log(ex);
          }
        }
      };

    const addBillInfo = (userData) => {
        if (userData.MeterNumber !== '') {
          updateBillData(array.concat(userData));
        }
        setIsAddingBillInfo(false);
        return { success: true };
      };
    
      const handleClearBillInfo = async (e) => {
        setArray([]);
      };
    
      const updateMyData = (rowIndex, columnId, value) => {
        setArray(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        )
      };

    const importFromConservice = (data) => {
        updateBillData(array.concat(data));
        SetIsImportingFromConservice(false);
      };
    
      const errorComponent = errors != '' ? <div className='errors'>{errors}</div> : null;
    
      const submit = verifyingCost 
        ? <LoadingSpinner /> 
        : <SubmitButton handleClick={handleSubmit} />;
    
        const line = array.length > 1
        ?  <BillDataGraph data={array} /> 
        : null;

    return (
      <>
      <div className='dropdownunits-container'>
          <DropdownUnits />
      </div>
      <div className='bill-data-button-style'>
        <form>
            <label className='upload file'>
            <input
              type={"file"}
              id={"fileUpload"}
              multiple
              accept={".pdf"}
              onChange={handleFileEvent}
            />
          </label>
          <button
            className='proposal-form-btn'
            onClick={(e) => scanPDFs(e)}>
              Import PDFs
          </button>
        </form>
      </div>
      <div className='bill-data-button-style'>
      {uploadFileMessage}
      </div>
      <div  className='bill-data-button-style'>
        <form>
          <label className='upload file'>
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
          </label>

          <button
            className='proposal-form-btn'
            onClick={(e) => loadCsvBills(e)}>
              Import CSV
          </button>
        </form>
      </div>
      <br />
      <div className='table-container'>
      <BillTable data={array} updateMyData={updateMyData}></BillTable>
      </div>
      <div className="bill-data-input-bottom-section">
        {errorComponent}
        {isLoading ? <div className="proposal-form-loading-wrapper"><LoadingSpinner /> </div> :
          <div className='bill-data-input-buttons'>
            <button
              className='rr-btn rr-add-new-user-btn'
              onClick={() => setIsAddingBillInfo(true)}>+ Add New Bill Info</button>
            <CustomModal
              isOpen={isAddingBillInfo}
              closeModal={() => setIsAddingBillInfo(false)}
              title="Add New Bill Info">
              <AddNewBillForm
                addBillInfo={addBillInfo} />
            </CustomModal>
            <button
              className='rr-btn rr-add-new-user-btn'
              onClick={() => SetIsImportingFromConservice(true)}>+ Import From Conservice</button>
            <CustomModal
              isOpen={isImportingFromConservice}
              closeModal={() => SetIsImportingFromConservice(false)}
              title="Import Meter Number">
              <ImportConserviceBillForm
                propertyName={state.proposalAnswers[QuestionIds.name]}
                accountName={state.proposalAnswers[QuestionIds.address]}
                importFromConservice={importFromConservice} />
            </CustomModal>
            <button className='rr-btn rr-add-new-user-btn'
              onClick={handleClearBillInfo}>Clear Bill Info</button>
            <div className='proposal-form-btn proposal-form-draft-btn'
              onClick={handleSaveAsDraft}>Save As Draft</div>
            {submit}
          </div>
        }
      </div>
      {line}      
      </>  
    );
};

export default BillDataInput;