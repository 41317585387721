import LoadingSpinner from './LoadingSpinner'
import './Common.css' 
import NumberHeader from './NumberFormat'

export default function GallonsSaved({waterSavingsResponseData}){

 return( 
    <div className='common-panel'>
        <span className='water-drop-title'>
        <NumberHeader data={waterSavingsResponseData}></NumberHeader>
            </span>
        <span className='common-panel-text'> Gallons Saved</span>
    </div>
 )
}