import './Common.css'; 
import './Footer.css';
import ReactToPrint from 'react-to-print';

export default function Footer({componentRef}){
 return( 
  <table className='footer'>
  {/* footer */}
  <tbody>
    <tr>
      <td align="center">
        <table>
          {/* logo */}
          <tbody>
            <tr>
              <td align="center">
                <img
                  src="https://portal.banyanwater.com/Content/img/Banyan/banyan_square.png"
                  alt="Banyan Water Square Logo"
                  style={{ height: 105, width: 105 }}
                />
              </td>
            </tr>
            {/* phone number */}
            <tr>
              <td
                align="center"
                style={{
                  fontFamily: "sans-serif",
                  color: "#2C3E50",
                  fontStyle: "italic"
                }}
              >
                800-276-1507
              </td>
            </tr>
            {/* address */}
            <tr>
              <td
                align="center"
                style={{
                  fontFamily: "sans-serif",
                  color: "#2C3E50",
                  fontStyle: "italic"
                }}
              >
                P.O. Box 81635, Austin TX, 78708
              </td>
            </tr>
            {/* social media logos */}
            <tr>
              <td align="center">
                <table cellPadding={15}>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="https://portal.banyanwater.com/Content/img/Banyan/twitter_icon.png"
                          alt="Banyan Water Square Logo"
                          style={{ height: 18, width: 18 }}
                        />
                      </td>
                      <td>
                        <img
                          src="https://portal.banyanwater.com/Content/img/Banyan/facebook_icon.png"
                          alt="Banyan Water Square Logo"
                          style={{ height: 18, width: 18 }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center">
                <table cellPadding={15}>
                  <tbody>
                    <tr>
                    <ReactToPrint
                    trigger={() => <button className="download-button">Download PDF</button>}
                    content={() => componentRef.current}
                    pageStyle="@page { size: 23in 12in }"
                    />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

 )
}