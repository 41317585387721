import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import BebbiaTDSBarChart from '../../components/bebbia/BebbiaTDSBarChart';
import BebbiaCartridgeBarChart from '../../components/bebbia/BebbiaCartridgeBarChart';
import BebbiaPortfolioTable from '../../components/bebbia/BebbiaPortfolioTable';

export default function BebbiaOperations() {
  const letterhead = require('../../../src/assets/images/letterhead.png');
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCartridgeDate1, setSelectedCartridgeDate1] = useState(null);
  const [selectedCartridgeDate2, setSelectedCartridgeDate2] = useState(null);
  const [selectedTDSDate1, setSelectedTDSDate1] = useState(null);
  const [selectedTDSDate2, setSelectedTDSDate2] = useState(null);
  const [isTDSNumber, setIsTDSNumber] = useState(true);
  const [isCartridgeNumber, setIsCartridgeNumber] = useState(true);
  registerLocale('es', es);

  useEffect(() => {
    import('moment/locale/es').then(() => {
      moment.locale('es');
    });

    return () => {
      moment.locale('en');
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleBack = () => {
    navigate('/bebbia');
  };

  const handleTDSToggle = (isNumber) => {
    setIsTDSNumber(isNumber);
  }

  const handleCartridgeToggle = (isNumber) => {
    setIsCartridgeNumber(isNumber);
  }

  const handleSelectDate = (date) => {
    setSelectedDate(date);
    setSelectedTDSDate1(moment(date).subtract(1, 'day'));
    setSelectedTDSDate2(moment(date));
    setSelectedCartridgeDate1(moment(date).subtract(1, 'day'));
    setSelectedCartridgeDate2(moment(date));
  }

  return (<>
    <div className='letterhead-container'>
      <img
        src={letterhead}
        alt='Banyan Water Letterhead with Logo'
        className='letterhead'
      ></img>
    </div>
    <div className='back-button-container'>
      {<button onClick={handleBack}>Volver</button>}
    </div>

    <div className='bebbia-operations-content-wrapper'>
      <div className='bebbia-operations-section-title'>Tabla de Informe de Portafolio</div>
      <BebbiaPortfolioTable />

      <div className='bebbia-operations-section-title'>Gráficos de Informe de Portafolio</div>
      <div className='bebbia-operations-datepicker-wrapper'>
        <DatePicker
          maxDate={new Date()}
          className="bebbia-operations-datepicker"
          selected={selectedDate}
          placeholderText='Seleccionar fecha...'
          onChange={(date) => handleSelectDate(date)}
          locale="es"
          dateFormat="dd/MM/yyyy" />
      </div>

      <div className='bebbia-operations-graph-container'>
        {selectedDate &&
          <>
            <div className='bebbia-operations-graph-wrapper'>
              <div className='bebbia-operations-title-wrapper'>
                <div className='bebbia-graph-title'>{"% Vida remanente de los filtros"}</div>
                <div className='bebbia-operations-graph-toggles-wrapper'>
                  <div
                    className={`bebbia-operations-toggle ${isCartridgeNumber ? 'active' : ''}`}
                    onClick={() => handleCartridgeToggle(true)}>#s</div>
                  <div
                    className={`bebbia-operations-toggle ${isCartridgeNumber ? '' : 'active'}`}
                    onClick={() => handleCartridgeToggle(false)}>%</div>
                </div>
              </div>
              <div className='bebbia-operations-graphs'>
                <BebbiaCartridgeBarChart date={selectedCartridgeDate1} position='left' isNumber={isCartridgeNumber} />
                <BebbiaCartridgeBarChart date={selectedCartridgeDate2} position='right' isNumber={isCartridgeNumber} />
              </div>

            </div>
          </>}
      </div>

      <div className='bebbia-operations-graph-container'>
        {selectedDate &&
          <>
            <div className='bebbia-operations-graph-wrapper'>
              <div className='bebbia-operations-title-wrapper'>
                <div className='bebbia-graph-title'>{"Resultados de TDS (salida)"}</div>
                <div className='bebbia-operations-graph-toggles-wrapper'>
                  <div
                    className={`bebbia-operations-toggle ${isTDSNumber ? 'active' : ''}`}
                    onClick={() => handleTDSToggle(true)}>#s</div>
                  <div
                    className={`bebbia-operations-toggle ${isTDSNumber ? '' : 'active'}`}
                    onClick={() => handleTDSToggle(false)}>%</div>
                </div>
              </div>
              <div className='bebbia-operations-graphs'>
                <BebbiaTDSBarChart date={selectedTDSDate1} position='left' isNumber={isTDSNumber} />
                <BebbiaTDSBarChart date={selectedTDSDate2} position='right' isNumber={isTDSNumber} />
              </div>
            </div>
          </>
        }
      </div>
    </div>
  </>
  )
}
