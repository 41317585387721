import "./BulkPropertyDetail.css"
import React, { useContext } from "react"
import { useState } from "react";
import { Context as userContext } from '../../context/AuthContext';
import { Context } from '../../context/ProposalContext';
import InnerCellProposal from "../../components/InnerCellProposal";
import api from "../../api/api";
import { useNavigate } from "react-router";

export default function BulkPropertyDetail() {
    const { state } = useContext(userContext);
    const { state: proposalState } = useContext(Context);
    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const [data, setData] = useState({});

    const [margin, setMargin] = useState(0);
    const [perZone, setPerZone] = useState(0);

    const handleRegenerateProposal = async (e) => {
        try {
            const dataForPost = {
                Id: proposalState.proposalId,
                Margin: margin,
                CostPerZone: perZone
            };
            const response = await api.updateBulkProposalInputs(state.token, dataForPost);
            setData(response.data);
            setErrors('');
        } catch (e) {
            try {
                console.log(e);
                setErrors(e.response.data.Message);
            } catch (ex) {
                console.log(e);
            }
        }
    };

    const errorComponent = errors != '' ? <div className='errors'>{errors}</div> : null;

    return (
        <div>
            <div className="sales-home-container">
                <h2 className="sales-title">{state.propertyName}</h2>
                <div className="proposal-form-container"></div>
            <h1 className='bill-title'>Update Inputs:</h1>
            <div className='widget-proposal sm common-box' >
                <table className='proposal-table'>
                    <InnerCellProposal title={"Margin:"} defaultValue={margin} action={setMargin}/>
                    <InnerCellProposal title={"Cost Per Zone:"} defaultValue={perZone} action={setPerZone}/>
                </table>
            </div>
            {errorComponent}
            <div className='bill-proposal-form-button-wrapper'>
                <button className='bill-proposal-form-btn banyan-blue-box'
                    onClick={handleRegenerateProposal}>Regenerate Line Item</button>
            </div>
            </div>
        </div>
    )
}
