import Dropdown from "../../components/Dropdown";
import Table from "../../components/Table";
import NumberFormat from '../../components/NumberFormat';
import CurrencyFormat from '../../components/CurrencyFormat';

const PortfolioLeaks = () => {
    const tableSortees = [{
        id: "PropertyName",
        desc: false
      }];
    
      const tableColumns = [
        {
          Header: 'Account',
          accessor: 'Account',
          Cell: ({ row: { index }, value }) => 
            <div>{value}</div>
        },
          {
            Header: 'Property',
            accessor: 'PropertyName',
            Cell: ({ row: { index }, value }) => 
            <div>{value}</div>  // accessor is the "key" in the data
          },
          {
            Header: 'Total Leaks Detected',
            accessor: 'Count',
            Cell: ({ row: { index }, value }) => 
              <NumberFormat data={value}></NumberFormat>
          },
          {
            Header: 'Cost Avoidance',
            accessor: 'CostAvoidance',
            Cell: ({ row: { index }, value }) => 
              <CurrencyFormat data={value}></CurrencyFormat> 
          },
          {
            Header: 'Gallon Avoidance',
            accessor: 'GallonAvoidance',
            Cell: ({ row: { index }, value }) => 
              <NumberFormat data={value}></NumberFormat>
          }
        ];

    return <div>
        <Table url="PortfolioLeakData"
            title='Portfolio Leak Report'
            columns={tableColumns} 
            sortees={tableSortees} 
            //callback={callback} 
        />
        <Dropdown />
    </div>
};

export default PortfolioLeaks;