import './LeakDetection.css'
import '../Common.css'
import { useNavigate } from 'react-router-dom';

export default function LeakDetection({ data }) {
  const to = 'leakinsights';
  const totalLeaks = data.ActiveLeaks + data.ResolvedLeaks;
  const navigate = useNavigate();

  return (
    <span className="leak-detection common-box" onClick={() => 
        { navigate(`/${to}`) }}>
          <div className='leak-panel'>
            <span className='leak-title'>
              {totalLeaks}
            </span>
            <span className='big-leak-text'> Leaks Detected</span>
          </div>
          <div className='leak-panel active-leak-text'>
                    <li>
                        {data.ActiveLeaks} Active
                    </li>
                    <li>
                        {data.ResolvedLeaks} Resolved
                    </li>
          </div>
          <div className='leak-panel leak-text'>
          {new Intl.NumberFormat("us-US", {
               style: "currency",
               currency: "USD",
               minimumFractionDigits: 0,
               maximumFractionDigits: 0
           }).format(data.CostSavings)} in water cost avoidance
            </div>
        </span>
  );
}
