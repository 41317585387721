
import '../Common.css' 
import NumberHeader from '../NumberHeader'

export default function WaterSavingsPercent({waterSavingsResponseData}){

 return(   
    <div className='common-panel'>
              <span className='water-drop-title'>
              <NumberHeader data={waterSavingsResponseData}></NumberHeader>%
              </span>
              <span className='common-panel-text'> Total Water Savings</span>
    </div>
 )
}