import { useState, useEffect, useContext } from 'react';
import { Context } from '../../context/ProposalContext';
import { Context as userContext } from '../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../api/api';
import LoadingSpinner from '../../components/LoadingSpinner';
import QuestionIds from '../../helpers/questionIds.json';
import "./ProposalForm.css";
import FormField from '../../components/salesAnalysis/proposalForm/FormField';
import SubmitButton from '../../components/salesAnalysis/SubmitButton';

export default function ProposalForm() {
    const navigate = useNavigate();
    const location = useLocation();

    const { updateErrors, updateProposalAnswers, updateProposalId, state } = useContext(Context);
    const { state: userState } = useContext(userContext);

    const [formErrorsFields, setFormErrorsFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [categorizedFields, setCategorizedFields] = useState([]);
    const [hasUpdate, setHasUpdate] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        (async () => {
            const jsonRes = await fetchQuestions();
            // render draft data: location.state is "draft"
            if (location.state !== null) {
                await updateFromId(location.state.Id, jsonRes);
            } else { // render new proposal: location.state is null
                updateProposalAnswers(getInitAnswers(jsonRes));
            }
            updateErrors([]);
            createFieldComponents(jsonRes);
            setIsLoading(false);
        })()
    }, []);

    const getInitAnswers = (jsonRes) => {
        let initAnswers = {};
        jsonRes.forEach((e, i) => {
            if (e.Metadata && e.Metadata.type === 'date') {
                initAnswers[i + 1] = null;
            } else if (e.Metadata && e.Metadata.type === 'multichoice') {
                initAnswers[i + 1] = [];
            } else {
                initAnswers[i + 1] = "";
            }
        });
        return initAnswers;
    }

    const updateFromId = async (proposalId, jsonRes) => {
        const res = await api.getProposalDraft(userState.token, proposalId);
        const formResponses = getInitAnswers(jsonRes);
        res.data.Responses.forEach((e) => {
            let qId = parseInt(e.QuestionId);
            if (qId === QuestionIds.plantType) {
                formResponses[qId] = JSON.parse(e.ResponseValue);
            } else {
                formResponses[qId] = e.ResponseValue;
            }
        })
        updateProposalId(res.data.Id);
        updateProposalAnswers(formResponses);
    };

    const fetchQuestions = async () => {
        try {
            const res = await api.getGenericSalesAnalysis(userState.token, '/GetPropertyInfoQuestions');
            const jsonRes = res.data.map((d) => {
                let meta = JSON.parse(d.Metadata);

                if (d.Id === QuestionIds.utility)
                    meta.choices = meta.choices.map((c) => JSON.parse(c));

                return { ...d, Metadata: meta };
            })
            return jsonRes
        } catch (e) {
            console.log(e)
        }
    };

    const createFieldComponents = (questionInputs) => {
        const fields = [];
        questionInputs.forEach((data) => {
            const id = data['Id'];
            const question = data['Question'];
            const metadata = data['Metadata'];
            const fieldComponent = <FormField id={id} question={question}
                metadata={metadata} setHasUpdate={setHasUpdate} />;

            fields.push({ component: fieldComponent, category: metadata.category });
        });

        const entryOrder = ['Property Information', 'System Information', 'Landscape Information',
            'Meter Information', 'Controller Information', 'Billing Information', 'Usage Information'];
        const orderEntry = (p1, p2) => {
            return entryOrder.indexOf(p1[0]) > entryOrder.indexOf(p2[0]) ? 1 : -1;
        }

        let categorizedFields = {}

        fields.forEach((f) => {
            if (!categorizedFields[f.category]) {
                categorizedFields[f.category] = [f.component]
            } else {
                categorizedFields[f.category].push(f.component)
            }
        })

        categorizedFields = Object.entries(categorizedFields).sort(orderEntry);
        const meterFields = categorizedFields[entryOrder.indexOf("Meter Information")][1];
        const lastField = meterFields.pop();
        meterFields.splice(1, 0, lastField);
        setCategorizedFields(categorizedFields);
    };

    const handleSaveAsDraft = async () => {
        const validated = validate();
        if (!validated) return;
        const proposal = generateProposal("draft");
        try {
            await api.saveProposalDraft(userState.token, proposal);
            updateProposalId(null);
            navigate('/sales');
        } catch (e) {
            console.log(e);
        }
    };

    const validate = () => {
        let valid = true;

        let formErrorCopy = state.formErrors;
        let formErrorFieldsCopy = formErrorsFields;

        if (state.proposalAnswers[QuestionIds.utility] === '') {
            if (!state.formErrors.includes(QuestionIds.utility)) {
                formErrorCopy.push(QuestionIds.utility);
                formErrorFieldsCopy.push('Utility Provider');
            }

            valid = false;
        } else {
            formErrorCopy = formErrorCopy.filter(x => x !== QuestionIds.utility);
            formErrorFieldsCopy = formErrorFieldsCopy.filter(x => x !== 'Utility Provider');
        }

        if (state.proposalAnswers[QuestionIds.utilityRate] === '') {
            if (!state.formErrors.includes(QuestionIds.utilityRate)) {
                formErrorCopy.push(QuestionIds.utilityRate);
                formErrorFieldsCopy.push('Utility Rate');
            }

            valid = false;
        } else {
            formErrorCopy = formErrorCopy.filter(x => x !== QuestionIds.utilityRate);
            formErrorFieldsCopy = formErrorFieldsCopy.filter(x => x !== 'Utility Rate');
        }

        updateErrors(formErrorCopy);
        setFormErrorsFields(formErrorFieldsCopy);

        return valid;
    };

    const handleClick = async () => {
        const validated = validate();
        if (!validated) return;

        try {
            const proposal = generateProposal("draft");
            let res = await api.saveProposalDraft(userState.token, proposal);
            const failedQuestions = res.data.QuestionIds;
            if (failedQuestions !== undefined && failedQuestions.length > 0) {
                let formErr = state.formErrors;
                failedQuestions.forEach(q => formErr.push(q));
                updateErrors(formErr);
                const fieldNames = res.data.FieldNames;
                let formErrFields = formErrorsFields;
                fieldNames.forEach(fn => formErrFields.push(fn));
                setFormErrorsFields(fieldNames);
                return;
            } else {
                updateProposalId(res.data.ProposalId);
            }
            navigate('/sales/billinput');
        } catch (e) {
            try {
                const error = e.response.data.message;
                let formErr = state.formErrors;
                formErr.push(error);
                updateErrors(formErr);
            } catch (ex) {
                console.log(ex);
            }
            console.log(e)
        }
    };

    const generateProposal = (status) => {
        const responses = [];
        for (let [k, v] of Object.entries(state.proposalAnswers)) {
            let numId = parseInt(k);
            if (numId === QuestionIds.plantType) {
                responses.push({ QuestionId: numId, ResponseValue: JSON.stringify(v) });
            } else {
                responses.push({ QuestionId: numId, ResponseValue: v });
            }
        }
        let id = null;

        if (location.state != null) {
            id = location.state.Id;
        }

        return {
            Id: id,
            PropertyName: state.proposalAnswers[QuestionIds.name],
            Status: status,
            Responses: responses
        };
    };

    if (isLoading)
        return <div className="proposal-form-loading-wrapper"><LoadingSpinner /></div>;

    let displayErrors = null;
    if (formErrorsFields.length > 0) {
        const innerFields = formErrorsFields.map(ef => <li>{ef}</li>);
        displayErrors = <div className='errors'>
            <h4>Missing inputs for the following fields:</h4>
            <ul>{innerFields}</ul></div>;
    }

    return (
        <div className="proposal-form-container">
            <div>
                {categorizedFields.map((field, i) => <div key={`title-${i}`}>
                    <h2 className="sales-title" >{field[0]}</h2>
                    <section>
                        {field[1]}
                    </section>
                </div>)}
                {displayErrors}
                <div className='proposal-form-button-wrapper'>
                    <div className='proposal-form-btn proposal-form-draft-btn'
                        onClick={handleSaveAsDraft}
                    >Save As Draft</div>
                    <SubmitButton handleClick={handleClick} />
                </div>
            </div>
        </div>
    )
}