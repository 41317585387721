import React, { useContext, useEffect, useState } from 'react';
import Navbar from "../../components/Navbar";
import './inventory.css'
import api from '../../api/api';
import Select from "react-dropdown-select"
import { Context } from '../../context/AuthContext';
import LoadingSpinner from '../../components/LoadingSpinner';
import FetchInventoryHelper from '../../helpers/fetchInventoryHelper'

const ReturnForm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isControllerButton, setIsControllerButton] = useState(false);
    const [isWaterSourceButton, setIsWaterSourceButton] = useState(false);
    const [controllersOrSources, setControllersOrSources] = useState([]);
    const [controllerOrSourcesLabel, setControllersOrSourcesLabel] = useState(null);
    const [selectedControllersOrSources, setSelectedControllersOrSources] = useState(null);
    const [components, setComponents] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [errors, setErrors] = useState({})
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const { state } = useContext(Context);
    const token = state.token;
    const helper = new FetchInventoryHelper(token);

    useEffect(() => {
        (async () => {
            const jsonResProperties = await helper.fetchProperties();
            setProperties(jsonResProperties);

            setIsLoading(false);
        })()
      }, []);

    const handlePropertySelected = (selectedProperty) => {
        setSelectedProperty(selectedProperty);
        refresh();
    }

    const refresh = () => {
        setSelectedControllersOrSources(null);
        setControllersOrSourcesLabel(null);
        setSelectedComponent(null);
        setIsControllerButton(false);
        setIsWaterSourceButton(false);
        setComponents([]);
        setControllersOrSources([]);
    }

    const handleControllerOrSourceButtonOption = async (controllerOrSourceOption) => {
        if (selectedProperty != null) {
            if(controllerOrSourceOption === 'Controller') {
                setIsControllerButton(true);
                setIsWaterSourceButton(false);
                setControllersOrSourcesLabel(controllerOrSourceOption);
                setIsLoading(true);
                setControllersOrSources(await helper.fetchControllersOrSources(controllerOrSourceOption, selectedProperty));
                setIsLoading(false);
            }
    
            if(controllerOrSourceOption === 'WaterSource') {
                setIsWaterSourceButton(true);
                setIsControllerButton(false);
                setControllersOrSourcesLabel('Water Source');
                setIsLoading(true);
                setControllersOrSources(await helper.fetchControllersOrSources(controllerOrSourceOption, selectedProperty));
                setIsLoading(false);
            }
        }
    }

    const handleControllerOrSourceSelected = async (controllerOrSource) => {
        if(controllerOrSource != null) {
            setSelectedControllersOrSources(controllerOrSource);
            setIsLoading(true);
            setComponents(await helper.fetchComponents(controllerOrSourcesLabel, controllerOrSource));
            setIsLoading(false);
        }
    }

    const handleSubmitReturn = async () => {
        try {
            if(formValidation()) {
                const inventoryItemId = selectedComponent;
                setIsLoading(true);
                const response = await api.returnItem(state.token, inventoryItemId);
                setIsLoading(false);
                setIsSuccess(true);
                refresh();
            }
        }

        catch(error) {
            console.log(error);
            setIsLoading(false);
            setIsFail(true);
        }
    }

    const formValidation = () => {
        setIsFail(false);
        setIsSuccess(false);
        let newErrors = []
        let formIsValid = true;

        if (selectedProperty === null) {
            newErrors.push('Please select a property')
            formIsValid = false;
        }

        if (selectedControllersOrSources === null) {
            newErrors.push('Please select a controller or water source')
            formIsValid = false;
        }

        if (selectedComponent === null) {
            newErrors.push('Please select a component')
            formIsValid = false;
        }
    
        setErrors(newErrors);
        
        return formIsValid;
      }

        return (
          <div className='container'>
              <Navbar category='inventory'/>
              <h1 className="sunray">Return Items</h1>
              <div className="content">
                <div className='select-row'>
                    <h3>Property:</h3>
                    <Select options={properties} onChange={(e) => handlePropertySelected(e[0].value)}/>
                </div>
                <div className='options'>
                    <button 
                    onClick={async () => handleControllerOrSourceButtonOption('Controller')}
                    disabled={isControllerButton}
                    >
                        Controller
                    </button>
                    <button 
                    onClick={async () => handleControllerOrSourceButtonOption('WaterSource')}
                    disabled={isWaterSourceButton}
                    >
                        Water Source
                    </button>
                </div>
                {controllerOrSourcesLabel != null &&
                <div className='select-row'>
                    <Select 
                    options={controllersOrSources} 
                    onChange={(e) => handleControllerOrSourceSelected(e[0].value)}
                    />
                </div>
                }
                <div className='select-row'>
                    <h3>Component:</h3>
                    <Select options={components} onChange={(e) => setSelectedComponent(e[0].value)}/>
                </div>
                <div className='options'>
                    {isLoading === true &&
                    <LoadingSpinner/>
                    }
                    {isLoading === false &&
                    <button
                    onClick={aysnc => handleSubmitReturn()}
                    >
                        Submit
                    </button>
                    }
                </div>
                <div>
                    {errors[0] && <p>{errors[0]}</p>}
                    {errors[1] && <p>{errors[1]}</p>}
                    {errors[2] && <p>{errors[2]}</p>}
                    {
                        isSuccess &&
                        <p className='success-message'>Submit Successful</p>
                    }
                    {
                        isFail &&
                        <p className='fail-message'>Submit Unsuccessful</p>
                    }
                </div>
              </div>
          </div>
        );
};
  
export default ReturnForm;