
import './Common.css'
import React from 'react';
import Table from './Table';
import NumberFormat from './NumberFormat';
import CurrencyFormat from './CurrencyFormat';
import NumberFormatTwoDecimalPlaces from './NumberFormatTwoDecimalPlaces';
import PercentFormat from './PercentFormat';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/AuthContext';
import { Context as userContext } from '../context/ProposalContext';
import { useContext } from 'react';

const BulkProposalDetailTable = ({ data }) => {
  const tableData = data.map(({MonthlyBaselines, MonthlyProjections, Id, 
    IsDeleted, RateIncrease, AssetValuationIncrease, MarkupPercentage, DeletedDate, ...obj })=> {return obj;});
  const tabularData = [...tableData].reverse();
  const navigate = useNavigate();

  const { state, setPropertyName } = useContext(Context);
  const { updateProposalId } = useContext(userContext); 
  const to = 'sales/bulkpropertydetail';

  const callback = (value, index, data) => {
    const property = value.original;
    updateProposalId(property.Id);
    setPropertyName(property.Name);
    navigate(`/${to}`);
  };

  const tableSortees = [{
    id: "Name",
    desc: false
  }];
  
  const tableColumns = [
      {
        Header: 'Property',
        accessor: 'Name',
        Cell: ({ row: { index }, value }) => 
        <div>{value}</div>// accessor is the "key" in the data
      },
      {
        Header: 'Address',
        accessor: 'Address',
      },
      {
        Header: 'Meter Count',
        accessor: 'MeterCount',
      },
      {
        Header: 'Baseline Usage (kgal)',
        accessor: 'BaselineUsageKiloGallons',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Expected Usage (kgal)',
        accessor: 'ExpectedUsageKiloGallons',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Baseline Cost',
        accessor: 'BaselineCost',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Expected Cost',
        accessor: 'ExpectedCost',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Gross Savings',
        accessor: 'GrossSavings',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Estimated Site Setup Fee',
        accessor: 'SiteSetupFee',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: '(2-Wire) Estimated Site Setup Fee',
        accessor: 'TwoWireProposalDetail.SiteSetupFee',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Estimated Annual Fees',
        accessor: 'AnnualFees',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Estimated Leak Cost Avoidance',
        accessor: 'LeakCostAvoidance',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Estimated Net Savings',
        accessor: 'NetSavings',
        Cell: ({ cell: { value } }) => <CurrencyFormat data={value} />
      },
      {
        Header: 'Estimated Payback Years',
        accessor: 'PaybackYears',
        Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
      },
      {
        Header: '(2-Wire) Estimated Payback Years',
        accessor: 'TwoWireProposalDetail.PaybackYears',
        Cell: ({ cell: { value } }) => <NumberFormatTwoDecimalPlaces data={value} />
      },
      {
        Header: 'Estimated Carbon Offset',
        accessor: 'CarbonOffset',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Estimated Trees Saved',
        accessor: 'TreesSaved',
        Cell: ({ cell: { value } }) => <NumberFormat data={value} />
      },
      {
        Header: 'Estimated Controller Count',
        accessor: 'ControllerEstimate',
        Cell: ({ cell: {value} }) => <NumberFormat data={value} />
      },
      {
        Header: 'Estimated HydroZone Count',
        accessor: 'ZoneEstimate',
        Cell: ({ cell: {value} }) => <NumberFormat data={value} />
      },
      {
        Header: 'Markup Percentage',
        accessor: 'MarkupPercentage',
        Cell: ({ cell: {value} })=> <PercentFormat data={value} />
      },
      {
        Header: 'Seasonality',
        accessor: 'Seasonality',
        Cell: ({ cell: {value }}) => <PercentFormat data={value} />
      }
    ];

  return (
    <div className='table-container'>
      <h3 className="sales-title">Irrigation Insight</h3>
      <Table columns={tableColumns} defaultData={tabularData} sortees={tableSortees} callback={callback} />
    </div>
  );
}


export default BulkProposalDetailTable;