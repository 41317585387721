import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, } from 'react-router-dom';
import { Context } from '../../context/AuthContext';
import TextInput from '../../components/TextInput';
import Box from '../../components/Box';
import './Login.css';
import api from '../../api/api';
import LoadingSpinner from '../../components/LoadingSpinner';
import useLocalStorage from '../../hooks/useLocalStorage';
import ReactGA from 'react-ga';

function Login({ id }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useLocalStorage("Checkbox", false);
    const { logInWithEmail, setIsAdmin, setToken, state, setAccountId } = useContext(Context);
    const isAdmin = state.isAdmin;

    useEffect(() => {
        if (id != null) {
            setIsLoading(true);
            api.getEmailAndToken(id)
                .then((response) => {
                    setEmail(response.data.Email);
                    window.localStorage.setItem("email", response.data.Email);
                    window.localStorage.setItem("token", response.data.Token);
                    login();
                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (window.localStorage.getItem("rememberMe") && id == null) {
            setToken(window.localStorage.getItem("email"), window.localStorage.getItem("token"));
            login();
        }
    }, []);

    const handleChange = () => {
        setChecked(!checked);
        if (checked === false) {
            window.localStorage.setItem("rememberMe", true);
            setToken(email, window.localStorage.getItem("token"));
        }
        else {
            window.localStorage.removeItem("rememberMe");
            localStorage.clear();
        }
    };

    let navigate = useNavigate();

    const login = async () => {
        setIsLoading(true);

        if (window.localStorage.getItem("rememberMe") == null
            || window.localStorage.getItem("token") == null) {
            const data = await logInWithEmail(email, password);
            if (data.access_token) {
                setError('');
                const roles = await api.getGeneric(data.access_token, '/AssetDashboard/CheckIfAdmin');
                window.localStorage.setItem("token", data.access_token);
                window.localStorage.setItem("email", email);
                if (email.includes("banyanwater")) {
                    ReactGA.set({ Is_banyanEmployee: "true" });
                }
                else {
                    ReactGA.set({ Is_banyanEmployee: "false" });
                }
                await setIsAdmin(roles.data);
                await setToken(email, window.localStorage.getItem("token"));
                if (!isAdmin) {
                    setAccountId(roles.data.accountId);
                    ReactGA.set({ Is_Admin: "false" });
                }
                else {
                    ReactGA.set({ Is_Admin: "true" });
                }
                
                navigator(roles);
            } else {
                try {
                    setIsLoading(false);
                    const message = data.response.data.error_description;
                    setError(message);
                } catch (e) {
                    setError('There was an error processing your request, please try again.');
                }
            }
        } else {
            const token = window.localStorage.getItem("token");
            const email = window.localStorage.getItem("email");
            const roles = await api.getGeneric(token, '/AssetDashboard/CheckIfAdmin');
            await setIsAdmin(roles.data);
            await setToken(email, token);
            setAccountId(roles.data.accountId);

            navigator(roles);
        }
    };

    const navigator = (roles) => {
        if (!roles.data.isAdmin && roles.data.isIndoor && !roles.data.isIrr){
            navigate('/leakcostavoidance');
        }
        else if(!roles.data.isAdmin && roles.data.isIrr && roles.data.isIndoor){
            navigate('/selectproduct');
        }
        else if (!roles.data.isAdmin && roles.data.isIrr && !roles.data.isIndoor){
            navigate(`/home`);
        }
        else if (roles.data.isAdmin){
            navigate(`/home`);
        }
    };

    const letterhead = require('../../assets/images/letterhead.png');

    const content = isLoading ? <LoadingSpinner />
        : <div className='login-container'>
            <TextInput
                defaultValue={checked ? email : ""}
                action={setEmail}
                placeholder="Email"
                password={false}
                login={login}
            />
            <TextInput
                defaultValue={checked ? password : ""}
                action={setPassword}
                placeholder="Password"
                password={true}
                login={login}
            />
            <form>
                <label>
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                    />
                    Remember me
                </label>
            </form>

            <button className='blue-btn'
                onClick={() => login()}>
                Login
            </button>
            <button className='blue-btn'
                onClick={() => navigate('resetpassword')}>
                Forgot Password?
            </button>
        </div>;

    return (
        <div>
            <div className='letterhead-container'>
                <img
                    src={letterhead}
                    alt="Banyan Water Letterhead with Logo"
                    className="letterhead"
                ></img>
            </div>
            <div className='login-screen-container'>
                <Box>
                    {content}
                </Box>

                <p>{error}</p>

            </div>
        </div>
    );
}

export default Login;