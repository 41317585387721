import React from 'react';
import { Link } from "react-router-dom";
import './Navbar.css'
import { useState } from 'react';

const Navbar = ({ category = "asset" }) => {

  const [isNavExpanded, setIsNavExpanded] = useState(false)

  const logo = require('../../src/assets/images/letterhead.png');
  const hamburger = require('../../src/assets/images/Hamburger_icon.png');

  const buildNavLinks = () => {
    switch (category) {
      case "mode":
        return (
          <ul>
            <li>
              <Link to="/sales">Sales</Link>
            </li>
            <li>
              <Link to="/assetadminview">Assets</Link>
            </li>
          </ul>)
      case "sales":
        return (
          <ul>
            <li>
              <Link to="/sales">Proposals</Link>
            </li>
            <li>
              <Link to="/sales/bulkcreation">Bulk Creation</Link>
            </li>
          </ul>)
        case "tracking":
          return (
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
            </ul>)
      case "inventory":
        return (
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/newarrivals">New Arrivals</Link>
            </li>
            <li>
              <Link to="/inventorycount">Inventory Count</Link>
            </li>
            <li>
              <Link to="/assemblytracker">Assembly Tracker</Link>
            </li>
            <li>
              <Link to="/orders">Orders</Link>
            </li>
            <li>
              <Link to="/returns">Return Items</Link>
            </li>
            <li>
              <Link to="/testing">Testing</Link>
            </li>
            <li>
              <Link to="/controllers">Registered Controllers</Link>
            </li>
          </ul>)
      case "asset":
      default:
        return (
          <ul>
            <li>
              <Link to="/landing">Home</Link>
            </li>
            <li>
              <Link to="/watersavings">Water Savings</Link>
            </li>
            <li>
              <Link to="/leakinsights">Leaks</Link>
            </li>
            <li>
              <Link to="/alertcenter">Alert Center</Link>
            </li>
            <li>
              <Link to="/carbonoffset">Carbon Offset</Link>
            </li>
            <li>
              <Link to="/installationsmetric">Installation Tracking</Link>
            </li> 
            <li>
              <Link to="/responserates">Survey Results</Link>
            </li>
            <li>
              <Link to="/payback">Savings Report</Link>
            </li>
          </ul>)
    }
  }
  return (
    <div className='nav'>
      <li className='business-logo'>
        <Link to="/landing"><img
          src={logo}
          alt="Logo"
          className="logo"
        ></img></Link>
      </li>
      <button className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}>
        <img
          src={hamburger}
          alt="Menu"
          className="menu"
        ></img>
      </button>
      <div className={
        isNavExpanded ? "nav-menu expanded" : "nav-menu"
      }>
        {buildNavLinks()}
      </div>
    </div>
  );
}
export default Navbar;