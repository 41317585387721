import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Context } from "../context/AuthContext";

function PrivateRoute({
  children,
  requiresAdmin=false,
  redirectPath = "/" }) {
  const { state } = useContext(Context);
  const token = state.token;
  const isAdmin = state.isAdmin;
  let isAuthorized = false;

  if (token !== "") {
    if (requiresAdmin) {
      isAuthorized = isAdmin;
    } else {
      isAuthorized = true;
    }
  }

  return (
    isAuthorized ? children ? children : <Outlet /> : <Navigate to={redirectPath} />)
}

export default PrivateRoute;