import './Installations.css'
import '../Common.css'
import { useNavigate } from 'react-router-dom';

export default function Installations({ data }) {
  const navigate = useNavigate();
  const to = 'installationsmetric';

  return (
    <div className="installations common-box">
      <div className='installations-panel' onClick={() => 
        { navigate(`/${to}`) }}>
        <div>
          <div className='installations-big-number' >
            {data.InstalledProperties}
          </div>
          <span className='big-installations-text'>Active Properties </span>
          <br></br>
        </div>
      </div>
    </div>
  );
}
