import "./ProposalForm.css"
import Verification from '../../components/salesAnalysis/verification/Verification'

export default function BillVerification() {
    return (
        <div>
            <h1 className='bill-title'>Billing Verification</h1>
            <div className="proposal-form-container">
                <Verification />
            </div>
        </div>
    );
}
