import './WaterSavingsRollup.css';
import '../Common.css';
import GallonsSaved from '../GallonsSaved';
import NetSavings from '../NetSavings';
import AssetValuationIncrease from '../AssetValuationIncrease';
import { useNavigate } from 'react-router-dom';

export default function WaterSavingsRollup({ data }) {
  const waterSavings = data.TotalWaterSavingsInGallons;
  const netSavings = data.NetSavingsInDollars;
  const assetValueIncrease = data.AssetValuationIncreasePercentage;
  const navigate = useNavigate();
  const to = 'watersavings';

  const waterdrop = require('../../../src/assets/images/water-drop-with-white-fill.png');
  return (
    <div>
      <div className="widget" 
        onClick={() => { navigate(`/${to}`) }}>
          <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><GallonsSaved waterSavingsResponseData={waterSavings}></GallonsSaved></div>
          </div>
          <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><NetSavings waterSavingsResponseData={netSavings}></NetSavings></div>
          </div>
          <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><AssetValuationIncrease waterSavingsResponseData={assetValueIncrease}></AssetValuationIncrease></div>
          </div>
        </div>
      </div>
  );
}
