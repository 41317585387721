
import '../Common.css'
import Table from '../Table';
import moment from 'moment';
import NumberFormat from '../NumberFormat';
import CurrencyFormat from '../CurrencyFormat';

const LeakInsightsTable = ({ url }) => {
  const tableSortees = [{
    id: "PropertyName",
    desc: false
  }];
  
  const tableColumns = [
      {
        Header: 'Property Name',
        accessor: 'PropertyName', // accessor is the "key" in the data
      },
      {
        Header: 'Mainline Name',
        accessor: 'MainlineName', // accessor is the "key" in the data
      },
      {
        Header: 'Volume Avoidance (gallons)',
        accessor: 'VolumeActionImpact',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
      {
        Header: 'Cost Avoidance',
        accessor: 'CostActionImpact',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <CurrencyFormat data={value}></CurrencyFormat>
            </>
          );
        }
      },
      {
        Header: 'Leak Detection Date',
        accessor: 'LeakDetectionDate',
        Cell: ({ cell: { value } }) => {
            return (
              moment(value).format("MM/DD/YYYY")
            );
          }
      },
      {
        Header: 'Leak End',
        accessor: 'LeakEnd',
        Cell: ({ cell: { value } }) => {
            return (
                moment(value).format("MM/DD/YYYY")
              );
          }
      },
      {
        Header: 'Leak GPM',
        accessor: 'LeakGPM',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
    ];

  return (
    <div className='table-container-wst'>
      <Table columns={tableColumns} title="Leak Insight" url={url} sortees={tableSortees}/>      
    </div>
  );
}

export default LeakInsightsTable;