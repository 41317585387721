import React from 'react'
import './Home.css'
import { useNavigate } from 'react-router-dom'

export default function Home() {
    const navigate = useNavigate()

    return (
        <div className="home-wrapper">
            <div className="home-tile" onClick={() => navigate("/sales")}>Sales Analysis</div>
            <div className="home-tile" onClick={() => navigate("/assetadminview")}>Asset Dashboard</div>
            <div className="home-tile" onClick={() => navigate("/newarrivals")}>Inventory Management</div>
            <div className="home-tile" onClick={() => navigate("/installtracking")}>Install Tracking Report</div>
            <div className="home-tile" onClick={() => navigate("/commsdownreport")}>Comms Down Report</div>
            <div className="home-tile" onClick={() => navigate("/portfolio")}>Portfolio Performance</div>
        </div>
    )
}
