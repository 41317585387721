import './MobileTrainingHome.css';
import '../../components/Common.css'


const MobileTrainingHome = () => {
    const letterhead = require('../../../src/assets/images/letterhead.png');
    const pdfGuide = require('../../../src/assets/BanyanMobileComprehensiveGuide.pdf');

    return (
        <div>
            <div className='letterhead-container'>
            <img
                src={letterhead}
                alt="Banyan Water Letterhead with Logo"
                className="letterhead"
            ></img>
            </div>
            <div className='title-container'>
            <h2>Welcome to our Mobile Training Hub!</h2>
            </div>
            <div className='title-container'>
            <h2>Comprehensive Training Guide</h2>
            </div>
            <div className='pdf-container'>
            <object data={pdfGuide} type="application/pdf" width="100%" height="100%">
            </object>
            </div>
            <div className='title-container'>
            <h2>Video Resources</h2>
            </div>
            <div className='video-container'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/LyCmcFCp0BU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='video-container'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/xSB-bTA3iw0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default MobileTrainingHome;