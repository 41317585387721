import React, { useState, useMemo } from 'react';
import Table from '../../components/Table';
import { useNavigate } from 'react-router-dom'

export default function RepairsTable() {
    const urlEndingRepairsTable = '/PropertiesRepairsMetric';
    const navigate = useNavigate()

    const callback = (value, index, data) => {
        data = data[index];
    };

    const columns = useMemo(() => [
        {
            Header: 'Property Name',
            accessor: 'PropertyName'
        },
        {
            Header: 'City',
            accessor: 'City'
        },
        {
            Header: 'State',
            accessor: 'State'
        },
        {
            Header: 'Number Of Repairs',
            accessor: 'NumberOfRepairs',
            Cell: ({ row: { index }, value }) => <div>{value}</div>
        },
        {
            Header: '',
            accessor: 'Id',
            disableSortBy: true,
            Cell: ({ row, value }) => <div className='buttons-wrapper'>
                <button className='rr-btn rr-delete-btn' onClick={() =>  navigate("/repairphotos", {state:{propertyName:row.original.PropertyName}})}>PHOTOS</button>
            </div>

        }
    ]);

    return (
        <div className='table-container-ws table-container-rr'>
            <Table columns={columns} url={urlEndingRepairsTable} callback={callback} />
        </div>
    );
}
