
import './Landing';
import '../../components/Common.css'
import './WaterSavingsDrilldown.css';
import Dropdown from "../../components/Dropdown";
import WaterSavingsDrilldownWidget from "../../components/waterSavings/WaterSavingsDrilldownWidget";
import React, { useState , useContext, useEffect } from 'react';
import { Context } from '../../context/AuthContext';
import { WaterUsageGraph } from '../../components/waterSavings/WaterUsageGraph.js';
import WaterSavingsTable from '../../components/waterSavings/WaterSavingsTable';

const WaterSavingsDrilldown = () => {
  const { state } = useContext(Context);
  const [rollupData, setRollupData] = useState('');
  const [hiddenCauseTypes, setHiddenCauseTypes] = useState([]);

  useEffect(() => {
    setRollupData('');
    setHiddenCauseTypes([]);
  }, [state.startDate])

  const callback = (text, gallons, costs, waterSavingsPercent) => {
    const netSavings = rollupData.NetSavingsInDollars + costs;
    setRollupData({
      TotalWaterSavingsInGallons: rollupData.TotalWaterSavingsInGallons + gallons,
      NetSavingsInDollars: netSavings,
      AssetValuationIncreasePercentage: netSavings / 0.05,
      PercentageSavings: rollupData.PercentageSavings
    });
    
    let hiddenCauseTypesCopy = hiddenCauseTypes;
    if (hiddenCauseTypesCopy.includes(text)) {
      const idx = hiddenCauseTypesCopy.indexOf(text);
      hiddenCauseTypesCopy.splice(idx, 1);
    } else {
      hiddenCauseTypesCopy.push(text);
    }
    setHiddenCauseTypes(hiddenCauseTypesCopy)
  };

    return (
          <div>
            <div className='landing-screen-container-ws'>
                <br></br>
                <WaterSavingsDrilldownWidget data={rollupData} hiddenCauseTypes={hiddenCauseTypes} callback={setRollupData} />
                <div className='graph-container'>
                  <WaterUsageGraph callback={callback} />
                </div>
                <Dropdown />
                <WaterSavingsTable url="TabularPropertyData" />
             </div>
        </div>
    )
}


export default WaterSavingsDrilldown;