import React from 'react'
import Select from 'react-select'
import { ReactSelectCustomTheme, ReactSelectCustomStyles } 
from '../../../constants/selectFomFieldStyle'

export default function SelectFormField({ id, question, metadata,
    isMulti, getDefaultValue, handleSelect, hasError }) {
    let options = [];
    if (metadata["choices"] !== undefined) {
        if (question === "Utility Provider") {
            options = metadata["choices"].map((c) => ({ label: c.name, value: c }))
        } else {
            options = metadata["choices"].map((c) => ({ label: c, value: c }))
        }
    }

    return (<div className='proposal-form-field-wrapper' key={`q-${id}`}>{question === "Utility Provider" ? <div style={{ textAlign: 'right' }}>Cannot find utility provider? Click
        <a href={`${process.env.REACT_APP_PLATFORM_BASE_URL}/Utility/Create`} target="blank"> here </a>
        to create one</div> : null}

        <div className='proposal-form-row' >
            <label >{question}</label>
            <div className='proposal-form-select-wrapper'>
                <Select name={id}
                    isMulti={isMulti}
                    defaultValue={() => getDefaultValue(options, id, isMulti)}
                    onChange={(e) => handleSelect(e, id, isMulti)}
                    placeholder='Select...'
                    theme={(theme) => ReactSelectCustomTheme(theme)}
                    styles={ReactSelectCustomStyles}
                    options={options} />
            </div>
        </div>
        {hasError && <div className='proposal-form-error'>This field has invalid input.</div>}
    </div>
    )
}
