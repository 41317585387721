import { useState, useEffect } from "react";
import get from '../../api/get';
import DeviceInfo from './DeviceInfo';
import Navbar from "../Navbar";

const Devices = () => {
    const [devices, setDevices] = useState([]);

    const getDevices = async () => {
        const deviceInfo = await get('inventory/devices');
        setDevices(deviceInfo.data);
    };

    useEffect(() => {
        setInterval(() => {
            getDevices();
        }, 10000);
    }, []);

    return (
        <div style={{minHeight: '600px'}}>
            <Navbar category='inventory'/>
            <h1 className="sunray" style={{ color: 'black', textAlign: 'center', marginTop: '0px', padding: '10px'}}>Registered Devices</h1>
            {devices.map(d => <DeviceInfo checkIn={d.CheckIn} serial={d.Serial} isNew={d.IsNew} />)}
        </div>
    );
};

export default Devices;