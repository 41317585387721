import React from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useState, useContext, useEffect } from 'react';
import api from '../api/api';
import { Context } from '../context/AuthContext';
import moment from 'moment';
import './Dropdown.css'

export default function Dropdown (){
    const { setDates } = useContext(Context);
    const { state } = useContext(Context);
    const token = state.token;
    const accountId = state.accountId;
    const [customDate, setCustomDate] = useState([state.startDate, state.endDate]);
    const [endDate, setEndDate] = useState(state.endDate);
    const urlEndingDates = 'ContractStartAndEndDates';

    const options = [
        { value: 'CTD', label: 'Contract to date' },
        { value: '1Y', label: 'Last year' },
        { value: 'YTD', label: 'Year to date' },
        { value: '6M', label: 'Last 6 months' },
        { value: '3M', label: 'Last 3 months' },
        { value: '1M', label: 'Last month' },
        { value: 'Custom', label: 'Custom' }
    ];

    const [selectedOption, setSelectedOption] = useState(
            state.dateRange?.length > 1 
            ? options.filter(o => o.value == state.dateRange)[0]
            : options[0]);
      
    const onChange = (v) => {
        setSelectedOption(v);
        switch(v) {
        case 'CTD':
            initilizeDates();
            return;
        case 'YTD':
            const startOfYear =  new Date().getFullYear();
            const newStartDateYTD = new Date(startOfYear, 0, 1);
            setDates(newStartDateYTD, endDate, v);
            return;
        case '1M':
            const newStartDate1M = moment(endDate).subtract(1, 'month').format();
            setDates(newStartDate1M, endDate, v);
            return;
        case '3M':
            const newStartDate3M = moment(endDate).subtract(3, 'month').format();
            setDates(newStartDate3M, endDate, v);
            return;
        case '6M':
            const newStartDate6M = moment(endDate).subtract(6, 'month').format();
            setDates(newStartDate6M, endDate, v);
            return;
        case '1Y':
            const startOfThisYear =  new Date().getFullYear();
            const newStartDateThisYear = new Date(startOfThisYear, 0, 1);
            const newStartDate1Y = moment(newStartDateThisYear).subtract(1, 'year').format();
            setDates(newStartDate1Y, newStartDateThisYear, v);
            return;
        default:
        return;
        }
    }
    const handleChange = (v) => {
        const newCustomStartDate = v[0];
        const newCustomEndDate = v[1];
        setCustomDate([newCustomStartDate, newCustomEndDate]);
        setDates(newCustomStartDate, newCustomEndDate, selectedOption.value);
        return;
    }

    useEffect(() => {
      if (state.startDate === undefined 
        || state.endDate === undefined) {
        initilizeDates();
      }
    }, []);

    const initilizeDates = (e) => {
        api.getData(new Date(), new Date(), accountId, '/AssetDashboard/' + urlEndingDates, token)
            .then((response)=>{
                setDates(response.data.startDate, response.data.endDate, selectedOption.value);
                setCustomDate([new Date(response.data.startDate), new Date(response.data.endDate)]);
                setEndDate(response.data.endDate);
            })
            .catch((error) => {
                console.log(error)
            })
        return {success: true};
    };

    return(
        <div className="datepicker-row-container-ws datepicker-text-ws">
            <b>Date Range:</b>
            <div className="date-container-ws common-box">
                <div className='select-container'>
                    <Select 
                        menuPlacement='auto'
                        defaultValue={selectedOption}
                        onChange={val => onChange(val.value)}
                        options={options} 
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 20,
                            colors: {
                                ...theme.colors,
                                neutral0: '#034C8C',
                                neutral20: '#034C8C',
                                primary: '#034C8C',
                                neutral80: 'white',
                                neutral70: 'white',
                                neutral60: 'white',
                                neutral50: 'white',
                                neutral40: 'white',
                                neutral30: 'white',
                            }, })} />
                </div>
                {(selectedOption !== 'Custom') ? ("") : (
                    <DateRangePicker clearIcon={null}
                        onChange={handleChange} value={customDate} />
                )}
            </div>
        </div>
    );
}
  