import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import BebbiaClientsTable from '../../components/bebbia/BebbiaClientsTable';


export default function BebbiaMarketing() {
  const letterhead = require('../../../src/assets/images/letterhead.png');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const handleBack = () => {
    navigate('/bebbia');
  };

  return (
    <>
      <div className='letterhead-container'>
        <img
          src={letterhead}
          alt='Banyan Water Letterhead with Logo'
          className='letterhead'
        ></img>
      </div>

      <div className='back-button-container'>
        {<button onClick={handleBack}>Volver</button>}
      </div>

      <div className='bebbia-marketing-content-wrapper'>
        <div className='bebbia-operations-section-title'>Tabla de Informe de Marketing</div>
        <div className='bebbia-marketing-table-wrapper'>
          <BebbiaClientsTable />
        </div>
      </div>
    </>
  )
}
