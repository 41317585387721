import React from 'react'
import Navbar from '../Navbar'

export default function LayoutWithNav({children, category}) {
    return (
        <main>
            <Navbar category={category}/>
            {children}
        </main>
    )
}
