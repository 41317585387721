import './PropertyWaterSavingsDrilldownWidget.css';
import '../assetDashboardHomeIcons/WaterSavingsRollup.css'
import '../Common.css';
import GallonsSaved from '../GallonsSaved';
import NetSavings from '../NetSavings';
import AssetValuationIncrease from '../AssetValuationIncrease';

export default function PropertyWaterSavingsDrilldownWidget({ data, hiddenCauseTypes }) {
  const hiddenTypes = hiddenCauseTypes !== undefined && hiddenCauseTypes.length > 0 
  ? <ul>{hiddenCauseTypes.map(type => <li>Without {type}</li>)}</ul>
  : null;
  
  const waterdrop = require('../../../src/assets/images/water-drop-with-white-fill.png');
  return (
    <div className="property-widget-drilldown">
      <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><GallonsSaved waterSavingsResponseData={data.TotalWaterSavingsInGallons}></GallonsSaved></div>
          </div>
          <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><NetSavings waterSavingsResponseData={data.NetSavingsInDollars}></NetSavings></div>
          </div>
          <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><AssetValuationIncrease waterSavingsResponseData={data.AssetValuationIncreasePercentage}></AssetValuationIncrease></div>
          </div>
    </div>
  );
}
